import React from "react";
import { useNavigate } from "react-router-dom";
import LocationCard from "../components/ui/LocationCard";
import Loader from "../components/ui/Loader";
import arrow from "../../assets/icons/arrow-left.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { useLocationHandler } from "../hooks/useLocationHandler";
import { Swiper, SwiperSlide } from "swiper/react";

const SelectLocation = () => {
  const navigate = useNavigate();
  const { locations, location, loading, setUserLocation } =
    useLocationHandler();

  const currentAddress = localStorage.currentAddress;
  const initialSetup = localStorage.initialSetup;

  return (
    <div className="flex grow-0 relative mini-tablet:gap-[80px] max-mobile:gap-[60px] gap-[40px] flex-col min-h-screen z-40 w-full m-auto  px-[32px] mini-tablet:py-5 py-[129.5px] justify-center items-center ">
      {currentAddress && initialSetup === "false" && (
        <img
          onClick={() => navigate("/")}
          className="absolute top-[35px] h-[20px] left-[35px] cursor-pointer w-[30px]"
          src={arrow}
          alt=""
        />
      )}
      <span className="text-[#ffffff] px-5 text-center  mini-tablet:text-[60px] max-mobile:text-[40px] text-[24px]  max-w-[794px] flex justify-center  mini-tablet:leading-[90px] max-mobile:leading-[60px] leading-[36px] font-[600]">
        Select your location to continue
      </span>
      <div className="w-[100%] z-[999999] max-w-[1288px] max-mobile:block hidden h-[296px] mx-auto ">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          centeredSlides={false}
          preventClicksPropagation={true}
          style={{ display: "flex", justifyContent: "center" }}
          breakpoints={{
            0: {
              width: 329,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            964: {
              width: 329,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1148: {
              slidesPerView: 3,
            },
            1248: {
              slidesPerView: 3,
            },
          }}
        >
          {locations.map((l, n) => (
            <SwiperSlide key={n}>
              <LocationCard
                value={l}
                location={location}
                setLocation={(id) => setUserLocation(id)}
                loading={loading}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className=" max-mobile:hidden flex w-full justify-center mini-tablet:flex-row flex-col items-center mini-tablet:gap-[32px] gap-[16px] mini-tablet:h-[296px] ">
        {locations.map((l, n) => (
          <LocationCard
            value={l}
            location={location}
            setLocation={(id) => setUserLocation(id)}
            loading={loading}
            key={n}
          />
        ))}
      </div>
      {/* <span
        onClick={() => location !== "" && setUserLocation()}
        className={`${
          location !== ""
            ? "bg-[#FF0018] text-[#ffffff]"
            : "bg-[#FFE5E8] text-[#FF8A95]"
        } cursor-pointer flex justify-center items-center justify-self-center max-mobile:w-[408px] w-full h-[66px] transition-all ease-in-out duration-300 text-[20px] leading-[30px] font-[500]  rounded-[100px] `}
      >
        {loading ? <Loader size={[30, 30]} color={"#fff"} /> : "Continue"}
      </span> */}
    </div>
  );
};

export default SelectLocation;
