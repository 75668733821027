import React, { useRef, useState } from "react";
import { useFlowContext } from "../../../../middle-ware/StateContext/FlowContext";

export const useNavbar = () => {
  const context = useFlowContext();
  const { cart } = context;

  const [openCart, setOpenCart] = useState(false);

  const user = localStorage.user
    ? JSON.parse(localStorage.user)
    : { first_name: "", last_name: "" };

  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};

  const cartRef1 = useRef();
  const cartRef = useRef();

  const numOfItems =
    cart && Object.keys(cart)?.length > 0 ? cart?.items?.length : 0;

  window.addEventListener("click", (e) => {
    if (
      openCart &&
      !cartRef.current?.contains(e.target) &&
      !cartRef1.current?.contains(e.target)
    )
      setOpenCart(false);
  });

  return {
    cart,
    location,
    user,
    cartRef,
    cartRef1,
    openCart,
    setOpenCart,
    numOfItems,
  };
};
