import React from "react";
import { BsStarFill } from "react-icons/bs";
import avatar from "../../../../assets/images/avatar1.png";

const CommentCard = () => {
  return (
    <div className="flex transparent_bg2 gap-[17px] p-[24px] light-shadow backdrop-blur-[20px] rounded-[24px] mini-tablet:w-[590px] max-mobile:w-[574px] w-[398px]  mini-tablet:h-[173px] mobile:h-[151px] h-[130px]">
      <div className="flex flex-col items-center gap-[17px] justify-between">
        <img
          src={avatar}
          className="mobile:min-w-[70px] min-w-[50px] mobile:min-h-[70px] min-h-[50px] rounded-full "
          alt=""
        />
        <div className="flex items-center gap-[7px]">
          <BsStarFill className="text-[15px] text-[#ffa503]" />
          <BsStarFill className="text-[15px] text-[#ffa503]" />
          <BsStarFill className="text-[15px] text-[#ffa503]" />
          <BsStarFill className="text-[15px] text-[#ffa503]" />
          <BsStarFill className="text-[15px] text-[#ffa503]" />
        </div>
      </div>
      <div className="flex flex-col w-full justify-between z-30">
        <span className="mini-tablet:text-[24px] mobile:text-[20px] text-[18px] mini-tablet:leading-[36px] mobile:leading-[30px] leading-[27px] font-[500] text-[#ffffff] opacity-[0.86]">
          Alex Uzor
        </span>

        <span className="font-[400] mini-tablet:block hidden  text-[#ffffff] opacity-[0.65] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] ">
          I always thought becoming a rider for a food delivery company would be
          long, complicated and require a lot of documentation but....
        </span>
        <span className="font-[400] mini-tablet:hidden max-mobile:block hidden text-[#ffffff] opacity-[0.65] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] ">
          I always thought becoming a rider for a food delivery company would be
          long...
        </span>
        <span className="font-[400] mini-tablet:hidden max-mobile:hidden block text-[#ffffff] opacity-[0.65] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] ">
          I always thought becoming a rider for a food delivery company ...
        </span>
      </div>
    </div>
  );
};

export default CommentCard;
