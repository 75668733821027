import React from "react";
// import { BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useClipper } from "../../hooks/useClipper";
import { useProduct } from "../../hooks/useProduct";
import Loader from "./Loader";
import Images from "./Images";
import Prices from "./Prices";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const { cart, loading, addToCart } = useProduct(product);

  return (
    <div className="tablet:h-[510px] overflow-hidden max-mobile:h-[485px] h-[382px] transparent_bg backdrop-blur-[21.34px] rounded-[24px] p-[24px] sm:px-[24px] px-[20px] tablet:gap-[35px] max-mobile:gap-[40px] gap-[24px] bg-blue-100 flex flex-col items-center">
      <span
        onClick={() =>{
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          navigate(
            `/product/${product?.category?.name}/${product?.title}/${product?.id}`
          )
        }}
      >
        <Images
          classes={
            "tablet:w-[220px] max-mobile:mobile:w-[180px] w-[140px] tablet:h-[220px] max-mobile:mobile:h-[180px] h-[140px] rounded-full filter"
          }
          url={product?.image?.url}
          width={"220px"}
          height={"220px"}
          radius={"100%"}
        />
      </span>
      <div className=" flex flex-col gap-[9.83px] px-[16px]">
        <span
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            navigate(
              `/product/${product?.category?.name}/${product?.title}/${product?.id}`
            );
          }}
          className=" text-center mx-auto cursor-pointer max-tablet:max-w-[300px] max-mobile:max-w-[250px] max-w-[270px] whitespace-nowrap truncate tablet:text-[24px] text-[18px] tablet:leading-[36px] leading-[27px] font-[500] text-[#ffffff] opacity-[0.85] "
        >
          {product.title}
        </span>
        {/* <div className="flex justify-center gap-[14.6px]">
          <BsStarFill className="text-[14px] text-[#ffa503]" />
          <BsStarFill className="text-[14px] text-[#ffa503]" />
          <BsStarFill className="text-[14px] text-[#ffa503]" />
          <BsStarFill className="text-[14px] text-[#ffa503]" />
          <BsStarFill className="text-[14px] text-[#ffa503]" />
        </div> */}
        <div className="max-mobile:h-[54px] h-[42px] max-mobile:text-[18px] text-[14px] max-mobile:leading-[27px] leading-[21px] overflow-hidden text-ellipsis text-center text-[#FFFFFF] opacity-[0.65]">
          <span className="text-ellipsis">
            {useClipper(product.description, 70)}
          </span>
        </div>
      </div>
      <div className="flex w-full justify-between gap-2 items-center">
        {/* <span className="">
          {toPrice(
            product?.product_price?.currency,
            product?.product_price?.price
          )}
        </span> */}
        <Prices
          amount={product?.product_price?.price}
          currency={product?.product_price?.currency}
          height={20}
          classes={
            "text-[20px] text-[#ffffff] opacity-[0.85] leading-[30px] font-[600]"
          }
        />
        <span
          onClick={() => addToCart()}
          className={`${
            cart && cart?.length > 0
              ? "text-[#ffffff] bg-[#ad0010] "
              : "text-[#ff0018]"
          } whitespace-nowrap sm:w-[114px] min-w-[90px] text-center transition-all ease-in-out duration-500 hover:bg-[#ff0018]  hover:text-[#ffffff] cursor-pointer text-[14px] border border-[#ff0018] p-[13px] rounded-[100px]`}
        >
          {loading ? (
            <Loader color={"#ffffff"} size={[20, 20]} />
          ) : cart && cart?.length > 0 ? (
            "In Cart"
          ) : (
            "Add To Cart"
          )}
        </span>
      </div>
    </div>
  );
};

export default ProductCard;
