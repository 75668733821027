import React from "react";

const SidebarItem = ({ item, active, setActive }) => {
  return (
    <div
      onClick={() => setActive(item)}
      className={`w-[200px] whitespace-nowrap border-l-[2px]  text-[#FFFFFF] cursor-pointer transition-all ease-in-out duration-300 text-[18px] leading-[27px] font-[400] h-[59px] p-[16px] ${
        active ? " border-[#AD0010] transparent_bg3" : " border-[transparent]"
      }`}
    >
      {item}
    </div>
  );
};

export default SidebarItem;
