import React from "react";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { useCartCardFunctions } from "../../hooks/useCartCardFunctions";
import Loader from "../../../ui/Loader";

const CartCard = ({ item }) => {
  const { updateCart, deleteCartItem, loading } = useCartCardFunctions(item);
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };

  return (
    <div className="max-w-[400px] items-center grid grid-cols-12 justify-between w-full gap-[16px]  max-mobile:p-[16px] p-[14px] py-[10px] transparent_bg3 light-shadow backdrop-blur-[20px] rounded-[16px]">
      <div className="col-span-8 flex gap-[8px] overflow-x-hidden ">
        <img
          src={item?.product?.image?.url}
          className="min-w-[30px] w-[30px] h-[30px] rounded-full"
          alt=""
        />
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            <span className="mobile:text-[16px] text-[15px] truncate max-mobile:max-w-[150px] mini-tablet:max-w-[180px]  mobile:max-w-[130px] max-w-[85px]  whitespace-nowrap text-[#ff0018] leading-[24px] font-[500]">
              {item?.product?.title}
            </span>
            <span className="mobile:text-[14px] text-[12.5px] sm:leading-[21px] text-[#ffffff] opacity-[0.65] font-[400]">
              {localStorage.token
                ? toPrice(item?.unit_price?.currency, item?.unit_price?.price)
                : toPrice(
                    item?.product?.product_price?.currency,
                    item?.product?.product_price?.price
                  )}{" "}
              each
            </span>
          </div>
          <span></span>
        </div>
      </div>
      <div className="col-span-4 flex flex-col  items-end justify-end">
        <div className="gap-[8px] border border-[#ffffff17] rounded-[100px] sm:h-[48px] h-[40px] px-[16px]  flex justify-between items-center">
          <span
            onClick={() => item.quantity > 1 && updateCart("minus")}
            className="w-[24px] cursor-pointer text-[#ffffff] h-[24px] flex justify-center items-center rounded-full bg-[#ffffff17]"
          >
            <BiMinus />
          </span>
          <span className="text-[11.55px] leading-[15px] text-[#ffffff] text-center">
            {loading ? (
              <Loader size={[15, 15]} color={"#fff"} />
            ) : (
              item.quantity
            )}
          </span>
          <span
            onClick={() => updateCart("plus")}
            className="w-[24px] cursor-pointer text-[#ffffff] h-[24px] flex justify-center items-center rounded-full bg-[#ffffff17]"
          >
            <BsPlus />
          </span>
        </div>
        <span
          onClick={() => deleteCartItem()}
          className="text-[12px] text-[#ff0018] opacity-[0.65] leading-[18px] cursor-pointer underline "
        >
          Delete
        </span>
      </div>
    </div>
  );
};

export default CartCard;
