import React, { Fragment,  } from "react";
import { useParams } from "react-router-dom";
import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import LayoutTwo from "../components/ui/layouts/LayoutTwo";
import ProductContent from "../components/product/ProductContent";
import MiniProductSlide from "../components/ui/MiniProductSlide";
// import Ratings from "../components/product/Ratings";
import Faq from "../components/ui/Faq";
import Cart from "../components/cart/Cart";
import ProductsSlide from "../components/ui/ProductsSlide";

const Product = () => {
  const paths = useParams();
  const appContext = useDataContext();
  const params = useParams();
  const products = appContext?.products?.filter(
    (p) => p?.category?.name === params?.category
  );
 

  return (
    <LayoutTwo
      title={"Menu"}
      Path={
        <Fragment>
          <span>/</span>
          <span>{paths?.category}</span>
          <span className=" sm-tablet:flex hidden">/</span>
          <span className=" sm-tablet:flex hidden">{paths?.item}</span>
        </Fragment>
      }
      LeftComponent={
        <Fragment>
          <ProductContent id={params.id} />
          <div className="tablet:block hidden">
            <MiniProductSlide title={"Featured Meals"} />
          </div>
          <div className="tablet:hidden block ">
            <ProductsSlide products={products} title={"Featured Meals"} />
          </div>
          {/* <Ratings /> */}
          <div className="mt-[32px]">
            <Faq />
          </div>
        </Fragment>
      }
      RightComponent={<Cart />}
    />
  );
};

export default Product;
