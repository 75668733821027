import React, { Fragment, useEffect, useState } from "react";
import AuthLayout from "../components/ui/layouts/AuthLayout";
import Input from "../components/ui/input/Input";
import { useNavigate } from "react-router-dom";
import client from "../../middle-ware/axiosInstance";
import Loader from "../components/ui/Loader";
import Geocode from "react-geocode";
import Select from "../components/ui/input/Select";

Geocode.setApiKey("AIzaSyCN7pinc11ItQCUJH9VXgs3ubpr1GG0vOo");
Geocode.setLanguage("en");

const Address = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [areas, setAreas] = useState([]);

  const [country, setCountry] = useState({
    id: "",
    name: "",
    currency: "",
  });
  const [state, setState] = useState({
    id: "",
    name: "",
  });
  const [area, setArea] = useState({
    id: "",
    name: "",
  });
  const [address, setAddress] = useState({
    house_number: "",
    street: "",
  });
  const [errors, setErrors] = useState({
    house_number: "",
    street: "",
    country: "",
    state: "",
    area: "",
  });

  const getCountries = async () => {
    try {
      setLoadingCountries(true);
      const res = await client.get("/addresses/countries");
      setCountries(res.data.data);
      setLoadingCountries(false);
    } catch (err) {
      setLoadingCountries(false);
    }
  };
  const getStates = async (id) => {
    try {
      setLoadingStates(true);
      const res = await client.get(`/addresses/countries/${id}`);
      setStates(res.data.data);

      setLoadingStates(false);
    } catch (err) {
      setLoadingStates(false);
    }
  };
  const getAreas = async (id) => {
    try {
      setLoadingAreas(true);
      const res = await client.get(
        `/addresses/countries/${country.id}/state/${id}`
      );
      setAreas(res.data.data);
      setLoadingAreas(false);
    } catch (err) {
      setLoadingAreas(false);
    }
  };

  const handleChange = (name, data) => {
    if (name === "country") {
      setState({ id: "", name: "" });
      setCountry({
        id: data?.id,
        name: data?.country_data?.data?.translations?.en,
        currency: data?.currency,
      });
      setErrors({ ...errors, country: "", state: "", area: "" });
      getStates(data?.id);
    } else if (name === "state") {
      setArea({ id: "", name: "" });
      setState({
        id: data?.id,
        name: data.state,
      });
      setErrors({ ...errors, state: "", area: "" });
      getAreas(data?.id);
    } else if (name === "area") {
      setArea({
        id: data?.id,
        name: data.name,
      });
      setErrors({ ...errors, area: "", house_number: "", street: "" });
    }
  };
  const handleAddress = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "street" && value.length < 3)
      setErrors({ ...errors, [name]: "should be a mininmum of 3 characters" });
    else setErrors({ ...errors, [name]: "" });
  };
  const validateForm = () => {
    const error = {};
    if (address.house_number === "") error.house_number = "Required*";
    if (address.street === "") error.street = "Required*";
    else if (address.street.length < 3)
      error.street = "should be a mininmum of 3 characters";
    if (country.id === "") error.country = "Required*";
    if (state.id === "") error.state = "Required*";
    if (area.id === "") error.area = "Required*";
    return error;
  };
  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        const location = await Geocode.fromAddress(
          `${address.house_number}, ${address.street} ${area.name} ${state.name} ${country.name}`
        );
        const { lat, lng } = location?.results[0]?.geometry.location;
        const res = await client.post("/addresses/new", {
          address: {
            house_number: address.house_number,
            street: address.street,
            delivery_zone_id: area.id,
            available_region_id: state.id,
            location_id: country.id,
            longitude: lng,
            latitude: lat,
          },
        });

        localStorage.setItem("currentAddress", JSON.stringify(res.data.data));
        setLoading(false);
        navigate("/");
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.token) navigate("/signup");
    else getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout
      title={"Hi again!"}
      text={
        "We need your address to provide you the nearest kitchen and meals to your loaction "
      }
    >
      <form className="flex-col flex gap-[32px] m-auto" action="">
        <Select
          list={countries}
          holder={"Select Country"}
          onChange={handleChange}
          loading={loadingCountries}
          name={"country"}
          value={country.name}
          error={errors.country}
        />
        {country.id !== "" && (
          <Select
            list={states}
            holder={"Select State"}
            onChange={handleChange}
            loading={loadingStates}
            name={"state"}
            value={state.name}
            error={errors.state}
          />
        )}
        {state.id !== "" && (
          <Select
            list={areas}
            holder={"Select Area"}
            onChange={handleChange}
            loading={loadingAreas}
            name={"area"}
            value={area.name}
            error={errors.area}
          />
        )}
        {area.id !== "" && (
          <Fragment>
            <Input
              value={address.house_number}
              type={"text"}
              onChange={handleAddress}
              name="house_number"
              error={errors.house_number}
              label={"House Number"}
            />
            <Input
              value={address.street}
              type={"text"}
              onChange={handleAddress}
              name="street"
              error={errors.street}
              label={"Street"}
            />
          </Fragment>
        )}

        <span
          onClick={() => handleSubmit()}
          className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
        >
          {loading ? (
            <Loader size={[40, 40]} color="#ffffff" />
          ) : (
            "Submit Address"
          )}
        </span>
      </form>
    </AuthLayout>
  );
};

export default Address;
