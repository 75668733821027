import React, { Fragment } from "react";
import Input from "../ui/input/Input";
import Loader from "../ui/Loader";
import { useRecipientForm } from "./hooks/useRecipientForm";

const Recipient = ({ vissible, showNext }) => {
  const { loading, form, errors, handleChange, setRecipient, location } =
    useRecipientForm(vissible, showNext);
  // console.log(location);
  return (
    <Fragment>
      <span className="mobile:text-[24px] text-[18px] mobile:leading-[36px] leading-[27px] font-[500]">
        Recipient Information
      </span>
      <span className="text-[16px] max-w-[430px] w-full opacity-[0.65] leading-[24px] text-center font-[400]">
        This will help us to check availability and delivery fees.
      </span>
      <form
        className="flex-col flex max-w-[430px] m-auto gap-[27px] w-full"
        action=""
      >
        <Input
          value={form.full_name}
          type={"text"}
          onChange={handleChange}
          name="full_name"
          error={errors.full_name}
          label={"Full Name"}
          light
        />

        <Input
          value={form.email}
          type={"email"}
          onChange={handleChange}
          name="email"
          error={errors.email}
          label={"Email"}
          light
        />

        <Input
          value={form.phone}
          type={"text"}
          onChange={handleChange}
          name="phone"
          error={errors.phone}
          label={"Phone"}
          light
          isLondon={location?.state === "London"}
        />

        {localStorage.forAFriend && (
          <Input
            value={form.customer_note}
            type={"text"}
            textarea={true}
            onChange={handleChange}
            name="customer_note"
            error={""}
            label={"Add a note for the recipient"}
            light
          />
        )}

        <span
          onClick={() => setRecipient()}
          className="h-[55px] cursor-pointer font-[500] w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[18px] mobile:leading-[30px] leading-[27px]"
        >
          {loading ? (
            <Loader color={"#fff"} size={[22, 22]} />
          ) : (
            "Use This Details"
          )}
        </span>
      </form>
    </Fragment>
  );
};

export default Recipient;
