import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Addresses from "../components/profile/Addresses";
import MobilNavigator from "../components/profile/MobilNavigator";
import Payment from "../components/profile/Payment";
import Settings from "../components/profile/Settings";
import Sidebar from "../components/profile/Sidebar";
import Support from "../components/profile/Support";
import LayoutOne from "../components/ui/layouts/LayoutOne";
// import { MdOutlineClose } from "react-icons/md";
import back from "../../assets/icons/l-left.png";

const Profile = () => {
  const [active, setActive] = useState("Profile Settings");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate;

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutOne title={"Profile"}>
      <div className="max-w-[892px] min-h-[535px] rounded-[24px] mb-[32px] sm-tablet:p-[40px] sm-tablet:transparent_bg4 m-auto w-full flex sm-tablet:flex-row flex-col gap-[60px] ">
        <div className={`sm-tablet:block hidden`}>
          <Sidebar active={active} setActive={setActive} />
        </div>
        <div className={`sm-tablet:hidden block `}>
          {mobile === "" && (
            <MobilNavigator active={mobile} setActive={setMobile} />
          )}
        </div>
        <div className="max-w-[494px] sm-tablet:block hidden w-full transition-transform ease-in-out duration-500">
          {active === "Profile Settings" && <Settings />}
          {active === "My Addresses" && <Addresses />}
          {active === "Payment Methods" && <Payment />}
          {active === "Live Support" && <Support />}
        </div>
        <div className="sm-tablet:max-w-[494px] sm-tablet:hidden block w-full transition-transform ease-in-out duration-500">
          {mobile !== "" && (
            <span className="w-full mt-[-50px] mb-[30px] flex justify-start text-[#fff] ">
              <img src={back} alt="icon" onClick={() => setMobile("")} />
            </span>
          )}
          {mobile === "Profile Settings" && <Settings />}
          {mobile === "My Addresses" && <Addresses />}
          {mobile === "Payment Methods" && <Payment />}
          {mobile === "Live Support" && <Support />}
        </div>
      </div>
    </LayoutOne>
  );
};

export default Profile;
