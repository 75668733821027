import React from "react";
import logo from "../../../assets/images/logo.png";
import fb from "../../../assets/icons/fb.png";
import { RiInstagramFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
// import { useDataContext } from "../../../middle-ware/StateContext/DataContext";

const Footer = () => {
  // const context = useDataContext();
  const navigate = useNavigate();
  return (
    <div className="min-h-[499px] py-[48px] border-t border-[#ffffff29]  gap-[24px] w-full flex flex-col justify-center items-center">
      <img src={logo} className="w-[120px] h-[120px]" alt="" />
      <span className="text-[#ffffff] opacity-[0.65] leading-[27px] text-[18px] font-[400]">
        See and follow our profile
      </span>
      <div style={{ gap: "40px" }} className="flex gap-[40px]">
        <span
          onClick={() =>
            (window.location.href = "https://web.facebook.com/Eveafterdarkk")
          }
          className="w-[40px] h-[40px] rounded-full bg-[#ffffff] flex justify-center items-center"
        >
          <img src={fb} className="h-[19px]" alt="" />
        </span>
        {/* */}
        <span
          onClick={() =>
            (window.location.href =
              "https://www.instagram.com/eveafterdarkabuja/")
          }
          className="w-[40px] h-[40px] rounded-full bg-[#ffffff] flex justify-center items-center"
        >
          <RiInstagramFill className="text-[#171717] text-[18px]" />
        </span>
      </div>
      <div
        style={{ gap: "40px" }}
        className="flex gap-[40px] max-mobile:flex-row flex-col"
      >
        <Link
          to="/about"
          className="text-[18px] max-mobile:text-start text-center leading-[27px] font-[400] text-[#ffffff] opacity-[0.65]"
        >
          About Us
        </Link>
        <span
          onClick={() => navigate("/user-location")}
          className="text-[18px] cursor-pointer max-mobile:text-start text-center leading-[27px] font-[400] text-[#ffffff] opacity-[0.65]"
        >
          Locations
        </span>
        <Link
          to="/menu"
          className="text-[18px] max-mobile:text-start text-center leading-[27px] font-[400] text-[#ffffff] opacity-[0.65]"
        >
          Food Menu
        </Link>
        <Link
          to="/rider-with-us"
          className="text-[18px] max-mobile:text-start text-center leading-[27px] font-[400] text-[#ffffff] opacity-[0.65]"
        >
          Ride with us
        </Link>
      </div>
      <span
        style={{ gap: "8px" }}
        className="text-[18px] flex max-mobile:flex-row flex-col gap-[8px] leading-[27px] font-[400] text-[#ffffff]"
      >
        <span className="max-mobile:text-start text-center">
          Email.{" "}
          <span className="text-[#FF0018]">help@theeveafterdark.com</span>{" "}
        </span>
        <span className="max-mobile:block hidden"> - </span>
        <span className="max-mobile:text-start text-center">
          Tel. <span className="text-[#FF0018]">+234 708 873 1453</span>
        </span>
      </span>
      <div style={{ gap: "8px" }} className="flex flex-col gap-[8px]">
        <span className="flex gap-[8px] font-[100] text-[18px] text-[#D9D9D9] items-center">
          <Link
            to="/privacy&policy"
            className="cursor-pointer text-[12px] font-[400] leading-[18px] text-[#ffffff] opacity-[0.65]"
          >
            PRIVACY POLICY
          </Link>{" "}
          |
          <Link
            to="/terms&conditions"
            className="cursor-pointer text-[12px] font-[400] leading-[18px] text-[#ffffff] opacity-[0.65]"
          >
            TERMS & CONDITIONS
          </Link>
        </span>
        <span className="text-[#ffffff80] text-[10px] text-center leading-[15px] font-[400]">
          © 2023 Eve After Dark. Developed by{" "}
          <a
            className=" no-underline text-inherit"
            href="https://awadigital.co"
          >
            Awa Digital
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
