import React from "react";
import Accordion from "../ui/Accordion";

const Faq = () => {
  return (
    <div className="px-[35px] py-[64px]">
      <div className="max-w-[1290px] w-full m-auto">
        <span className="text-[24px]  leading-[36px] text-[#ffffff] font-[600] ">
          FAQ
        </span>
        <div className="flex mt-[32px] gap-[32px] flex-col">
          <Accordion
            q={"Do I need my own vehicle?"}
            ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
          />
          <Accordion
            q={"How much can I earn with Eve After Dark?"}
            ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
          />
          <Accordion
            q={"When and how would I be paid with Eve After Dark?"}
            ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
          />
          <Accordion
            q={"What is the opening hours of Eve After Dark?"}
            ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
          />
          <Accordion
            q={"I don’t have any vehicle. Can I still join Eve After Dark?"}
            ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
          />
          <Accordion
            q={"What documents and details do I need to join Eve After dark?"}
            ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
        last={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Faq;
