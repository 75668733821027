import React, { useState, createContext, useContext } from "react";

const reactContext = createContext();

const FlowContextProvider = (props) => {
  const [cart, setCart] = useState({});
  const [addreses, setAddresses] = useState([]);
  const [hotReload, setHotReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cartModalView, setCartModalView] = useState(false);
  const [addressModalView, setAddressModalView] = useState(false);
  const [cartModalItem, setCartModalItem] = useState({});
  const [reloadMenu, setReloadMenu] = useState(false);
  // console.log("flow", reloadMenu);
  return (
    <reactContext.Provider
      value={{
        cart,
        addreses,
        setCart,
        setAddresses,
        hotReload,
        openModal,
        setOpenModal,
        setHotReload,
        cartModalView,
        setCartModalView,
        addressModalView,
        setAddressModalView,
        cartModalItem,
        setCartModalItem,
        reloadMenu,
        setReloadMenu,
      }}
      {...props}
    />
  );
};

const useFlowContext = () => {
  const context = useContext(reactContext);
  return context;
};

export { FlowContextProvider, useFlowContext };
