import { useState } from "react";
import { useFlowContext } from "../../middle-ware/StateContext/FlowContext";
import { appService } from "../../middle-ware/Services/appService";

export const useProduct = (product) => {
  const flowContext = useFlowContext();

  const service = new appService();

  const [loading, setLoading] = useState(false);

  const cart = flowContext?.cart?.items?.filter(
    (i) => i.product.id === product?.id
  );

  const { setCartModalView, setOpenModal, setCartModalItem } = flowContext;

  const addToCart = async () => {
    setLoading(true);
    if (localStorage.token) {
      const res = await service.addToCart(product.id, 1);
      if (res.message === "success") {
        service.getCart();
        if (
          product?.has_related_products ||
          product?.has_spice ||
          product?.has_allergiess
        ) {
          setCartModalView(true);
          setOpenModal(true);
          setCartModalItem(product);
        }
      }
    } else {
      service.addToLocalCart(product, 1);
      service.getLocalCart();if (
        product?.has_related_products ||
        product?.has_spice ||
        product?.has_allergiess
      ) {
        setCartModalView(true);
        setOpenModal(true);
        setCartModalItem(product);
      }
    }
    setLoading(false);
  };
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };

  return {
    loading,
    cart,
    addToCart,
    toPrice,
  };
};
