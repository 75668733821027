import React from "react";
import { useModal } from "./hooks/useModal";
import {  Routes, Route } from "react-router-dom";
import About from "../views/pages/About"
import Address from "../views/pages/Address";
import Order from "../views/pages/Order";
import Catalogue from "../views/pages/Catalogue";
import Checkout from "../views/pages/Checkout";
import Favourites from "../views/pages/Favourites";
import Home from "../views/pages/Home";
import Login from "../views/pages/Login";
import Product from "../views/pages/Product";
import Profile from "../views/pages/Profile";
import Singup from "../views/pages/Singup";
import History from "../views/pages/History";
import Tracking from "../views/pages/Tracking";
// import { useAuthContext } from "./middle-ware/StateContext/AuthContext";
import SelectLocation from "../views/pages/SelectLocation";
// import { useFlowContext } from "./hooks/StateContext/FlowContext";
import VerifyPayment from "../views/pages/VerifyPayment";
import Help from "../views/pages/Help";
import Privacy from "../views/pages/Privacy";
import Error from "../views/pages/Error";
import RideWithUs from "../views/pages/RideWithUs";
import ForFriend from "../views/pages/ForFriend";
import ResetPassword from "../views/pages/ResetPassword";
import ForgotPassword from "../views/pages/ForgotPassword";

const AppRoutes = () => {
  return (
    <>
      {useModal()}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Singup />} />
        <Route path="/address" element={<Address />} />
        <Route path="/menu/*" element={<Catalogue />} />
        <Route path="/product/:category/:item/:id" element={<Product />} />
        <Route path="/favourites" element={<Favourites />} />
        <Route path="/help" element={<Help />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy&policy" element={<Privacy />} />
        <Route path="/terms&conditions" element={<Privacy />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/orders" element={<Order />} />
        <Route path="/orders/history/:id" element={<History />} />
        <Route path="/orders/tracking/:id" element={<Tracking />} />
        <Route path="/user-location" element={<SelectLocation />} />
        <Route path="/rider-with-us" element={<RideWithUs />} />
        <Route path="/buy-for-a-friend" element={<ForFriend />} />
        <Route path="/payment-verification/:ref" element={<VerifyPayment />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/*" element={<ResetPassword />} />
        <Route path="/not-found" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
