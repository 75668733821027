import React from "react";
import { useNavbar } from "../../hooks/useNav";
import CartCard from "../card/CartCard";
import { Link } from "react-router-dom";

const CartLayout = () => {
  const { cart } = useNavbar();
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };

  return (
    <div className="max-h-[581px] sm-tablet:w-[386px] pb-[24px] rounded-b-[20px] max-mobile:w-[330px] w-[300px] h-fit">
      <div className="flex flex-col ">
        <span className="text-[20px] leading-[30px] font-[600] text-[#ffffff]">
          Cart
        </span>
        <span className="text-[14px] leading-[24px] font-[600] text-[#ffffff] opacity-[0.65] ">
          {cart?.items?.length} ITEM
          {cart?.items?.length > 1 && "S"}
        </span>
      </div>
      <div className="flex flex-col max-h-[353px] h-fit pt-[16px] pb-[24px] overflow-y-auto w-full gap-[16px]">
        {cart?.items?.map((i, n) => (
          <CartCard key={n} item={i} />
        ))}
      </div>
      <div className="flex justify-between pt-[24px] border-t border-[#ffffff3b] items-center">
        <div className="flex flex-col ">
          <span className="text-[#ffffff] sm:text-[18px] text-[16px] leading-[27px] font-[600]">
            Subtotal
          </span>
          <span className="text-[#ffffff] sm:text-[14px] text-[12px] leading-[21px] font-[400] opacity-[0.65]">
            Extra charges may apply
          </span>
        </div>
        <span className="sm:text-[18px] text-[16px] whitespace-nowrap text-[#ffffff] leading-[27px] font-[600]">
          {toPrice(cart?.currency, cart?.total)}
        </span>
      </div>
      <Link
        to="/checkout"
        className="text-[#ffffff] mt-[24px] no-underline decoration-inherit flex text-[20px] leading-[30px] font-[500] cursor-pointer max-mobile:h-[66px] h-[50px] w-full justify-center items-center bg-[#ff0018] red_btn_shadow rounded-[100px]"
      >
        Checkout
      </Link>
    </div>
  );
};

export default CartLayout;
