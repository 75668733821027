import React from "react";
import InfoCard from "./InfoCard";
import icon1 from "../../../assets/icons/red-file.svg";
import icon2 from "../../../assets/icons/red-down.svg";
import icon3 from "../../../assets/icons/red-person.svg";

const GetStarted = () => {
  return (
    <div className="py-[48px] px-[32px] max-w-[1290px] w-full m-auto ">
      <span className="text-[#ffffff] flex mini-tablet:justify-start justify-center mini-tablet:text-start w-full text-center mini-tablet:text-[32px] max-mobile:text-[24px] text-[20px] mini-tablet:leading-[48px] max-mobile:leading-[36px] leading-[30px] font-[500]">
        Get Started
      </span>
      <div className="mini-tablet:grid flex flex-col mini-tablet:gap-[8px] gap-[32px] grid-cols-12 w-full mt-[40px]">
        <div className="col-span-4 h-full flex justify-center items-center">
          <InfoCard
            heading={"Sign up online"}
            icon={icon3}
            desc={
              "You’ll need a valid email address and cell phone number to sign up."
            }
          />
        </div>
        <div className=" col-span-4 h-full flex justify-center items-center">
          <InfoCard
            heading={"Check driving requirements"}
            icon={icon1}
            desc={
              "You will need a valid form of Identification that’s up to date and also a profile photo."
            }
          />
        </div>
        <div className=" col-span-4 h-full flex justify-center items-center">
          <InfoCard
            heading={"Download the app"}
            icon={icon2}
            desc={
              "After successfully creating an account download Eve After Dark driver app."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
