import React from "react";

const ChatCard = ({ external, message, time }) => {
  return (
    <div
      className={`w-full flex flex-col ${
        external ? "items-start" : "items-end"
      }`}
    >
      <span
        className={`text-[#FFFFFf] max-mobile:text-[14px] text-[12px] leading-[21px] ${
          external ? "bg-[#ffffff7e]" : "bg-[#ff0018]"
        } max-w-[349px] max-mobile:px-[21px] px-[12px] max-mobile:py-[14px] py-[12px] rounded-[100px]`}
      >
        {message}
      </span>
      <span className="text-[12px] mt-[8px] leading-[18px] font-[400] text-[#ffffff] opacity-[0.65]">
        {time.slice(time.indexOf("T")+1, time.indexOf("T") + 6)} •{" "}
        {external ? "Eve After..." : " You"}
      </span>
    </div>
  );
};

export default ChatCard;
