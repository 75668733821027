import React, { useRef, useState } from "react";
import caution from "../../../../assets/icons/caution.svg";
// import vector from "../../../../assets/icons/select.svg";
// import { IoIosArrowForward } from "react-icons/io";
// import Loader from "../Loader";

const AddressInput = ({
  list,
  holder,
  onChange,
  error,
  light,
  loading,
  name,
  value,
  ready,
  onSelect,
}) => {
  //   const [value, setValue] = useState("");
  const [toggle, setToggle] = useState(false);
  const [view, setView] = useState(false);
  const selectRef = useRef();
  const open = () => {
    setToggle(true);
    setView(true);
  };
  const close = () => {
    setView(false);
    setTimeout(() => setToggle(false), 100);
  };
  window.addEventListener("click", (e) => {
    if (toggle && !selectRef.current?.contains(e.target)) close();
  });
  
  return (
    <div className="relative w-full">
      <div
        className={`flex transition-all  relative w-full ease-in-out duration-500 flex-col py-[8px] border-b-[0.65px] ${
          light
            ? "border-[#ffffff]"
            : "mobile:border-[#0000005d] border-[#ffffff]"
        }`}
      >
        <span
          className={`text-[12px] transition-all ease-in-out duration-500 h-[18px] leading-[18px] ${
            light ? "text-[#FFFFFF]" : "mobile:text-[#000000] text-[#FFFFFF]"
          } opacity-[0.5]`}
        >
          {value !== "" && holder}
        </span>
        <div
          ref={selectRef}
          onClick={toggle ? close : open}
          className="flex gap-[16px] relative  justify-between w-full items-center"
        >
          <input
            type="text"
            placeholder={"Street"}
            name={name}
            value={value}
            // disabled={!ready}
            onChange={onChange}
            className={`w-full ${
              name !== "email" && name !== "password" && "capitalize"
            } bg-transparent transition-all ease-in-out duration-500 outline-0 border-none autofill:bg-transparent placeholder:text-[20px] ${
              light
                ? " placeholder:text-[#ffffff9a]"
                : "mobile:placeholder:text-[#000000] placeholder:text-[#ffffff9a] "
            } placeholder:opacity-[0.65] mini-tablet:text-[20px] mobile:text-[18px] text-[16px] mini-tablet:leading-[30px] mobile:leading-[27px] leading-[24px] font-[400] ${
              light ? "text-[#FFFFFF]" : "mobile:text-[#000000] text-[#FFFFFF]"
            }`}
          />
          {/* <img src={vector} alt="icon" /> */}
          {list?.length > 0 && (
            <div
              className={`absolute z-20 ${
                name === "area" ? "top-[-100px]" : "top-[50px]"
              } mobile:bg-[#fff] bg-black rounded-lg gap-4 flex flex-col z-50 w-full p-5 max-mobile:max-h-[300px] max-h-[400px] overflow-y-auto transition-transform ease-in-out duration-300 ${
                view ? "translate-y-0" : "translate-y-[-50px]"
              }`}
            >
              {loading &&
                list?.map(({ description }, n) => (
                  <span
                    key={n}
                    onClick={() => {
                      onSelect(description);
                      close();
                    }}
                    className="font-[500] text-[18] mobile:text-[#000000] text-[#ffffff]"
                  >
                    {description}
                  </span>
                ))}
            </div>
          )}
        </div>
      </div>
      {error && (
        <span
          className={`${
            error !== "" ? "text-[#FF0018] opacity-[1]" : "opacity-0"
          } text-[12px] leading-[18px] font-[400] h-[18px]`}
        >
          <span className="flex gap-[8px] items-center mt-[8px] ">
            <img src={caution} alt="" /> {error}
          </span>
        </span>
      )}
    </div>
  );
};

export default AddressInput;
