import React from 'react'
import ex from "../../../assets/icons/ex.svg";
import social1 from "../../../assets/icons/social1.svg";
import social2 from "../../../assets/icons/social2.svg";
import social3 from "../../../assets/icons/social3.svg";
import { toast } from 'react-hot-toast';

const ShareModal = ({ setShare}) => {
  return (
    <div className="fixed top-0 left-0 bottom-0 z-[999999999] bg-[#00000041] flex justify-center items-center w-[100%] h-screen">
    <div className="sm-tablet:w-[544px] max-mobile:w-[500px] w-[370px] flex flex-col bg-[#151515] sm-tablet:gap-[24px] gap-[16px] p-[32px] rounded-[24px] items-center ">
      <span onClick={() => setShare(false)} className="w-full">
        <img
          src={ex}
          className="cursor-pointer max-mobile:w-[20px] w-[16px]"
          alt=""
        />
      </span>
      <span className="text-[#ffffff] max-mobile:text-[32px] text-[20px] max-mobile:leading-[48px] leading-[30px]">
        Share with others
      </span>
      <div className="flex gap-[32px] justify-center">
        <img
          className=" cursor-pointer max-mobile:w-[47px] w-[40px] h-fit"
          src={social1}
          onClick={() =>
            (window.location.href = "https://www.facebook.com/")
          }
          alt=""
        />
        <img
          className=" cursor-pointer max-mobile:w-[47px] w-[40px] h-fit"
          src={social2}
          onClick={() =>
            (window.location.href = "https://web.whatsapp.com")
          }
          alt=""
        />
        <img
          className=" cursor-pointer max-mobile:w-[47px] w-[40px] h-fit"
          src={social3}
          onClick={() =>
            (window.location.href = "https://twitter.com/compose/tweet")
          }
          alt=""
        />
      </div>
      <span
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          toast.success("Copied to clipboard");
        }}
        className="max-mobile:text-[20px] text-[14px] cursor-pointer font-[500] w-full bg-[#ff0018] rounded-[100px] text-[#fff] sm-tablet:h-[66px] max-mobile:h-[63px] h-[57px] flex items-center justify-center"
      >
        Copy invite link
      </span>
    </div>
  </div>
  )
}

export default ShareModal