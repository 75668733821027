import { useEffect, useState } from "react";
import { appService } from "../../middle-ware/Services/appService";
import { useAuthContext } from "../../middle-ware/StateContext/AuthContext";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useFetchData = () => {
  const service = new appService();
  // const auth = useAuthContext();
  const naviagte = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : "";

  const path = window.location.pathname;
  // console.log("path", path);

  const getData = async () => {
    // console.log("getdata loader");
    setLoading(true);
    if (localStorage.token && localStorage.currentAddress) {
      const response2 = await service.getCart();
      const response3 = await service.getFavourites();
      // console.log("has token");
      await service.getPorpularDishes();
      if (
        // response1?.message === "unauthorized" ||
        response2.message === "unauthorized" ||
        response3.message === "unauthorized"
      ) {
        localStorage.clear();
        toast.error("Session timeout");
        naviagte("/");
        setLoading(false);
      }
      setLoading(false);

      // } else if (!localStorage.token && localStorage.currentAddress) {
    } else if (!localStorage.token) {
      // console.log("no token");
      service.getLocalCart();
      await service.getPorpularDishes();
      setLoading(false);
    }
    setLoading(false);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const getAllCategories = async () => {
    const res = await service.getAllCategories();
    if (res.message === "success") {
      const { data } = res;
      const menuRoute = path.includes("menu")
        ? path.slice(6).replaceAll("%20", " ")
        : "";
      const category_id =
        menuRoute.length > 0
          ? data.find((c) => c.title === menuRoute)?.id
          : data[0].id;

      if (
        localStorage.token &&
        localStorage.currentAddress &&
        !localStorage.guestUser
      ) {
        await service.getProductsByCategory(category_id);
      } else {
        await service.getProductsByCategoryAndLocation(
          category_id,
          location.id
        );
      }
    } else {
      if (res?.message === "unauthorized") {
        localStorage.clear();
        toast.error("Session timeout");
        naviagte("/");
      }
    }
  };

  const getAllProducts = async () => {
    await service.getAllProducts();
  };

  useEffect(() => {
    stopLoading();
  }, []);

  // useEffect(() => {
  //   getData();
  //   getAllProducts();
  //   // eslint-disable-next-line
  // }, [auth?.isLoggedIn, localStorage.token]);

  return { loading, getAllCategories, getData, getAllProducts, stopLoading };
};
