import React from "react";
import { BsStarFill } from "react-icons/bs";
// import avatar from "../../assets/images/avatar1.png";

const CommentCard = ({ avatar, text, name }) => {
  const random = Math.random();
  const makeRandom = (random * (5.0 - 3.5) + 3.5).toFixed(1);

  return (
    <div className="flex transparent_bg2 gap-[16px] p-[16px] light-shadow backdrop-blur-[20px] rounded-[24px] mini-tablet:w-[518px] mobile:w-[443px] w-[350px]  mini-tablet:h-[147px] h-[123px]">
      <img
        src={avatar}
        className="mini-tablet:w-[50px] w-[40px] mini-tablet:h-[50px] h-[40px] rounded-full "
        alt=""
      />
      <div className="flex flex-col w-full overflow-hidden text-ellipsis">
        <div className="flex items-center w-full justify-between">
          <span className="mini-tablet:text-[18px] text-[16px] mini-tablet:leading-[36px] leading-[24px] font-[500] text-[#ffffff] opacity-[0.86]">
            {name}
          </span>
          <div className="flex items-center gap-[10px]">
            <BsStarFill className="text-[20px] text-[#ffa503]" />
            <span className="text-[14px] leading-[21px] font-[500] text-[#ffffff]">
              {makeRandom}
            </span>
          </div>
        </div>
        <div className="dotted_text mini-tablet:h-[72px] h-[63px]">
          <span className="font-[400]  text-ellipsis text-[#ffffff] opacity-[0.65] mini-tablet:text-[16px] text-[14px] mini-tablet:leading-[24px] leading-[21px] ">
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
