import React from "react";
import UserNavbar from "../components/navbar/mainLayouts/UserNavbar";
import Navbar from "../components/navbar/mainLayouts/Navbar";

export const useNavbar = () => {
  return (
    <div className="mobile:py-[46px] py-[20px] max-w-[1288px] m-auto ">
      {localStorage.token && !localStorage.guestUser ? (
        <UserNavbar />
      ) : (
        <Navbar />
      )}
    </div>
  );
};
