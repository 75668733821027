import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";

const CartAccordion = ({ children, title, label, helper, value }) => {
  const [open, setOpen] = useState(false);
  const [delay, setDelay] = useState(false);

  const toggle = () => {
    if (!open) {
      setOpen(true);
      setTimeout(() => {
        setDelay(true);
      }, 30);
    } else {
      setDelay(false);
      setTimeout(() => {
        setOpen(false);
      }, 100);
    }
  };

  return (
    <div className=" flex flex-col gap-[16px] w-full ">
      <div
        onClick={toggle}
        className="flex flex-col gap-[12px]  cursor-pointer w-full "
      >
        <div className="flex justify-between items-center w-full">
          <span className="flex gap-[8px] text-[#fff] sm:text-[20px] text-[18px] leading-[30px] items-center">
            {title}
            <span className="text-[14px] leading-[21px] opacity-[0.65]">
              (optional)
            </span>
          </span>
          <span className="text-[12px] text-[#ffffffa6] leading-[18px]">
            <BiChevronDown
              className={`text-[24px] text-[#fff] transition-all ease-in-out ${
                open ? "rotate-180" : "rotate-0"
              }`}
            />
          </span>
        </div>
        <div className="flex justify-between items-center w-full">
          <span className="text-[16px] text-[rgba(255,255,255,0.65)]">{helper}</span>
        </div>
      </div>
      {open && (
        <div
          className={` transition-all ease-in-out delay-75 h-fit w-full ${
            delay ? "translate-y-0" : "translate-y-[-20px]"
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default CartAccordion;
