import React from "react";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { appService } from "../../../middle-ware/Services/appService";
import Images from "./Images";

const CartCard = ({ item }) => {
  const service = new appService();

  const updateCart = (action) => {
    if (localStorage.token) {
      if (action === "plus") {
        service.updateCart(item?.id, item?.quantity + 1);
      } else {
        item?.quantity > 1 && service.updateCart(item?.id, item?.quantity - 1);
      }
    } else {
      if (action === "plus") {
        service.addToLocalCart(item?.product, item?.quantity + 1);
        service.getLocalCart();
      } else {
        item?.quantity > 1 &&
          service.addToLocalCart(item?.product, item?.quantity - 1);
        service.getLocalCart();
      }
    }
  };
  const deleteCartItem = () => {
    if (localStorage.token) {
      service.deleteCartItem(item.id);
    } else {
      service.deleteLocalCart(item.product);
      service.getLocalCart();
    }
  };
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };
  // console.log(item);
  return (
    <div className="max-w-[400px] items-center grid grid-cols-6 justify-between w-full h-[101px] p-[16px] transparent_bg3 light-shadow backdrop-blur-[20px] rounded-[16px]">
      <div className="flex gap-[8px] text-ellipsis truncate col-span-4 ">
        <Images
          url={item?.product?.image?.url}
          width={"30px"}
          height={"30px"}
          radius={"100%"}
          classes={"min-w-[30px] h-[30px] rounded-full"}
        />
        <div className="flex flex-col gap-[10px] ">
          <div className="flex flex-col ">
            <span className="text-[16px] truncate desktop:max-w-[172px] max-tablet:max-w-[150px] tablet:max-w-[80px] whitespace-nowrap text-[#ff0018] leading-[24px] font-[500]">
              {item.product.title}
            </span>
            <span className="text-[12px] flex items-center gap-2 leading-[18px] text-[#ffffff] opacity-[0.65] font-[400]">
              {localStorage.token
                ? toPrice(item?.unit_price?.currency, item?.unit_price?.price)
                : toPrice(
                    item?.product?.product_price?.currency,
                    item?.product?.product_price?.price
                  )}
              <span> each</span>
            </span>
          </div>
          <span className="text-[16px] leading-[27px] text-[#ffffff] opacity-[0.65] font-[600] ">
            {localStorage.token
              ? toPrice(item?.unit_price?.currency, item?.subtotal)
              : toPrice(
                  item?.product?.product_price?.currency,
                  item?.subtotal * item.quantity
                )}
          </span>
        </div>
      </div>
      <div className=" flex flex-col col-span-2  items-end justify-end">
        <div className="w-[116px] border border-[#ffffff17] rounded-[100px] h-[48px] px-[16px] py-[11.5px] flex justify-between items-center">
          <span
            onClick={() => item.quantity > 1 && updateCart("minus")}
            className="w-[24px] cursor-pointer text-[#ffffff] h-[24px] flex justify-center items-center rounded-full bg-[#ffffff17]"
          >
            <BiMinus />
          </span>
          <span className="text-[11.55px] leading-[17px] text-[#ffffff] text-center">
            {item.quantity}
          </span>
          <span
            onClick={() => updateCart("plus")}
            className="w-[24px] cursor-pointer text-[#ffffff] h-[24px] flex justify-center items-center rounded-full bg-[#ffffff17]"
          >
            <BsPlus />
          </span>
        </div>
        <span
          onClick={() => deleteCartItem()}
          className="text-[12px] text-[#ff0018] opacity-[0.65] leading-[18px] cursor-pointer underline "
        >
          Delete
        </span>
      </div>
    </div>
  );
};

export default CartCard;
