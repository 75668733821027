import React, { useState, useRef } from "react";
import CommentCard from "./CommentCard";
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  // BsStarFill,
} from "react-icons/bs";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Comments = () => {
  const forwardRef = useRef(null);
  const backwardRef = useRef(null);
  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(false);

  const rates = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div className="max-w-[1288px] py-[68px] w-full m-auto">
      <div className="flex w-full justify-between items-center  px-[35px]">
        <div className="flex flex-col">
          <span className="text-[#ffffff] whitespace-nowrap opacity-[0.65] max-mobile:text-[24px] text-[20px] max-mobile:leading-[36px] leading-[30px] font-[600]">
            What it’s like working with us
          </span>
        </div>
        <div className="mobile:flex hidden gap-[16px]">
          <span
            onClick={() => backwardRef.current.click()}
            className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer mini-tablet:w-[56px] w-[50px] mini-tablet:h-[56px] h-[50px] ${
              !start && "bg-[#ff0018]"
            } flex justify-center items-center rounded-full`}
          >
            <BsArrowLeftShort className="text-[21px] text-[#ffffff]" />
          </span>
          <span
            onClick={() => forwardRef.current.click()}
            className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer mini-tablet:w-[56px] w-[50px] mini-tablet:h-[56px] h-[50px] ${
              !end && "bg-[#ff0018]"
            } flex justify-center items-center rounded-full`}
          >
            <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
          </span>
        </div>
      </div>

      <div className=" w-full  mt-[28px] mb-[24px]">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={39}
          slidesPerView={2}
          breakpoints={{
            0: {
              width: 398,
              slidesPerView: 1,
              spaceBetween: 10,
            },
            550: {
              slidesPerView: 1,
              width: 596,
              spaceBetween: 10,
            },
            1180: {
              slidesPerView: 2,
            },
          }}
          onSwiper={(swiper) => {
            setStart(swiper.isBeginning);
            setEnd(swiper.isEnd);
          }}
          onSlideChange={(swiper) => {
            setStart(swiper.isBeginning);
            setEnd(swiper.isEnd);
          }}
        >
          {rates.map((e, n) => (
            <SwiperSlide key={n}>
              <CommentCard />
            </SwiperSlide>
          ))}
          <SwiperButtonNext>
            <span className="hidden absolute" ref={forwardRef}>
              <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
            </span>
          </SwiperButtonNext>
          <SwiperButtonPrev>
            <span className="hidden absolute" ref={backwardRef}>
              <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
            </span>
          </SwiperButtonPrev>
        </Swiper>
      </div>
    </div>
  );
};
const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slideNext()}>{children}</span>;
};
const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slidePrev()}>{children}</span>;
};

export default Comments;
