import React, { useEffect, useState } from "react";
import DeliveryRating from "./DeliveryRating";
import RecipientCard from "./RecipientCard";
import Map1 from "../maps/Map1";
import RecipientDropDown from "./RecipentDropDown";
import { appService } from "../../../middle-ware/Services/appService";
import { useParams } from "react-router-dom";

const OrderHistory = () => {
  const service = new appService();
  const [order, setOrder] = useState({});
  const params = useParams();

  const getOrder = async () => {
    const res = await service.getOrder(params.id);
    console.log(res);
    if (res.message === "success") {
      // console.log(res);

      setOrder(res.data);
    }
  };
  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  // console.log(order);
  return (
    <div className="relative w-full h-[724px]">
      <Map1 />
      <div className="absolute px-[16px] w-full top-[12px] sm-tablet:hidden flex justify-center ">
        <RecipientDropDown info={order} />
      </div>
      <div className="absolute overflow-hidden flex gap-[16px] justify-between items-end sm-tablet:px-[32px] px-[16px] sm-tablet:py-[24px] py-[16px] w-full left-0 bottom-0 right-0">
        <DeliveryRating info={order} />
        <div className="sm-tablet:block hidden">
          <RecipientCard processed={true} info={order} />
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
