import React, { useState } from "react";
import caution from "../../../../assets/icons/caution.svg";
import { HiChevronDown } from "react-icons/hi";
import { useRef } from "react";

const PhoneInput = ({
  value,
  type,
  onChange,
  name,
  error,
  label,
  light,
  code,
  setPhonetype,
}) => {
  const toggleRef = useRef();
  const [toggle, setToggle] = useState(false);
  window.addEventListener("click", (e) => {
    if (toggle && !toggleRef.current?.contains(e.target)) {
      setToggle(false);
    }
  });
  const changeCode = (val) => {
    setPhonetype(val);
    setToggle(false);
  };
  return (
    <div>
      <div
        className={`flex transition-all capitalize w-full ease-in-out duration-500 flex-col py-[8px] border-b-[0.65px] ${
          light ? "border-[#ffffff9a]" : "mobile:border-[#00000081]"
        }`}
      >
        <span
          className={`text-[12px] transition-all ease-in-out duration-500 h-[18px] leading-[18px] ${
            light ? "text-[#FFFFFF]" : "mobile:text-[#000000] text-[#FFFFFF]"
          } opacity-[0.5]`}
        >
          {value !== "" && label}
        </span>
        <div className="grid grid-cols-12 gap-[16px] w-full items-center relative">
          <div
            ref={toggleRef}
            className={`${
              error !== ""
                ? "text-[#FF0018]"
                : light
                ? "text-[#FFFFFF]"
                : "mobile:text-[#000000] text-[#ffffff]"
            } mini-tablet:text-[20px] col-span-2 mobile:text-[18px] text-[16px] mini-tablet:leading-[30px] mobile:leading-[27px] leading-[24px] font-[400]`}
          >
            <span
              onClick={() => setToggle(!toggle)}
              className=" flex items-center justify-between cursor-pointer"
            >
              {code} <HiChevronDown />
            </span>
            {toggle && (
              <div className=" absolute z-[9999999] text-[#000] w-full top-[30px] border h-fit shadow-md p-2 bg-[#fff] rounded-[10px] left-0 bottom-0 flex flex-col">
                <span onClick={()=> changeCode("+44")} className="cursor-pointer text-[14px]">+44</span>
                <span onClick={()=> changeCode("+234")} className="cursor-pointer text-[14px]">+234</span>
              </div>
            )}
          </div>
          <input
            type={type}
            placeholder={label}
            name={name}
            value={value}
            maxLength={name === "phone" ? 10 : name === "cvc" ? 3 : 59}
            minLength={0}
            onChange={onChange}
            className={`w-full col-span-10 bg-transparent transition-all ease-in-out duration-500 outline-0 border-none autofill:bg-transparent placeholder:text-[20px] ${
              light
                ? " placeholder:text-[#ffffff9a]"
                : "mobile:placeholder:text-[#000000] placeholder:text-[#ffffff9a] "
            } placeholder:opacity-[0.65] mini-tablet:text-[20px] mobile:text-[18px] text-[16px] mini-tablet:leading-[30px] mobile:leading-[27px] leading-[24px] font-[400] ${
              error && error !== ""
                ? "text-[#FF0018]"
                : light
                ? "text-[#FFFFFF]"
                : "mobile:text-[#000000] text-[#FFFFFF]"
            }`}
          />
        </div>
      </div>
      {error && (
        <span
          className={`${
            error !== "" ? "text-[#FF0018] opacity-[1]" : "opacity-0"
          } text-[12px] leading-[18px] font-[400] h-[18px]`}
        >
          <span className="flex gap-[8px] items-center mt-[8px] ">
            <img src={caution} alt="" /> {error}
          </span>
        </span>
      )}
    </div>
  );
};

export default PhoneInput;
