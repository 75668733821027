import React, { useState } from "react";
import AuthLayout from "../components/ui/layouts/AuthLayout";
import Input from "../components/ui/input/Input";
import { Link } from "react-router-dom";
import client from "../../middle-ware/axiosInstance";
import Loader from "../components/ui/Loader";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const [form, setForm] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, [name]: "Enter a valid email adddress" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    const { email } = form;
    const error = {};
    if (email === "") error.email = "Required*";
    else if (email !== "" && !validateEmail(email))
      error.email = "Enter a valid email adddress";
    return error;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        await client.post("/auth/password/request-update", {
          user: {
            email: form.email,
          },
        });
        toast.success("Email has been sent")
        setLoading(false);
      } catch (err) {
        toast.error("Failed to login, Please try again");
        setLoading(false);
      }
    }
  };
  return (
    <AuthLayout
      title={"Get Back into your account"}
      text={"You will receive a verification email if you already have an account."}
    >
      <form className="w-full flex-col flex gap-[32px]" action="">
        <div className="flex flex-col gap-[20px] w-full">
          <Input
            value={form.email}
            type={"email"}
            onChange={handleChange}
            name="email"
            error={errors.email}
            label={"Email"}
          />
        </div>

        <span
          onClick={() => handleSubmit()}
          className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
        >
          {loading ? (
            <Loader size={[40, 40]} color="#ffffff" />
          ) : (
            "Send Recovery Email"
          )}
        </span>

        <span className="text-[16px] text-center leading-[24px] font-[400] text-[#A3A3A3]">
          Have an account?
          <Link to="/login" className="text-[#FF0018] cursor-pointer">
            Sign In
          </Link>
        </span>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
