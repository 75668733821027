import React from "react";
import Accordion from "./Accordion";

const Faq = () => {
  return (
    <div className="">
      <span className="text-[24px]  leading-[36px] text-[#ffffff] font-[600] ">
        Frequently Asked Questions
      </span>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        <Accordion
          q={"Who Owns Eve After Dark?"}
          ans={
            <span className="flex flex-col">
              Chioma Nkwakwesi Nnadi - Founder/CEO
              <span>Richard Nnadi - Co-founder</span>
            </span>
          }
        />
        <Accordion
          q={"Does Eve after dark closes for the year?"}
          ans={`No, Its a Cloud Kitchen that operates on-demand (24hr, 365days)`}
        />
        <Accordion
          q={"How can I order?"}
          ans={`Website link or download the app `}
        />
        <Accordion q={"Can I pick up?"} ans={`No`} />
        <Accordion
          q={"Where is Eve After Dark located?"}
          ans={`It's a cloud kitchen and does not have any physical store but we serve particularly in Lagos and Abuja.`}
        />
      </div>
    </div>
  );
};

export default Faq;
