import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appService } from "../../middle-ware/Services/appService";
import LayoutTwo from "../components/ui/layouts/LayoutTwo";
import OrderSlide1 from "../components/order/OrderSlide1";
import OrderSlide2 from "../components/order/OrderSlide2";
import CurrentOrder from "../components/cart/CurrentOrder";
// import OrderCart from "../components/cart/OrderCart";

const Order = () => {
  const navigate = useNavigate();
  const service = new appService();
  const path = window.location.href;
  const [data, setData] = useState([]);

  const getAllOrders = async () => {
    const res = await service.getAllOrders();
    console.log(path);
    if (res.message) setData(res.data);
  };

  const triggerConversion = () => {
    console.log("conversion logged");
    window.gtag("event", "conversion", {
      send_to: "AW-854169182/KrZTCKnG0JIZEN6sppcD",
      value: 6.9,
      currency: "USD",
      // transaction_id:,
    });
  };

  useEffect(() => {
    if (!localStorage.token) navigate("/login");
    else if (!localStorage.currentAddress) navigate("/user-location");
    else getAllOrders();
    if (path.includes("completed=true")) triggerConversion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutTwo
      title={"Your Orders"}
      LeftComponent={
        <Fragment>
          <div className=" tablet:hidden block tablet:pb-0 pb-[32px] border-b border-[#ffffff29] ">
            <CurrentOrder title={"Current Cart"} processed />
          </div>
          <div className="tablet:pt-0 py-[32px] flex flex-col gap-[32px]">
            <OrderSlide1
              title={"Pending Orders"}
              data={data?.filter((o) => o.status !== "completed")}
            />
            <OrderSlide2 data={data?.filter((o) => o.status === "completed")} />
          </div>
        </Fragment>
      }
      RightComponent={<CurrentOrder title={"Current Cart"} />}
    />
  );
};

export default Order;
