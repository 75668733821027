import React from "react";
import Images from "./Images";
// import { useDataContext } from "../../../middle-ware/StateContext/DataContext";

const OrderCartCard = ({ item }) => {
  // const context = useDataContext();
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };

  return (
    <div className=" items-center px-3 flex gap-[8px] justify-between w-full mobile:h-[101px] h-[84px] p-[16px] transparent_bg3 light-shadow backdrop-blur-[20px] rounded-[16px]">
      <div className=" flex gap-[8px]">
        {/* <img
          src={item?.product?.image?.url}
          className="w-[30px] h-[30px] rounded-full"
          alt=""
        /> */}

        <Images
          classes={"w-[30px] h-[30px] rounded-full"}
          url={item?.product?.image?.url}
          width={"30px"}
          height={"30px"}
          radius={"100%"}
        />

        {/* 220px */}
        <div className="flex flex-col ">
          <span className="text-[18px] truncate sm:max-w-[200px] whitespace-nowrap text-[#ff0018] leading-[27px] font-[500]">
            {item.product.title}
          </span>
          <span className="text-[14px] flex items-center gap-2 leading-[21px] text-[#ffffff] opacity-[0.65] font-[400]">
            {localStorage.token
              ? toPrice(item?.unit_price?.currency, item?.unit_price?.price)
              : toPrice(
                  item?.product?.product_price?.currency,
                  item?.product?.product_price?.price
                )}{" "}
            <span> each</span>
          </span>
        </div>
      </div>
      {/* <span className=" text-[#ffffff] text-[12px] leading-[18px] font-[600]">
        
      </span> */}
    </div>
  );
};

export default OrderCartCard;
