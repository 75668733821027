import React from "react";
import logo from "../../../../assets/images/logo.png";
import ng from "../../../../assets/images/ng.svg";
import uk from "../../../../assets/images/uk.svg";
import au from "../../../../assets/images/dubar-flag.png";
import tx from "../../../../assets/images/texas.png";
import { Link } from "react-router-dom";
import PageLink from "../utils/PageLink";
import CartContainer from "../utils/CartContainer";
import { useLocationHandler } from "../../../hooks/useLocationHandler";
import { FiChevronDown } from "react-icons/fi";
import Loader from "../../ui/Loader";
import { SmallForFriendFloater } from "../utils/ForFriendFloater";
// import { useHotReload } from "../../../hooks/useHotReload";

const Navbar = () => {
  const {
    locations,
    currentLocation,
    changeLocation,
    loading,
    locationId,
    setLocationId,
    setUserLocation,
    delayToggler,
    openLocationList,
    closeLocationList,
    toggler,
    listRef,
  } = useLocationHandler();
  // console.log(currentLocation);
  const getCountryFlag = (name) => {
    if (name === "Dubai") return au;
    else if (name === "London") return uk;
    else if (name === "Texas") return tx;
    else if (name === "FCT") return ng;
    else if (name === "Lagos") return ng;
    else return logo;
  };
  return (
    <div className="m-auto grid grid-cols-12 ">
      <span className="mini-tablet:col-span-2 col-span-6 mr-4 flex items-center ">
        <Link to="/">
          <img
            src={logo}
            className=" mini-tablet:min-w-[120px] mobile:min-w-[80px] min-w-[70px] mini-tablet:h-[120px] mobile:h-[80px] h-[70px] "
            alt=""
          />
        </Link>
      </span>
      <div className="tablet:col-span-6 mini-tablet:col-span-5 mini-tablet:flex  hidden items-center mr-4">
        <div
          ref={listRef}
          className="flex relative max-tablet:mr-[72px] gap-2 items-center text-[#a3a3a3] mr-[30px] leading-[27px] text-[18px] font-[400px]"
        >
          <span
            onClick={toggler ? closeLocationList : openLocationList}
            className="w-[24px] h-[24px] rounded-full bg-[#a3a3a3] overflow-hidden"
          >
            <img src={getCountryFlag(currentLocation?.state)} alt="" />
          </span>
          <span className="cursor-pointer" onClick={toggler ? closeLocationList : openLocationList}>
            {currentLocation?.state}
          </span>
          <FiChevronDown />
          {toggler  && (
            <div
              className={`absolute transition-all ease-in-out ${
                delayToggler ? "translate-y-0" : "translate-y-[-20px]"
              } top-[30px] text-[14px] px-[4] flex flex-col transparent_bg w-[150px] p-2 rounded-[14px] `}
            >
              {locations?.map((l, n) => {
                return (
                  <span
                    onClick={() => {
                      setLocationId(l.id);
                      changeLocation(l);
                    }}
                    className="cursor-pointer flex"
                    key={n}
                  >
                    {l.state}
                    {loading && l.id === locationId && (
                      <Loader size={[20, 20]} color={"#a3a3a3"} />
                    )}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <PageLink path={"menu"} />
        <PageLink
          path={"Buy For A Friend"}
          to={"buy-for-a-friend"}
          style={
            localStorage.forAFriend
              ? { background: "#fff", color: "#FF0018", padding: "18px 32px" }
              : {}
          }
        />
      </div>
      <div className="tablet:col-span-4 mini-tablet:col-span-5 col-span-6 flex items-center justify-end">
        <div className="flex items-center gap-3">
          <CartContainer />
          <SmallForFriendFloater />
        </div>

        <Link
          to={"/login"}
          className="px-[32px] decoration-inherit no-underline mini-tablet:block hidden whitespace-nowrap py-[18px] leading-[27px] text-[18px] font-[400px] text-[#a3a3a3] cursor-pointer"
        >
          Log In
        </Link>
        <Link
          to={"/signup"}
          className="bg-[#FF0018] mini-tablet:block hidden decoration-inherit no-underline mobile:text-[18px] text-[14px] whitespace-nowrap cursor-pointer text-[#ffffff] rounded-[100px] red_btn_shadow mobile:px-[32px] px-[18px]  mobile:py-[18px] py-[12px] "
        >
          Sign Up
        </Link>
        <Link
          to={"/login"}
          className="bg-[#FF0018] mini-tablet:hidden block ml-5 decoration-inherit no-underline mobile:text-[18px] text-[14px] whitespace-nowrap cursor-pointer text-[#ffffff] rounded-[100px] red_btn_shadow mobile:px-[32px] px-[18px]  mobile:py-[18px] py-[12px] "
        >
          Login
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
