import { useEffect, useState } from "react";
import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import { appService } from "../../middle-ware/Services/appService";
import { useNavigate, useParams } from "react-router-dom";
import { useFlowContext } from "../../middle-ware/StateContext/FlowContext";
// import { toast } from "react-hot-toast";

export const useCatalogue = () => {
  const context = useDataContext();
  const service = new appService();
  const navigate = useNavigate();

  const route = useParams();

  //

  const {
    menuProducts,
    setMenuProducts,
    setDealsOfTheDay,
    products,
    categories,
  } = context;

  const { reloadMenu, setReloadMenu,  } = useFlowContext();

  // const [products, setProducts] = useState(menuProducts);

  const productsByRoute = categories?.filter(
    (c) => c.title === route["*"]?.replace("%20", " ")
  );

  const currentCategory = {
    title:
      route["*"] === ""
        ? categories[0]?.title
        : route["*"]?.replace("%20", " "),
    products: route["*"] === "" ? categories[0]?.items : productsByRoute,
  };

  const [category, setCategory] = useState(currentCategory.title);
  // const [products, setProducts] = useState(currentCategory.products);

  const [modal, setModal] = useState(false);
  const [pages, setPages] = useState(1);
  const [currentPge, setCurrent] = useState(1);
  const [slice, setSlice] = useState(3);
  const [deals, setDeals] = useState([]);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(localStorage["max-price"]);
  // const [currency, setCurrency] = useState("");

  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [vissible, setVissible] = useState(false);

  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : "";

  const handleProducts = async (name, category_id) => {
    name && navigate(`/menu/${name}`);
    setCategory(categories.find((c) => c.id === category_id));
    getPages();
    if (
      localStorage.token &&
      localStorage.currentAddress &&
      !localStorage.guestUser
    ) {
      await service.getProductsByCategory(category_id);
    } else {
      await service.getProductsByCategoryAndLocation(category_id, location.id);
    }
  };

  const rangeProducts = () => {
    setMenuProducts(
      context?.categories[0]?.items?.filter(
        (i) =>
          (parseInt(i.product_price.price) < max ||
            parseInt(i.product_price.price) === max) &&
          (parseInt(i.product_price.price) > min ||
            parseInt(i.product_price.price) === min)
      )
    );
  };

  const getPages = () => {
    setPages(Math.ceil(products?.length / 6));
  };

  const allowedIndex = 6;

  // const checkProducts = async () => {
  //   if (route["*"] && route["*"] !== "")
  //     setProducts(
  //       context?.categories
  //         ?.filter((item) => item.title === route["*"])[0]
  //         ?.items?.filter(
  //           (i) =>
  //             (parseInt(i.product_price.price) < max ||
  //               parseInt(i.product_price.price) === max) &&
  //             (parseInt(i.product_price.price) > min ||
  //               parseInt(i.product_price.price) === min)
  //         )
  //     );
  //   else
  //     setProducts(
  //       context?.categories[0]?.items?.filter(
  //         (i) =>
  //           (parseInt(i.product_price.price) < max ||
  //             parseInt(i.product_price.price) === max) &&
  //           (parseInt(i.product_price.price) > min ||
  //             parseInt(i.product_price.price) === min)
  //       )
  //     );
  // };

  const dealsOfTheDay = async () => {
    const res = await service.getDealsOfTheDay();
    if (res.message === "success") {
      setDeals(res.data.products);
      setDealsOfTheDay(res.data.products);
    }
  };
  const path = window.location.pathname;

  const verifyCategory = () => {
    if (route["*"] !== "" && productsByRoute?.length === 0) {
      navigate("/not-found");
    }
    const menuRoute = path.includes("menu")
      ? path.slice(6).replaceAll("%20", " ")
      : "";

    const category_ =
      menuRoute.length > 0
        ? categories.find((c) => c.title === menuRoute)
        : categories[0];

    setCategory(category_);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const allProducts = context.products;
    const result = allProducts.filter((p) => p.title.includes(e.target.value));
    setSearchResults(result);
    setTimeout(() => {
      setVissible(true);
    }, 50);
  };

  const closeSearch = () => {
    setVissible(false);
    setTimeout(() => {
      setSearch("");
    }, 50);
  };

  useEffect(() => {
    getPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, products, categories, min, max]);

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    else dealsOfTheDay();
    verifyCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (reloadMenu) {
      rangeProducts();

      handleProducts(route["*"], categories[0]?.id);
      // setProducts(menuProducts);
      setReloadMenu(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuProducts, categories]);

  useEffect(() => {
    setMax(localStorage["max-price"]);
  }, [products]);

  return {
    category,
    products,
    modal,
    setModal,
    allowedIndex,
    pages,
    currentPge,
    setCurrent,
    slice,
    setSlice,
    deals,
    max,
    setMax,
    min,
    setMin,
    rangeProducts,
    handleProducts,
    search,
    searchResults,
    vissible,
    handleSearch,
    closeSearch,
    dealsOfTheDay,
  };
};
