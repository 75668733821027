import React, { useEffect } from "react";
import { policy } from "../../constants/policy";
import Footer from "../components/footer/Footer";
import PolicyTemplate from "../components/policy/PolicyTemplate";
import { useNavbar } from "../hooks/useNavbar";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="max-w-[1440px] w-full m-auto max-mobile:px-[35px] px-[16px] ">
      {useNavbar()}
      <div className="max-w-[1288px] w-full m-auto py-[24px] flex flex-col gap-[40px]">
        <div className="flex flex-col w-full">
          <span className="text-center font-[500] mini-tablet:text-[48px] max-mobile:text-[32px] text-[24px] text-[#ffffff] mini-tablet:leading-[72px] max-mobile:leading-[48px] leading-[36px]">
            Eve After Dark Privacy Policy
          </span>
          <span className="text-center text-[#ffffff] text-[16px] leading-[24px] opacity-[0.65] font-[400]">
            Last modified: 12/23/2022
          </span>
        </div>
        <div className="flex flex-col gap-[24px] ">
          {policy.map((p, n) => (
            <PolicyTemplate
              title={p.title}
              subTitle={p.subTitle}
              key={n}
              data={p.paragraphs}
              list={p.list}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
