import React from "react";
import Accordion from "../ui/Accordion";

const Legal = () => {
  return (
    <div className="w-full">
      <span className="text-[32px] leading-[48px] font-[500] text-[#ffffff]">
        Legal
      </span>
      <div className="mt-[32px] flex flex-col gap-[32px]">
        <Accordion
          q={"Terms of use"}
          ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
        />
        <Accordion
          q={"Privacy Policy"}
          ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
        />
        <Accordion
          q={"Cancellations and Refunds"}
          ans={`
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        quos dolorum illo suscipit eaque deserunt? Veritatis natus, modi
        cupiditate incidunt, omnis, eum nihil deleniti illo alias adipisci
        repellendus. Quam quod amet natus soluta, accusamus vel alias fuga
        ratione aliquam, dolorum voluptatum tempora aliquid architecto
        error deserunt repellat dicta, delectus dolores?`}
        />
      </div>
    </div>
  );
};

export default Legal;
