import React, { useEffect } from "react";
import logo from "../../../../assets/images/logo.png";
import Footer from "../../footer/Footer";
import AuthNavbar from "../../navbar/mainLayouts/AuthNavbar";

const AuthLayout = ({ children, title, text }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="auth_background z-[99999999] flex flex-col items-center">
      <AuthNavbar />
      <div className="mobile:px-[35px] px-0 w-full flex justify-center">
        <div className="max-w-[653px] my-[48px] flex-col flex justify-center items-center w-full h-fit mobile:p-[40px] p-[32px] mobile:bg-[#ffffff] rounded-[32px]">
          <img
            src={logo}
            className="w-[120px] mb-[16px] h-[120px] rounded-full"
            alt=""
          />
          <span className="mini-tablet:text-[48px] max-w-[354px] mobile:text-[32px] text-[24px] text-center f0ont-[600] mini-tablet:leading-[72px] mobile:leading-[48px] leading-[36px] mobile:text-[#000000] text-[#ffffff] opacity-[0.85]">
            {title}
          </span>
          <span className="text-center mt-[8px] mini-tablet:text-[20px] mobile:text-[16px] text-[14px] mini-tablet:leading-[30px] mobile:leading-[24px] leading-[21px] mobile:text-[#000000] text-[#ffffff] opacity-[0.65] font-[400]">
            {text}
          </span>
          <div className="mt-[32px] w-full">{children}</div>
        </div>
      </div>
      <div className="w-full bg-[#171717] mini-tablet:hidden block">
        <Footer />
      </div>
    </div>
  );
};

export default AuthLayout;
