import React from "react";
import logo from "../../../assets/images/logo.png";
import Loader from "./Loader";

const LocationCard = ({ value, location, setLocation, loading }) => {
  const selectCard = (event) => {
    event.stopPropagation();
    if (!loading) {
      setLocation(value.id);
      localStorage.setItem("currentAddress", JSON.stringify(value));
      localStorage.setItem("initialSetup", true);
    }
  };
  return (
    <div
      onClick={selectCard}
      style={{ width: "100%" }}
      className={`mini-tablet:max-w-[408px] max-mobile:max-w-[508px] max-mobile:h-[296px] h-[144px] w-full ${
        location === value.id && " border-2 border-[#ffffff]"
      } cursor-pointer flex justify-center items-center max-mobile:gap-[32px] gap-[8px] flex-col  locationCard`}
    >
      <div className=" mini-tablet:w-[180px]  relative mini-tablet:h-[180px] max-mobile:w-[140px] w-[80px] max-mobile:h-[140px] h-[80px] flex items-center justify-center">
        <img className="w-full h-full" src={logo} alt="" />
        {/* )} */}
        <span
          className={`${
            loading && location === value.id
              ? "opacity-1 p-4 w-full h-full "
              : "opacity-0 w-full h-full "
          } absolute  cursor-pointer bg-[#ff00198e] text-[#ffffff] flex justify-center items-center justify-self-center transition-all ease-in-out duration-400 rounded-full `}
        >
          <Loader size={[30, 30]} color={"#fff"} />
        </span>
      </div>
      <span className="max-mobile:text-[24px] text-[16px] max-mobile:leading-[36px] leading-[24px] font-[500] text-[#ffffff]">
        {value.state}
      </span>
    </div>
  );
};

export default LocationCard;
