import React from "react";
import { BsChevronRight } from "react-icons/bs";
import location from "../../../assets/icons/l-location.svg";
import wallet from "../../../assets/icons/l-wallet__.svg";
import logout from "../../../assets/icons/logout.svg";
import chat from "../../../assets/icons/l-chat.svg";

const MobilNavigator = ({ active, setActive }) => {
  const user = localStorage.user ? JSON.parse(localStorage.user) : {};

  return (
    <div className="flex flex-col gap-[24px] ">
      <span
        onClick={() => setActive("Profile Settings")}
        className="transparent_bg4 cursor-pointer p-[24px] rounded-[24px] flex gap-[10px] items-center"
      >
        <img
          className="w-[45px] h-[45px] rounded-full"
          src={user?.avatar?.url}
          alt=""
        />
        <div className="flex w-full  items-center justify-between">
          <div className="flex flex-col">
            <span className="text-[18px] capitalize leading-[27px] font-[500] text-[#fff]">
              {user?.first_name} {user?.last_name}
            </span>
            <span className="text-[12px] leading-[18px] text-[rgba(255,255,255,0.5)]">
              {user?.phone}
            </span>
          </div>
          <BsChevronRight className="text-[#ffffff] text-[20px]" />
        </div>
      </span>
      <div className="transparent_bg4 rounded-[24px] flex flex-col items-center">
        <div
          onClick={() => setActive("My Address")}
          className="flex cursor-pointer w-full gap-[10px] border-b border-[rgba(255,255,255,0.1)] p-[24px] pb-[34px] items-center justify-between"
        >
          <img
            className="w-[20px] h-[20px] rounded-full"
            src={location}
            alt=""
          />
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#fff]">
              Location
            </span>
            <BsChevronRight className="text-[#ffffff] text-[20px]" />
          </div>
        </div>

        {/* <div
          onClick={() => setActive("Payment Methods")}
          className="flex w-full cursor-pointer gap-[10px] p-[24px] py-[34px] items-center justify-between"
        >
          <img className="w-[23px] h-[20px] rounded-full" src={wallet} alt="" />
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#fff]">
              Payment Methods
            </span>
            <BsChevronRight className="text-[#ffffff] text-[20px]" />
          </div>
        </div> */}

        {/* <div
          onClick={() => setActive("Live Support")}
          className="flex w-full p-[24px] pt-[34px] cursor-pointer border-t border-[rgba(255,255,255,0.1)] gap-[10px] items-center justify-between"
        >
          <img className="w-[20pxpx] h-[20px] rounded-full" src={chat} alt="" />
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#fff]">
              Live Support
            </span>
            <BsChevronRight className="text-[#ffffff] text-[20px]" />
          </div>
        </div> */}
      </div>
      <div className="transparent_bg4 p-[24px] cursor-pointer rounded-[24px] flex gap-[10px] items-center">
        <div className="flex w-full gap-[10px] rounded-[24px] items-center justify-between">
          <img className="w-[20px] h-[15px] rounded-full" src={logout} alt="" />
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#FF0018]">
              Sign Out
            </span>
            <BsChevronRight className="text-[#FF0018] text-[20px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilNavigator;
