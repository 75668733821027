import React from "react";
import { BiMinus } from "react-icons/bi";
import Loader from "../../ui/Loader";
import { BsPlus } from "react-icons/bs";
import { useProductItem } from "../hooks/useProductItem";

const Counter = () => {
  const { updateCart, loadingCount, quantity } = useProductItem();
  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="flex max-mobile:h-[66px] h-[52px] rounded-[100px] justify-bewtween border-[0.65px] max-mobile:px-[32px] px-[12px] border-[#ffffff80] sm:w-[189px] gap-[14px] items-center"
    >
      <span
        onClick={() => updateCart("minus")}
        className="w-[40px] cursor-pointer h-[40px] flex justify-center items-center transparent_bg2 rounded-full"
      >
        <BiMinus className="text-[#ffffff]" />
      </span>
      <span className="text-[#FFFFFF]">
        {loadingCount ? <Loader color={"#ffffff"} size={[20, 20]} /> : quantity}
      </span>
      <span
        onClick={() => updateCart("plus")}
        className="w-[40px] cursor-pointer h-[40px] flex justify-center items-center transparent_bg2 rounded-full"
      >
        <BsPlus className="text-[#ffffff]" />
      </span>
    </div>
  );
};

export default Counter;
