import React from "react";
import { useProductItem } from "../hooks/useProductItem";
import { BsFillHeartFill, BsHeart } from "react-icons/bs";

const FavouriteIcon = () => {
  const { favourite, dislikeItem, likeItem } = useProductItem();
  return (
    <span
      onClick={() => (favourite ? dislikeItem() : likeItem())}
      className="w-[44px] bg-[#ffffff0d] rounded-full cursor-pointer flex justify-center items-center h-[44px]"
    >
      {favourite ? (
        <BsFillHeartFill className="text-[#FF0018] text-[19px]" />
      ) : (
        <BsHeart className="text-[#ffffff] text-[19px]" />
      )}
    </span>
  );
};
export default FavouriteIcon;
