import React from "react";
import food from "../../../assets/images/food.png";
import freshIcon from "../../../assets/icons/FreshIcons.svg";
import deliveryIcon from "../../../assets/icons/DeliveryIcons.svg";
import liveIcon from "../../../assets/icons/LiveIcons.svg";

const Delivery = () => {
  return (
    <div className="w-full tablet:h-[1024px] sm-tablet:h-[657px] h-fit gap-[30px] items-center max-w-[1288px] max-mobile:px-[35px] px-[16px]  m-auto grid grid-cols-12">
      <div className="sm-tablet:col-span-5 col-span-full tablet:py-[123px] sm-tablet:py-[48px] py-[60px] h-full">
        <img src={food} className="w-full sm-tablet:max-h-[777px] max-h-[554px] h-full rounded-[60px]" alt="" />
      </div>
      <div className="sm-tablet:col-span-7 col-span-full tablet:py-[123px] sm-tablet:py-[48px] sm-tablet:pt-[48px] py-[0] pb-[60px]  ">
        <span className="tablet:leading-[72px] sm-tablet:text-left text-start  w-full max-mobile:leading-[48px] leading-[36px] font-[500] tablet:text-[48px] max-mobile:text-[32px] text-[24px]  text-[#FFFFFF]">
          Nothing brings people together like good food.
        </span>
        <div className="flex flex-col text-start w-full mt-[48px] tablet:gap-[82px] gap-[49px]">
          <div className="flex gap-[16px]">
            <span className="p-[16px] tablet:min-w-[68.4px] min-w-[40px] tablet:h-[68.4px] h-[40px] flex justify-center bg-[#ffffff29] items-center rounded-[24.3px]">
              <img src={freshIcon} className="w-[29.9px] h-[29.9px]" alt="" />
            </span>
            <div style={{maxWidth: "650px"}} className="flex flex-col">
              <span className="tablet:text-[32px] text-[24px] tablet:py-[10px] tablet:leading-[48px] leading-[32px] font-[500] text-[#ffffff] opacity-[0.85]">
                Freshly Made Meals
              </span>
              <span className="tablet:text-[24px] text-[16px] tablet:leading-[36px] leading-[24px] font-[400] text-[#ffffff] opacity-[0.65]">
                Get freshly made meals prepared with the best ingredients.
              </span>
            </div>
          </div>
          <div className="flex gap-[16px]">
            <span className="p-[16px] tablet:min-w-[68.4px] min-w-[40px] tablet:h-[68.4px] h-[40px] flex justify-center bg-[#ffffff29] items-center rounded-[24.3px]">
              <img
                src={deliveryIcon}
                // className="w-[29.9px] h-[29.9px]"
                alt=""
              />
            </span>
            <div style={{maxWidth: "650px"}} className="flex flex-col">
              <span className="tablet:text-[32px] text-[24px] tablet:py-[10px] tablet:leading-[48px] leading-[32px] font-[500] text-[#ffffff] opacity-[0.85]">
                24/7 Delivery
              </span>
              <span className="tablet:text-[24px] text-[16px] tablet:leading-[36px] leading-[24px] font-[400] text-[#ffffff] opacity-[0.65]">
                Know where your order is at all times, from the restaurant to
                your doorstep.
              </span>
            </div>
          </div>

          <div className="flex gap-[16px]">
            <span className="p-[16px] tablet:min-w-[68.4px] min-w-[40px] tablet:h-[68.4px] h-[40px] flex justify-center bg-[#ffffff29] items-center rounded-[24.3px]">
              <img src={liveIcon} className="w-[29.9px] h-[29.9px]" alt="" />
            </span>
            <div style={{maxWidth: "650px"}} className="flex flex-col">
              <span className="tablet:text-[32px] text-[24px] tablet:py-[10px] tablet:leading-[48px] leading-[32px] font-[500] text-[#ffffff] opacity-[0.85]">
                Live Order Tracking
              </span>
              <span className="tablet:text-[24px] text-[16px] tablet:leading-[36px] leading-[24px] font-[400] text-[#ffffff] opacity-[0.65]">
                Know where your order is at all times, from the restaurant to
                your doorstep.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
