import React from "react";
import { BiMenu } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";

const AuthNavbar = () => {
  // console.log(window.location.pathname);
  return (
    <div className="bg-[#171717] w-full mini-tablet:px-[75px] mobile:px-[35px] px-[19px] mobile:py-[24px] py-[16px] flex justify-between items-center ">
      <span className="mini-tablet:col-span-2 col-span-6 mr-4 flex items-center ">
        <BiMenu className="text-[20px] mini-tablet:hidden block text-[#ffffff] cursor-pointer mr-[27px]" />
        <Link to="/">
          <img
            src={logo}
            className="mobile:w-[80px] mobile:h-[80px] w-[70px] h-[70px] "
            alt=""
          />
        </Link>
      </span>
      <div className="flex">
        {window.location.pathname === "/signup" && (
          <Link
            to={"/login"}
            className="px-[32px] decoration-inherit no-underline mini-tablet:block hidden whitespace-nowrap py-[18px] leading-[27px] text-[18px] font-[400px] text-[#a3a3a3] cursor-pointer"
          >
            Log In
          </Link>
        )}
        {window.location.pathname === "/login" && (
          <Link
            to={"/signup"}
            className="bg-[#FF0018] mini-tablet:block hidden decoration-inherit no-underline mobile:text-[18px] text-[14px] whitespace-nowrap cursor-pointer text-[#ffffff] rounded-[100px] red_btn_shadow mobile:px-[32px] px-[18px]  mobile:py-[18px] py-[12px] "
          >
            Sign Up
          </Link>
        )}
        <span className="min-w-[44px] h-[44px] mr-[24px] mini-tablet:hidden flex cursor-pointer justify-center items-center rounded-full bg-[rgba(255,255,255,0.2)]">
          <BsPerson className="text-[#FFFFFF] text-[16.69px]" />
        </span>
      </div>
    </div>
  );
};

export default AuthNavbar;
