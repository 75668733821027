import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Catalogue from "../components/home/Catalogue";
import Delivery from "../components/home/Delivery";
import Comments from "../components/home/reviews/Reviews";
import Header from "../components/home/Header";
import MobilePreview from "../components/general/MobilePreview";
import { useNavigate } from "react-router-dom";
import AppLayout from "../components/ui/layouts/AppLayout";

const Home = () => {
  const navigate = useNavigate();
  const path = window.location.href;
  const ref = path.slice(path.indexOf("?") + 8, path.length);

  useEffect(() => {
    if (path.includes("trxref")) {
      navigate(`/payment-verification/${ref}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <AppLayout>
      <div className="w-full m-auto">
        <Header />
        <Catalogue />
        <Delivery />
        <MobilePreview />
        <Comments />
        <Footer />
      </div>
    </AppLayout>
  );
};

export default Home;
