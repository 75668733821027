import React, { useState } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import Input from "../ui/input/Input";
// import avatar from "../../assets/images/avatar2.png";
import camera from "../../../assets/icons/l-camera.png";
import { appService } from "../../../middle-ware/Services/appService";
import { toast } from "react-hot-toast";
import Loader from "../ui/Loader";
import { useNavigate } from "react-router-dom";
import PhoneInput from "../ui/input/PhoneInput";

const Settings = () => {
  const service = new appService();
  const navigate = useNavigate();

  const user = localStorage.user
    ? JSON.parse(localStorage.user)
    : {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        avatar: "",
      };
  const [form, setForm] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone.slice(4),
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [emails, setEmails] = useState(user?.receive_email);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};
  const country_code = location?.state === "London" ? "+44" : "+234";
  const [phoneType, setPhonetype] = useState(country_code);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkPhone = (value) => {
    const arr = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
    if (arr.includes(value[value.length - 1])) return true;
    else return false;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const phoneChecker = checkPhone(value);
    if (name === "phone") {
      if (phoneChecker || form.phone.length === 1)
        setForm({ ...form, [name]: value });
    } else setForm({ ...form, [name]: value });
    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, [name]: "Email is not valid*" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const formChanged = () => {
    if (
      form.first_name !== user?.first_name ||
      form.last_name !== user?.last_name ||
      form.email !== user?.email ||
      (phoneType === "+234"
        ? form.phone !== user?.phone.slice(4)
        : form.phone !== user?.phone.slice(3)) ||
      emails !== user?.receive_email ||
      phoneType !== country_code
    ) {
      return true;
    } else return false;
  };
  const validateForm = () => {
    const newErrors = {};
    const { first_name, last_name, email, phone } = form;
    if (first_name === "" || first_name.length < 3)
      newErrors.first_name = "Required*";
    if (last_name === "" || last_name.length < 3)
      newErrors.last_name = "Required*";
    if (email === "") newErrors.email = "Required*";
    else if (!validateEmail(email))
      newErrors.email = "Enter a valid email address*";
    if (phone === "") newErrors.phone = "Required*";
    else if (phone.length < 10 && phoneType === "+234")
      newErrors.phone = "Enter a valid phone number*";
    return newErrors;
  };
  const handleSubmit = async () => {
    const formError = validateForm();
    if (Object.keys(formError).length > 0) {
      setErrors({ ...errors, ...formError });
    } else {
      setLoading(true);
      const res = await service.updateProfile({
        first_name: form.first_name,
        last_name: form.last_name,
        phone_number: phoneType + form.phone,
        receive_email: emails,
        email: form.email,
      });
      if (res.message === "success") {
        toast.success("Profile updated");
      } else toast.error("Failed to update profile");
      setLoading(false);
    }
  };
  const deleteAccount = async () => {
    setDeleting(true);
    const res = await service.deleteAccount();
    if (res.message === "success") {
      localStorage.clear();
      setDeleteModal(false);
      navigate("/user-location");
    } else {
      toast.error("Failed to delete account");
    }
    setDeleting(false);
  };
  return (
    <form className="flex-col  flex gap-[32px] w-full" action="">
      <div className="flex items-center sm-tablet:justify-start justify-center gap-[24px]">
        <div className="relative mini-tablet:w-[100px] w-[80px] mini-tablet:h-[100px] h-[80px] rounded-full">
          <img
            src={user?.avatar?.url}
            className="mini-tablet:w-[100px] w-[80px] mini-tablet:h-[100px] h-[80px] rounded-full"
            alt=""
          />
          <div className="absolute bottom-0 rounded-full cursor-pointer w-[31px] right-0 h-[31px] flex justify-center items-center bg-[#FF0018]">
            <img src={camera} alt="" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="mini-tablet:text-[32px] capitalize text-[24px] mini-tablet:leading-[48px] leading-[36px] font-[500] text-[#ffffff] sm-tablet:block hidden">
            {user?.first_name} {user?.last_name}
          </span>
          <span className="text-[18px] leading-[27px] font-[400] text-[#ffffff] opacity-[0.65] sm-tablet:block hidden">
            +234 {user.phone.slice(4)}
          </span>
        </div>
      </div>
      <Input
        value={form.first_name}
        type={"text"}
        onChange={handleChange}
        name="first_name"
        error={errors.first_name}
        label={"first Name"}
        light
      />
      <Input
        value={form.last_name}
        type={"text"}
        onChange={handleChange}
        name="last_name"
        error={errors.last_name}
        label={"last Name"}
        light
      />

      <div className="flex flex-col gap-[20px]">
        <Input
          value={form.email}
          type={"email"}
          onChange={handleChange}
          name="email"
          error={errors.email}
          label={"Email"}
          light
        />
        <span
          onClick={() => setEmails(!emails)}
          className="flex gap-[16px] text-[#8F8F8F] transition-all ease-in-out duration-500 text-[16px] leading-[24px] items-center"
        >
          {emails ? (
            <BsCheckSquare className=" rounded-[4px] text-[#34C759] text-[20px]  transition-all ease-in-out duration-500" />
          ) : (
            <BsSquare className=" rounded-[4px] text-[#8F8F8F] text-[20px]  transition-all ease-in-out duration-500" />
          )}
          I would like to receive emails
        </span>
      </div>

      <PhoneInput
        value={form.phone}
        type={"text"}
        onChange={handleChange}
        name="phone"
        error={errors.phone}
        label={"Phone"}
        light={true}
        code={phoneType}
        setPhonetype={setPhonetype}
      />

      <span
        onClick={() => formChanged() && handleSubmit()}
        className={`h-[66px] cursor-pointer font-[500] w-full flex justify-center items-center ${
          formChanged() ? "bg-[#FF0018]" : "bg-[#A3A3A3]"
        } text-[#ffffff] rounded-[100px] text-[20px] leading-[30px]`}
      >
        {loading ? <Loader size={[30, 30]} color={"#fff"} /> : "Save Changes"}
      </span>
      <span
        onClick={() => setDeleteModal(true)}
        className="h-[66px] cursor-pointer font-[500] w-full flex justify-center items-center bg-[#FFE5E8] hover:bg-[#FF0018] text-[#FF0018] hover:text-[#ffffff] rounded-[100px] text-[20px] leading-[30px]"
      >
        Delete Account
      </span>
      {deleteModal && (
        <div className="fixed w-full top-0 left-0 p-[30px] h-screen bg-[rgba(0,0,0,0.6)] flex justify-center items-center">
          <div className="mini-tablet:max-w-[544px] max-mobile:max-w-[470px] max-w-[38px] bg-[#151515] rounded-[32px] w-fit p-[32px] flex flex-col  ">
            <span className="text-center mini-tablet:text-[32px] max-mobile:text-[24px] mini-tablet:leading-[48px] max-mobile:leading-[36px] text-[18px] leading-[27px] font-[600] text-[#ffffff] mb-[8px]">
              Are you sure you want to delete your account?
            </span>
            <span className="text-center max-mobile:text-[18px] text-[14px] max-mobile:leading-[27px] leading-[21px] font-[400] text-[#ffffff] max-mobile:mb-[40px] mb-[32px] opacity-[0.65]">
              Deleting your account will erase all your data
            </span>
            <span
              onClick={deleteAccount}
              className="bg-[#FF0018] max-mobile:mb-[24px] mb-[16px] w-full max-mobile:h-[63px] h-[57px] cursor-pointer flex justify-center items-center text-[#ffffff] rounded-[100px] mini-tablet:text-[20px] max-mobile:text-[18px] text-[14px] mini-tablet:leading-[30px] max-mobile:leading-[27px] leading-[21px]"
            >
              {deleting ? (
                <Loader size={[30, 30]} color={"#ffffff"} />
              ) : (
                "Delete"
              )}
            </span>
            <span
              onClick={() => setDeleteModal(false)}
              className=" border border-[#FF0018] w-full max-mobile:h-[63px] h-[57px] cursor-pointer flex justify-center items-center text-[#FF0018] hover:text-[#ffffff] hover:bg-[#FF0018] transition-all ease-in-out duration-300 rounded-[100px]  mini-tablet:text-[20px] max-mobile:text-[18px] text-[14px] mini-tablet:leading-[30px] max-mobile:leading-[27px] leading-[21px]"
            >
              Cancel
            </span>
          </div>
        </div>
      )}
    </form>
  );
};

export default Settings;
