import React, { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { FaEllipsisH } from "react-icons/fa";
import { RiEdit2Fill, RiCloseFill } from "react-icons/ri";
import polygon from "../../../assets/vectors/polygon.png";
import { appService } from "../../../middle-ware/Services/appService";
import Select from "../ui/input/Select";
import Input from "../ui/input/Input";
// import { useNavigate } from "react-router-dom";
// import client from "../../hooks/axiosInstance";
import Loader from "../../components/ui/Loader";
import Geocode from "react-geocode";
import ex from "../../../assets/icons/ex.svg";

const AddressCard = ({
  address,
  setAsLocation,
  addresses,
  reload,
  countries,
}) => {
  const service = new appService();
  const toggleRef = useRef();

  const target = addresses?.filter((a) => a.id === address.id);
  const current = JSON.parse(localStorage.currentAddress);

  const [toggle, setToggle] = useState(false);

  const [isCurrent, setIsCurrent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [edit, setEdit] = useState(false);

  const [states, setStates] = useState([]);
  const [areas, setAreas] = useState([]);

  const [country, setCountry] = useState({
    id: target[0]?.location_id,
    name: target[0]?.country,
    currency: "",
  });
  const [state, setState] = useState({
    id: target[0]?.available_region_id,
    name: target[0]?.state,
  });
  const [area, setArea] = useState({
    id: target[0]?.delivery_zone_id,
    name: target[0]?.area,
  });
  const [address_, setAddress] = useState({
    house_number: target[0]?.house_number,
    street: target[0]?.street,
  });
  const [errors, setErrors] = useState({
    house_number: "",
    street: "",
    country: "",
    state: "",
    area: "",
  });

  const checkCurrentLocation = () => {
    if (current.id === address.id) setIsCurrent(true);
    else setIsCurrent(false);
  };

  const deleteAddress = async () => {
    // setLoading(true);
    const res = await service.deletAddress(address?.id);
    if (res.message === "success") {
      reload();
      setToggle(false);
    }
    // setLoading(false);
  };

  const getStates = async (id) => {
    setLoadingStates(true);
    const res = await service.getStates(id);
    if (res.message === "success") {
      setStates(res.data);
    }
    setLoadingStates(false);
  };
  const getAreas = async (id) => {
    setLoadingAreas(true);
    const res = await service.getAreas(country.id, id);
    if (res.message === "success") {
      setAreas(res.data);
    }
    setLoadingAreas(false);
  };
  const handleAddress = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "street" && value.length < 3)
      setErrors({ ...errors, [name]: "should be a mininmum of 3 characters" });
    else setErrors({ ...errors, [name]: "" });
  };
  const handleChange = (name, data) => {
    if (name === "country") {
      setState({ id: "", name: "" });
      setCountry({
        id: data?.id,
        name: data?.country_data?.data?.translations?.en,
        currency: data?.currency,
      });
      setErrors({ ...errors, country: "", state: "", area: "" });
      getStates(data?.id);
    } else if (name === "state") {
      setArea({ id: "", name: "" });
      setState({
        id: data?.id,
        name: data.state,
      });
      setErrors({ ...errors, state: "", area: "" });
      getAreas(data?.id);
    } else if (name === "area") {
      setArea({
        id: data?.id,
        name: data.name,
      });
      setErrors({ ...errors, area: "", house_number: "", street: "" });
    }
  };
  const validateForm = () => {
    const error = {};
    if (address.house_number === "") error.house_number = "Required*";
    if (address.street === "") error.street = "Required*";
    else if (address?.street?.length < 3)
      error.street = "should be a mininmum of 3 characters";
    if (country.id === "") error.country = "Required*";
    if (state.id === "") error.state = "Required*";
    if (area.id === "") error.area = "Required*";
    return error;
  };
  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      const location = await Geocode.fromAddress(
        `${address.house_number}, ${address.street} ${area.name} ${state.name} ${country.name}`
      );
      const { lat, lng } = location?.results[0]?.geometry.location;
      // console.log(target);
      const res = await service.updateAddress(
        {
          address: {
            house_number: address.house_number,
            street: address.street,
            delivery_zone_id: area.id,
            available_region_id: state.id,
            location_id: country.id,
            longitude: lng,
            latitude: lat,
          },
        },
        target[0]?.id
      );
      if (res.message === "success") {
        localStorage.setItem("currentAddress", JSON.stringify(res.data));
        reload();
        setEdit(false);
      } else toast.error("Something went wrong, Please try again.");
      setLoading(false);
    }
  };

  window.addEventListener("click", (e) => {
    if (toggle && !toggleRef.current?.contains(e.target)) setToggle(false);
  });
  useEffect(() => {
    checkCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrent, current]);
  return (
    <div
      className={`w-full relative ${
        isCurrent
          ? " bg-[#FF0018] text-[#ffffff] "
          : "transparent_bg3 text-[#ffffff]"
      } rounded-[16px] cursor-pointer min-h-[63px] p-[16px] flex justify-between items-center`}
    >
      <span
        onClick={() => {
          setAsLocation(address.id);
          checkCurrentLocation();
        }}
        className="tablet:text-[18px] max-w-[85%] text-[16px] w-full leading-[27px] font-[400]  opacity-[0.65] "
      >
        {address.name}
      </span>
      <div ref={toggleRef}>
        <FaEllipsisH
          onClick={() => setToggle(!toggle)}
          className="text-[20px] text-[#ffffff] cursor-pointer"
        />
        {toggle && (
          <Fragment>
            <img
              src={polygon}
              className="absolute transition-transform   ease-in-out duration-300 top-[37px] right-0 w-[40px] h-[40px] z-30"
              alt=""
            />
            <div className="flex flex-col gap-[24px] items-center justify-center top-[52px] bg-[#151515] rounded-[16px] w-[114px] min-h-[114px] right-0 absolute z-[99999999] transition-transform ease-in-out duration-300">
              <span
                onClick={() => {
                  setEdit(true);
                  setToggle(false);
                  getAreas(state?.id);
                  getStates(country?.id);
                }}
                className="flex cursor-pointer items-center gap-[6px] text-[#00BA80] text-[14px] leading-[21px] font-[600]"
              >
                Edit <RiEdit2Fill />
              </span>
              <span
                onClick={() =>
                  isCurrent
                    ? toast.error("Can not delete your current address")
                    : deleteAddress()
                }
                className="flex cursor-pointer items-center gap-[6px] text-[#AD0010] text-[14px] leading-[21px] font-[600]"
              >
                Delete <RiCloseFill />
              </span>
            </div>
          </Fragment>
        )}
      </div>

      {edit && (
        <div className="fixed h-screen z-[9999] w-full  top-0 left-0 right-0 min-h-screen flex justify-center overscroll-y-auto bg-[rgba(0,0,0,0.6)]">
          <div className="min-h-screen h-full py-[100px] px-[30px] newscroll w-full flex overflow-auto ">
            <div className="max-w-[574px] relative m-auto flex flex-col items-center w-full h-fit rounded-[24px] max-mobile:py-[32.5px] py-[17px]  max-mobile:px-[40px] px-[16px] bg-[#151515]">
              <span className="w-full">
                <img
                  onClick={() => setEdit(false)}
                  className=" left-[20px]"
                  src={ex}
                  alt=""
                />
              </span>
              <span className="text-center mobile:text-[32px] text-[24px] mobile:leading-[48px] leading-[30px] font-[600] text-[#ffffff]">
                Edit Address
              </span>
              <div
                className={`flex-col flex max-mobile:gap-[32px] gap-[24px] w-full`}
              >
                <Select
                  list={countries}
                  holder={"Select Country"}
                  onChange={handleChange}
                  loading={false}
                  name={"country"}
                  value={country.name}
                  error={errors.country}
                  light={true}
                />
                {country.id !== "" && (
                  <Select
                    list={states}
                    holder={"Select State"}
                    onChange={handleChange}
                    loading={loadingStates}
                    name={"state"}
                    value={state.name}
                    error={errors.state}
                    light
                  />
                )}
                {state.id !== "" && (
                  <Select
                    list={areas}
                    holder={"Select Area"}
                    onChange={handleChange}
                    loading={loadingAreas}
                    name={"area"}
                    value={area.name}
                    error={errors.area}
                    light
                  />
                )}
                {area.id !== "" && (
                  <Fragment>
                    <Input
                      value={address_.house_number}
                      type={"text"}
                      onChange={handleAddress}
                      name="house_number"
                      error={errors.house_number}
                      label={"House Number"}
                      light
                    />
                    <Input
                      value={address_.street}
                      type={"text"}
                      onChange={handleAddress}
                      name="street"
                      error={errors.street}
                      label={"Street"}
                      light
                    />
                    <span
                      onClick={() => handleSubmit()}
                      className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
                    >
                      {loading ? (
                        <Loader size={[40, 40]} color="#ffffff" />
                      ) : (
                        "Edit Address"
                      )}
                    </span>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressCard;
