import React, { useEffect } from "react";
import { useFetchData } from "./views/hooks/useFetchData";
import { useAuthContext } from "./middle-ware/StateContext/AuthContext";
import PageLoader from "./views/components/ui/PageLoader";
import AppRoutes from "./router/Routes";
import "./assets/slick-style/slick_.css";
import "./assets/slick-style/slick-theme_.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-loading-skeleton/dist/skeleton.css";
import { useFlowContext } from "./middle-ware/StateContext/FlowContext";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

function App() {
  const auth = useAuthContext();
  const { reloadMenu } = useFlowContext();
  const { loading } = useFetchData();

  const pixelOptions = {
    autoConfig: true, // set to false if you want to manually call `sendPageView` instead
    debug: false, // enable logs for debugging
  };
  ReactPixel.init("383892961005299", null, pixelOptions);
  ReactPixel.pageView();
  ReactGA.initialize(
    [
      {
        trackingId: "G-2XD3EK4C4D",
        gaOptions: {
          name: "initial-tracker",
        },
      },
      {
        trackingId: "G-R0ZCYNQCTN",
        gaOptions: { name: "AWADIGITALtracker" },
      },
      {
        trackingId: "AW-854169182",
        gaOptions: { name: "EADBurgerTracker" },
      },
    ],
    { debug: true, alwaysSendToDefaultTracker: false }
  );

  useEffect(() => {
    if (localStorage.token) auth.setIsLoggedIn(true);
    else auth.setIsLoggedIn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const setLocation = (latitude, longitude) => {
      localStorage.setItem(
        "geolocation",
        JSON.stringify({
          latitude: latitude,
          longitude: longitude,
        })
      );
    };

    const setUserIP = (ip) => {
      let userIP = localStorage.getItem("userIP");
      if (userIP === undefined || userIP === null || userIP === "") {
        localStorage.setItem("userIP", ip);
      }
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let geolocation = JSON.parse(localStorage.getItem("geolocation"));
          if (geolocation === null) {
            setLocation(position.coords.latitude, position.coords.longitude);
          } else if (
            geolocation !== undefined &&
            geolocation.latitude !== position.coords.latitude
          ) {
            console.log("Location changed");
            setLocation(position.coords.latitude, position.coords.longitude);
          } else {
            console.log("Location already exists");
          }
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
          // alert(
          //   "Unlock the full potential of your visit to Eve After Dark by enabling location services! By allowing us to access your location, you can enjoy a more personalized and enriched experience. From tailored event recommendations to easier navigation within our venue, turning on your location helps us better cater to your preferences and needs. Experience Eve After Dark like never before - simply enable location services and let the magic begin!"
          // );
        }
      );
    } else {
      console.log("Turn on location for better user experience");
    }

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setUserIP(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  return (
    <div className="bg-[#171717] min-h-screen w-full">
      <div className="relative w-full m-auto min-h-screen overflow-hidden">
        {/* {loading} */}
        {loading ? <PageLoader /> : <AppRoutes />}
      </div>
    </div>
  );
}

export default App;
