import React from "react";
import { BsArrowLeftShort, BsArrowRightShort, BsPlus } from "react-icons/bs";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import ProductCard from "../ui/ProductCard";
import { Link } from "react-router-dom";
import { useDishes } from "./hooks/useDishes";

const Dishes = () => {
  const {
    backwardRef,
    forwardRef,
    start,
    setStart,
    end,
    setEnd,
    popularDishes,
    loadingProducts,
  } = useDishes();

  return (
    <div className="max-w-[1288px] tablet:pb-[48px] w-full m-auto max-mobile:px-[35px] px-[16px] ">
      <div className="flex w-full mobile:justify-between justify-center">
        <span className="text-[#ffffff] mobile:text-left w-full text-center opacity-[0.65] mobile:text-[32px] text-[24px] mobile:leading-[48px] leading-[36px] font-[500]">
          Popular Dishes
        </span>
        <div className="mobile:flex hidden gap-[16px]">
          <button
            onClick={() => backwardRef.current.click()}
            className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer mini-tablet:w-[56px] w-[50px] mini-tablet:h-[56px] h-[50px] ${
              !start && "bg-[#ff0018]"
            } flex justify-center items-center rounded-full`}
          >
            <BsArrowLeftShort className="text-[21px] text-[#ffffff]" />
          </button>
          <button
            onClick={() => forwardRef.current.click()}
            className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer mini-tablet:w-[56px] w-[50px] mini-tablet:h-[56px] h-[50px] ${
              !end && "bg-[#ff0018]"
            } flex justify-center items-center rounded-full`}
          >
            <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
          </button>
        </div>
      </div>
      {loadingProducts ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className=" w-full  mt-[28px] mb-[24px]">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={29}
              slidesPerView={3}
              breakpoints={{
                0: {
                  width: 308,
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                550: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                964: {
                  slidesPerView: 3,
                },
              }}
              onSwiper={(swiper) => {
                setStart(swiper.isBeginning);
                setEnd(swiper.isEnd);
              }}
              onSlideChange={(swiper) => {
                setStart(swiper.isBeginning);
                setEnd(swiper.isEnd);
              }}
            >
              {popularDishes?.map((p, n) => (
                <SwiperSlide key={n}>
                  <ProductCard product={p} />
                </SwiperSlide>
              ))}
              <SwiperButtonNext>
                <span className="hidden absolute" ref={forwardRef}>
                  <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
                </span>
              </SwiperButtonNext>
              <SwiperButtonPrev>
                <span className="hidden absolute" ref={backwardRef}>
                  <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
                </span>
              </SwiperButtonPrev>
            </Swiper>
          </div>
          <Link
            to="/menu"
            className="p-[16px] rounded-[16px] backdrop-blur-[20px] transparent_btn light-shadow flex justify-center gap-[16px] w-full items-center"
          >
            <span className="text-[18px] leading-[27px] font-[400] text-[#ffffff]">
              See More
            </span>
            <span className=" border-l border-[#ffffff1a] h-[30px] w-[1px]"></span>
            <span className="w-[24px] flex justify-center items-center h-[24px] rounded-full bg-[#FFFFFF]">
              <BsPlus className="text-[#8F8F8F]" />
            </span>
          </Link>
        </>
      )}
    </div>
  );
};

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slideNext()}>{children}</span>;
};
const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slidePrev()}>{children}</span>;
};

export default Dishes;
