import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FloatingCart from "../../navbar/utils/FloatingCart";
import { useNavbar } from "../../navbar/hooks/useNav";
import {ForFriendFloater} from "../../navbar/utils/ForFriendFloater";

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const { numOfItems } = useNavbar();

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    document.title = document.title + "- Nigeria's first internet kitchen";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="">
      {children}
      <div className="fixed bottom-[50px] z-[999999999] flex flex-col gap-3 mobile:right-[50px] right-[25px] w-[50px] h-fit">
        {numOfItems > 0 && <FloatingCart />}
        <ForFriendFloater />
      </div>
    </div>
  );
};

export default AppLayout;
