import React, { Fragment, useEffect } from "react";
import RecipientCard from "./RecipientCard";
import Map1 from "../maps/Map1";
import OrderWaitCard from "./OrderWaitCard";
import OrderChat from "./OrderChat";
// import target from "../../../assets/icons/target.svg";
import { useState } from "react";
import RecipientDropDown from "./RecipentDropDown";
import { appService } from "../../../middle-ware/Services/appService";
import { useParams } from "react-router-dom";
import OrderDirections from "./OrderDirections";
import PendingOrderCard from "./pendingOrderCard";
import Map2 from "../maps/Map2";

const OrderTracking = () => {
  const [chat, setChat] = useState(false);
  const service = new appService();
  const [order, setOrder] = useState({});
  const params = useParams();

  const getOrder = async () => {
    const res = await service.getOrder(params.id);
    // console.log(res);
    if (res.message === "success") {
      setOrder(res.data);
    }
  };
  useEffect(() => {
    setInterval(() => {
      getOrder();
    }, 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="relative w-full h-[724px]">
      {order.status === "delivering" ? <Map2 order={order} /> : <Map1 />}
      <div className="absolute w-full flex-wrap top-0 flex justify-between items-start max-mobile:px-[32px] px-[16px] max-mobile:py-[24px] py-[16px] gap-[10px]">
        {/* {order.status === "delivering" && (
          <span className="flex flex-nowrap whitespace-nowrap items-center bg-[#FF0018] gap-[9px] rounded-[100px] max-mobile:p-[14px] p-[8px] text-[12px] text-[#ffffff] leading-[18px]">
            <img
              className="max-mobile:w-[18px] w-[15px] h-fit"
              src={target}
              alt=""
            />
            Track Delivery
          </span>
        )} */}
        {order.status === "delivering" && (
          <div className="sm-tablet:hidden block ">
            <RecipientDropDown />
          </div>
        )}
      </div>
      {order.status === "delivering" ? (
        <div className="absolute flex gap-[24px] justify-between items-end max-mobile:px-[32px] px-[16px] max-mobile:py-[24px] py-[16px] w-full left-0 bottom-0 right-0">
          {!chat ? (
            <Fragment>
              <OrderWaitCard message={() => setChat(true)} />
              <div className="sm-tablet:block hidden">
                <RecipientCard info={order} processed={false} />
              </div>
            </Fragment>
          ) : (
            <OrderChat
              order={order}
              close={() => setChat(false)}
              slide={chat}
            />
          )}
        </div>
      ) : (
        <div className="absolute flex gap-[24px] sm-tablet:flex-row flex-col justify-between sm-tablet:items-end max-mobile:px-[32px] px-[16px] max-mobile:py-[24px] py-[16px] w-full left-0 bottom-0 right-0">
          <PendingOrderCard order={order} />
          <OrderDirections order={order} />
        </div>
      )}
    </div>
  );
};

export default OrderTracking;
