import React, { Fragment, useEffect } from "react";
import LayoutTwo from "../components/ui/layouts/LayoutTwo";
import OrderHistory from "../components/order/OrderHistory";
// import CurrentOrder from "../components/cart/CurrentOrder";
import { useNavigate } from "react-router-dom";
import OrderCart from "../components/cart/OrderCart";
// import { useParams } from "react-router-dom";

const History = () => {
  // const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    // else if(!localStorage.currentAddress) navigate("/address");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LayoutTwo
      title={"Order"}
      Path={
        <Fragment>
          <span>/</span>
          <span>History</span>
        </Fragment>
      }
      LeftComponent={
        <Fragment>
          <OrderHistory />
          <div className=" tablet:hidden mt-[32px] block tablet:pb-0 pb-[32px] border-b border-[#ffffff29] ">
            <OrderCart title={"Order details"} processed={true} />
          </div>
        </Fragment>
      }
      RightComponent={<OrderCart title={"Order details"} processed={true} />}
    />
  );
};

export default History;
