import { BsHeart, BsDot, BsFillHeartFill } from "react-icons/bs";
import { useProductItem } from "./hooks/useProductItem";
import clock from "../../../assets/icons/l-clock.png";
import send from "../../../assets/icons/l-send.svg";
import FavouriteIcon from "./utils/FavouriteIcon";
import ShareModal from "../modals/ShareModal";
import { useClipper } from "../../hooks/useClipper";
import Btn from "./utils/Btn";
import Counter from "./utils/Counter";
import Images from "../ui/Images";
import Skeleton from "react-loading-skeleton";
import search from "../../../assets/icons/l-search.svg";
import cancel from "../../../assets/icons/close-l.svg";
import { useEffect, useState } from "react";

const ProductContent = () => {
  const {
    share,
    favourite,
    item,
    quantity,
    read,
    toPrice,
    likeItem,
    dislikeItem,
    setShare,
    setRead,
    cart,
    loading,
    updateCart,
    addToCart,
  } = useProductItem();

  const keys = item.allergen_information
    ? Object.keys(item.allergen_information)
    : [];
  const values = item.allergen_information
    ? Object.values(item.allergen_information)
    : [];

  const [allergens, setAllergens] = useState([]);
  const [searchAl_, setSearchAl] = useState("");
  const getAllergens = () => {
    const arr = [];
    for (let i = 0; i < keys.length; i++) {
      if (values[i] === true) arr.push([keys[i]]);
    }
    setAllergens(arr);
  };
  const getAllergens_ = () => {
    const arr = [];
    for (let i = 0; i < keys.length; i++) {
      if (values[i] === true) arr.push([keys[i]]);
    }
    return arr;
  };
  const filter = (text) => {
    if (text === "") getAllergens();
    else {
      const arr = [];
      [...getAllergens_()].map((a) => {
        if (a[0].includes(text)) {
          arr.push(a[0]);
        }
      });
      setAllergens([...arr]);
    }
  };
  useEffect(() => {
    setSearchAl("");
    getAllergens();
  }, []);
  return (
    <div className="flex flex-col gap-[24px] pb-[35px] w-full">
      <div className="relative w-full h-fit">
        <Images
          classes={"w-full max-mobile:h-[374px] h-[274px] rounded-[24px] "}
          url={item?.image?.url}
          width={"100%"}
          height={"374px"}
          radius={"24px"}
        />
        <div className="max-mobile:hidden z-[1111111] absolute right-[16px] top-[16px]  flex gap-[16px]">
          {localStorage.token && (
            <span
              onClick={() => (favourite ? dislikeItem() : likeItem())}
              className="w-[44px] bg-[#ffffff0d] rounded-full cursor-pointer flex justify-center items-center h-[44px]"
            >
              {favourite ? (
                <BsFillHeartFill className="text-[#FF0018]" />
              ) : (
                <BsHeart className="text-[#ffffff]" />
              )}
            </span>
          )}
          <span
            onClick={() => setShare(true)}
            className="w-[44px] bg-[#ffffff0d] rounded-full cursor-pointer flex justify-center items-center h-[44px]"
          >
            <img className="w-[18px]" src={send} alt="" />
          </span>
        </div>
      </div>
      <div className="flex gap-[8px] flex-col">
        <div className="flex justify-between items-center">
          <span className="max-mobile:text-[32px] flex-wrap max-mobile:max-w-[70%] max-w-[90%] text-[18px] opacity-[0.85] mobile:leading-[48px] leading-[27px] font-[500] text-[#ffffff]">
            {item?.title}
          </span>
          <div className="max-mobile:flex hidden gap-[16px]">
            {localStorage.token && <FavouriteIcon />}
            <span
              onClick={() => setShare(true)}
              className="w-[44px] bg-[#ffffff0d] rounded-full cursor-pointer flex justify-center items-center h-[44px]"
            >
              <img className="w-[20px]" src={send} alt="" />
            </span>
          </div>
          <span className="max-mobile:hidden whitespace-nowrap flex light-shadow2 rounded-[100px] transparent_bg3 px-[8px] gap-[9.67px] items-center py-[8px]">
            <img src={clock} className="w-[11.6px] h-[11.6px]" alt="" />
            {/* <span className="text-[10px] leading-[15px] font-[400] text-[#ffffffd9]">
              30 - 45min
            </span> */}
          </span>
        </div>
        <div className="flex items-center gap-[8px]">
          <span className="max-mobile:flex hidden light-shadow2 rounded-[100px] transparent_bg3 px-[12.67px] gap-[9.67px] items-center py-[11.67px]">
            <img src={clock} alt="" />
            <span className="text-[14px] leading-[20px] font-[400] text-[#ffffffd9]">
              30 - 60min
            </span>
          </span>
          {/* <BsDot className="text-[#ffffff] max-mobile:block hidden opacity-[0.65]" />
          <span className="max-mobile:leading-[30px] leading-[21px] max-mobile:text-[20px] text-[14px] flex gap-[5.5px] items-center text-[#ffffff] opacity-[0.65] font-[400]">
            4.8 <BsStarFill className="text-[14px] text-[#ffa503]" />{" "}
          </span>
          <span className="text-[#ffffff] max-mobile:leading-[27px] leading-[18px] font-[400] max-mobile:text-[18px] text-[12px] opacity-[0.65] ">
            (245 Reviews)
          </span> */}
          <BsDot className="text-[#ffffff] mobile:block hidden opacity-[0.65]" />
          <span className="text-[#ffffff] opacity-[0.85] max-mobile:leading-[30px] leading-[21px] font-[500] max-mobile:text-[20px] text-[14px] ">
            {!item?.product_price ? (
              <Skeleton count={1} width={100} height={47} borderRadius={100} />
            ) : (
              toPrice(item?.product_price?.currency, item?.product_price?.price)
            )}
          </span>
        </div>
      </div>
      <div className="flex">
        <span className="max-mobile:text-[18px] text-[14px] text-[#ffffffa6] max-mobile:leading-[27px] leading-[21px] font-[300]">
          {useClipper(
            item?.description,
            read ? item?.description?.length : 150
          )}
          {item?.description?.length > 150 && (
            <span
              onClick={() => setRead(!read)}
              className=" pl-5 cursor-pointer text-[#ff26099f] whitespace-nowrap underline"
            >
              {read ? "Show less" : "Read more"}
            </span>
          )}
        </span>
      </div>
      <div className="flex flex-col gap-[11px]">
        <div className="flex gap-5 text-[18px] text-[#ffffffa6] leading-[27px] font-[300]">
          <span>Quantity:</span>
          <span>{quantity}</span>
        </div>
        <div className="flex gap-[30px] tablet:justify-start justify-between items-center">
          <span className=" tablet:hidden block text-[#ffffff] opacity-[0.85] mobile:leading-[30px] leading-[21px] font-[500] mobile:text-[20px] text-[14px] ">
            {toPrice(item?.product_price?.currency, item?.product_price?.price)}
          </span>
          <span className="min-w-[184px] tablet:flex hidden">
            <Btn
              cart={cart}
              loading={loading}
              updateCart={updateCart}
              addToCart={addToCart}
            />
          </span>
          <Counter />
        </div>

        <span className="tablet:hidden flex w-full ">
          <Btn
            cart={cart}
            loading={loading}
            updateCart={updateCart}
            addToCart={addToCart}
          />
        </span>
      </div>
      {item?.has_allergens && (
        <div className="flex flex-col gap-[24px]">
          <div className="flex flex-col gap-[8px]">
            <span className=" max-mobile:text-[24px] text-[20px] font-[500] text-[#fff]">
              Allergen Information
            </span>
            <span className="max-mobile:text-[18px] text-[14px] opacity-[0.65] font-[400] text-[#fff]">
              Please check the allergens. If uncertain, search for specific
              allergens to enjoy a safe meal.
            </span>
          </div>
          <div className="flex h-[50px] justify-between items-center rounded-[16px] pl-[20px] p-[4px] bg-[rgba(255,255,255,0.09)]">
            <input
              className="bg-transparent text-[#ffffffc2] placeholder:text-[#666666] border-none outline-none w-full h-full"
              type="text"
              value={searchAl_}
              onChange={(e) => {
                setSearchAl(e.target.value);
                filter(e.target.value.toLowerCase());
              }}
              placeholder="what are you allergic to?"
            />
            {searchAl_.length === 0 ? (
              <span
                onClick={() => filter(searchAl_)}
                className="transparent_bg5 cursor-pointer w-[42px] h-[42px] flex justify-center items-center backdrop:blur-[21.344px] rounded-[16px]"
              >
                <img className="w-[24px] h-[24px]" src={search} alt="" />
              </span>
            ) : (
              <span
                onClick={() => {
                  setSearchAl("");
                  getAllergens();
                }}
                className="transparent_bg5 cursor-pointer w-[42px] h-[42px] flex justify-center items-center backdrop:blur-[21.344px] rounded-[16px]"
              >
                <img className="w-[24px] h-[24px]" src={cancel} alt="" />
              </span>
            )}
          </div>
          <div className="grid grid-cols-12 gap-x-[14p] gap-y-[16px]">
            {item?.allergen_information &&
              (allergens.length > 0 ? (
                allergens.map((val) => {
                  return (
                    <div className="max-mobile:col-span-4 col-span-6 capitalize flex items-center gap-[20px] text-[#fff] text-[16px] opacity-[0.65]">
                      <span className="w-[5px]  h-[5px] bg-[#fff] rounded-full"></span>{" "}
                      {val}
                    </div>
                  );
                })
              ) : (
                <div className="col-span-12 flex justify-center items-center">
                  <span className="text-center text-[#fff] text-[16px] opacity-[0.65]">
                    No result found!
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
      {share && <ShareModal setShare={setShare} />}
    </div>
  );
};

export default ProductContent;
