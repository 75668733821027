import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { appService } from "../../../middle-ware/Services/appService";
import AddressCard from "./AddressCard";
import location from "../../../assets/icons/location-dot.svg";
import Select from "../ui/input/Select";
import Input from "../ui/input/Input";

import Loader from "../../components/ui/Loader";
import Geocode from "react-geocode";
import ex from "../../../assets/icons/ex.svg";
import AddressInput from "../ui/input/AddressInput";
// import axios from "axios";
import usePlacesAutocomplete from "use-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";

Geocode.setApiKey("AIzaSyCN7pinc11ItQCUJH9VXgs3ubpr1GG0vOo");
Geocode.setLanguage("en");
const libraries = ["places"];

const Addresses = () => {
  const [addresses, setAddresses] = useState([]);
  const service = new appService();
  // eslint-disable-next-line
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk",
    libraries,
  });

  const [loading, setLoading] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  // const [loadingOldAddresses, setLoadingOldAddresses] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);
  // const token = localStorage.token;

  const [boundary, setBoundary] = useState({ lat: "", lng: "" });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [areas, setAreas] = useState([]);
  const [allAddresses, setAllAdrresses] = useState([]);
  const [country, setCountry] = useState({
    id: "",
    name: "",
    currency: "",
  });
  const [state, setState] = useState({
    id: "",
    name: "",
  });
  const [area, setArea] = useState({
    id: "",
    name: "",
  });
  const [address, setAddress] = useState({
    house_number: "",
    street: "",
  });
  const [errors, setErrors] = useState({
    house_number: "",
    street: "",
    country: "",
    state: "",
    area: "",
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => boundary.lat, lng: () => boundary.lng },
      radius: 30000,
    },
  });

  const getAddresses = async () => {
    const res = await service.getAddresses();
    if (res.message === "success") {
      setAllAdrresses(res.data);
      const arr = [];
      res?.data?.forEach((a) => {
        arr.push({
          id: a.id,
          name: `${a.house_number} ${a.street}, ${a.area} ${a.state} ${a.country}`,
        });
      });
      setAddresses(arr);
    }
  };
  const getCountries = async () => {
    setLoadingCountries(true);
    const res = await service.getCountries();
    if (res.message === "success") {
      setCountries(res.data);
    }
    setLoadingCountries(false);
  };
  const getStates = async (id) => {
    setLoadingStates(true);
    const res = await service.getStates(id);
    if (res.message === "success") {
      setStates(res.data);
    }
    setLoadingStates(false);
  };
  const getAreas = async (id) => {
    setLoadingAreas(true);
    const res = await service.getAreas(country.id, id);
    if (res.message === "success") {
      setAreas(res.data);
    }
    setLoadingAreas(false);
  };
  const getAreaLocation = async (area_) => {
    try {
      const location = await Geocode.fromAddress(`${area_}, ${state.name}`);
      const { lat, lng } = location?.results[0]?.geometry.location;
      setBoundary({ lat: lat, lng: lng });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (name, data) => {
    if (name === "country") {
      setState({ id: "", name: "" });
      setCountry({
        id: data?.id,
        name: data?.country_data?.data?.translations?.en,
        currency: data?.currency,
      });
      setErrors({ ...errors, country: "", state: "", area: "" });
      getStates(data?.id);
    } else if (name === "state") {
      setArea({ id: "", name: "" });
      setState({
        id: data?.id,
        name: data.state,
      });
      setErrors({ ...errors, state: "", area: "" });
      getAreas(data?.id);
    } else if (name === "area") {
      setArea({
        id: data?.id,
        name: data.name,
      });
      setErrors({ ...errors, area: "", house_number: "", street: "" });
      getAreaLocation(data.name);
    }
  };
  const handleAddress = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "street" && value.length < 3)
      setErrors({ ...errors, [name]: "should be a mininmum of 3 characters" });
    else setErrors({ ...errors, [name]: "" });
  };
  const validateForm = () => {
    const error = {};
    if (address.house_number === "") error.house_number = "Required*";
    if (address.street === "") error.street = "Please select an address*";
    else if (address.street.length < 3)
      error.street = "should be a mininmum of 3 characters";
    if (country.id === "") error.country = "Required*";
    if (state.id === "") error.state = "Required*";
    if (area.id === "") error.area = "Required*";
    return error;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      const location = await Geocode.fromAddress(
        `${address.house_number}, ${address.street} ${area.name} ${state.name} ${country.name}`
      );
      const { lat, lng } = location?.results[0]?.geometry.location;
      const res = await service.postAddress({
        address: {
          house_number: address.house_number,
          street: address.street,
          delivery_zone_id: area.id,
          available_region_id: state.id,
          location_id: country.id,
          longitude: lng,
          latitude: lat,
        },
      });
      if (res.message === "success") {
        localStorage.setItem("currentAddress", JSON.stringify(res.data));
        getAddresses();
        setNewAddress(false);
        toast.success("Address created");
      } else toast.error("Something went wrong, Please try again.");
      setLoading(false);
    }
  };
  // console.log(allAddresses)
  const setCurrentLoaction = async (id) => {
    setLoading(true);
    const res = await service.setCurrentAddress(id);
    if (res.message === "success") {
      localStorage.setItem("currentAddress", JSON.stringify(res.data[0]));
      toast.success("Location changed successfully");
    } else toast.error("Something went wrong, Please try again.");
    setLoading(false);
  };

  useEffect(() => {
    getAddresses();
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-full relative gap-[16px] flex-col ">
      <div className="flex items-center justify-between">
        <span className="mini-tablet:text-[32px] text-[24px] mb-[8px]  font-[500] text-[#ffffff]">
          Saved Addresses
        </span>
        <span
          onClick={() => setNewAddress(true)}
          className={`p-[10px] cursor-pointer font-[500]  flex justify-center items-center bg-[#000000] opacity-[0.85] text-[#ffffff] rounded-[24px] mini-tablet:text-[16px] text-[14px]`}
        >
          Add New
        </span>
      </div>
      <div className="max-h-[600px] min-h-[200px] w-full flex flex-col gap-[16px] h-fit overflow-auto">
        {addresses.length > 0 ? (
          addresses.map((a, n) => (
            <AddressCard
              reload={getAddresses}
              addresses={allAddresses}
              key={n}
              address={a}
              countries={countries}
              setAsLocation={setCurrentLoaction}
            />
          ))
        ) : (
          <div className="min-h-[255px] transparent_bg  gap-[24px] px-5 flex flex-col items-center justify-center">
            <span className="bg-[rgba(255,255,255,0.05)] flex justify-center items-center w-[90px] h-[90px] rounded-full">
              <img className="w-[30px]" src={location} alt="" />
            </span>
            <span className="text-[#ffffff] mobile:text-[18px] mobile:leading-[24px] text-[16px] leading-[21px] text-center max-w-[292px] ">
              You have no address attached to your account
            </span>
          </div>
        )}
      </div>
      {newAddress && (
        <div className="fixed h-screen z-[9999] w-full  top-0 left-0 right-0 min-h-screen flex justify-center overscroll-y-auto bg-[rgba(0,0,0,0.6)]">
          <div className="min-h-screen h-full py-[100px] px-[30px] newscroll w-full flex overflow-auto ">
            <div className="max-w-[574px] min-h-[250px] relative m-auto flex flex-col items-center w-full h-fit rounded-[24px] max-mobile:py-[32.5px] py-[16px]  max-mobile:px-[40px] px-[16px] bg-[#151515]">
              <span className="w-full">
                <img
                  onClick={() => setNewAddress(false)}
                  className=" left-[20px]"
                  src={ex}
                  alt=""
                />
              </span>
              <span className="text-center mobile:text-[32px] text-[24px] mobile:leading-[48px] leading-[30px] font-[600] text-[#ffffff]">
                Add New Address
              </span>
              <div
                className={`flex-col flex max-mobile:gap-[32px] gap-[24px] w-full`}
              >
                <Select
                  list={countries}
                  holder={"Select Country"}
                  onChange={handleChange}
                  loading={loadingCountries}
                  name={"country"}
                  value={country.name}
                  error={errors.country}
                  light={true}
                />
                {country.id !== "" && (
                  <Select
                    list={states}
                    holder={"Select State"}
                    onChange={handleChange}
                    loading={loadingStates}
                    name={"state"}
                    value={state.name}
                    error={errors.state}
                    light
                  />
                )}
                {state.id !== "" && (
                  <Select
                    list={areas}
                    holder={"Select Area"}
                    onChange={handleChange}
                    loading={loadingAreas}
                    name={"area"}
                    value={area.name}
                    error={errors.area}
                    light
                  />
                )}
                {area.id !== "" && (
                  <Fragment>
                    <Input
                      value={address.house_number}
                      type={"text"}
                      onChange={handleAddress}
                      name="house_number"
                      error={errors.house_number}
                      label={"House Number"}
                      light
                    />
                    <AddressInput
                      value={value}
                      type={"text"}
                      onChange={(e) => {
                        setValue(e.target.value);
                        e.target.value === "" &&
                          setErrors({ ...errors, street: "Required" });
                      }}
                      name="street"
                      error={errors.street}
                      label={"Street"}
                      ready={ready}
                      loading={status === "OK"}
                      light
                      list={data}
                      onSelect={(description) => {
                        setAddress({ ...address, street: description });
                        setValue(description, false);
                        clearSuggestions();
                      }}
                    />
                    <span
                      onClick={() => handleSubmit()}
                      className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
                    >
                      {loading ? (
                        <Loader size={[40, 40]} color="#ffffff" />
                      ) : (
                        "Create Address"
                      )}
                    </span>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Addresses;
