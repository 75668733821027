import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Comments from "../components/rideWithUs/comments/Comment";
import Faq from "../components/rideWithUs/Faq";
import GetStarted from "../components/rideWithUs/GetStarted";
import Header from "../components/rideWithUs/Header";
import MobilePreview from "../components/rideWithUs/MobilePreview";
import RiderSpecs from "../components/rideWithUs/RiderSpecs";

const RideWithUs = () => {
  useEffect(()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])
  return (
    <div className="">
      <Header />
      <RiderSpecs />
      <div className="transparent_bg3">
        <GetStarted />
      </div>
      <Comments />
      <MobilePreview />
      <Faq />
      <Footer />
    </div>
  );
};

export default RideWithUs;
