import React from "react";
import bag from "../../../../../assets/icons/bigbag.svg";

const EmptyCart = () => {
  return (
    <div className="h-fit gap-[24px] sm-tablet:w-[386px] w-[300px] px-5 flex flex-col items-center justify-center">
      <span className="bg-[rgba(255,255,255,0.2)] flex justify-center items-center w-[100px] h-[100px] rounded-full">
        <img className="w-[42px] h-[47px]" src={bag} alt="" />
      </span>
      <div className="flex flex-col text-center items-center">
        <span className="text-[#ffffff] sm-tablet:text-[24px] text-[18px] sm-tablet:leading-[36px] leading-[27px] text-center max-w-[292px] ">
          Your cart is empty
        </span>
        <span className="text-[#ffffff] w-[152px] text-[12px] leading-[18px] opacity-[0.65]">
          Once you add items your cart will appear here
        </span>
      </div>
    </div>
  );
};

export default EmptyCart;
