import React from "react";
import dot from "../../../assets/icons/circledot.svg";

const OrderDirections = ({ order }) => {
  return (
    <div className="sm-tablet:w-[363px] h-[121px] p-[22px] px-[30px] bg-[#151515] rounded-[18px] ">
      <div className="relative h-full border-l border-dashed px-[21px] border-[#FF0018] w-full flex flex-col justify-between items-start">
        <div className="absolute left-[-14px] top-0">
          <img src={dot} alt="" />
        </div>
        <span className="text-[#ffffff] whitespace-nowrap text-[16px] leading-[24px] font-[400] ">
          Eve After Dark
        </span>
        <span className="text-[#ffffff] whitespace-nowrap text-[16px] leading-[24px] font-[400] ">
          {order?.address?.area} {order?.address?.state}
        </span>
        <div className="absolute left-[-14px] bottom-0">
          <img src={dot} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OrderDirections;
