import React from "react";
import CommentCard from "../../ui/CommentCard";
import "./reviews.scss";
import { homeReviews } from "../../../../constants/data";

const Reviews = () => {
  return (
    <div className="w-full mini-tablet:h-[1007px] mini-tablet:py-[200px] py-[88px] flex flex-col gap-[48px] justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <span className="mini-tablet:text-[48px] mobile:text-[32px] text-[24px] font-[500] mini-tablet:leading-[72px] mobile:leading-[48px] leading-[36px] text-center mini-tablet:max-w-[505px] mobile:max-w-[337px] max-w-[253px] text-[#ffffff]">
          What our customers say about us
        </span>
        <span className="text-[16px] px-[16px] font-[400] leading-[19px] text-center  mini-tablet:max-w-[629px] max-w-[700px] text-[#ffffff] opacity-[0.65] mt-[16px]">
          We cook and they enjoy every bite! What we care about is the WOW
          effect and all the luck that our efforts are not in vain. We know
          because we listen to our customers.
        </span>
      </div>
      <div className="slider">
        <div className="slide_track">
          {homeReviews?.map((r, n) => (
            <div key={n} className="slide">
              <CommentCard avatar={r.image} name={r.name} text={r.text} />
            </div>
          ))}
        </div>
      </div>
      {/* <div className="slider2">
        <div className="slide_track2">
          {homeReviews?.map((r, n) => (
            <div key={n} className="slide2">
              <CommentCard avatar={r.image} name={r.name} text={r.text} />
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Reviews;
