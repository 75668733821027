import React, { Fragment, useRef, useState } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { RiEdit2Fill, RiCloseFill } from "react-icons/ri";
import polygon from "../../../assets/vectors/polygon.png";
import card1 from "../../../assets/vectors/card1.svg"

const PaymentCard = () => {
    const [toggle, setToggle] = useState(false);
    const toggleRef = useRef();
    window.addEventListener("click", (e) => {
      if (toggle && !toggleRef.current?.contains(e.target)) setToggle(false);
    });
  
    return (
      <div className="w-full  relative transparent_bg3 rounded-[16px] h-[63px] px-[16px] flex justify-between items-center">
        <div className="flex gap-[24px] items-center">
          <img src={card1} className="w-[56px] h-[42px]" alt="" />
          <div className="flex flex-col">
            <span className="text-[18px] leading-[27px] font-[400] text-[#ffffff] opacity-[0.65]">**** **** **** *898</span>
            <span className="text-[16px] leading-[24px] font-[400] text-[#ffffff] opacity-[0.65]">Master Card</span>
          </div>
        </div>
        <div ref={toggleRef}>
          <FaEllipsisH
            onClick={() => setToggle(!toggle)}
            className="text-[20px] text-[#ffffff] cursor-pointer"
          />
          {toggle && (
            <Fragment>
              <img
                src={polygon}
                className="absolute transition-transform ease-in-out duration-300 top-[37px] right-0 w-[40px] h-[40px] z-30"
                alt=""
              />
              <div className="flex z-30 transition-transform ease-in-out duration-300 flex-col gap-[24px] items-center justify-center absolute right-0 top-[52px] bg-[#151515] rounded-[16px] w-[114px] h-[114px]">
                <span className="flex cursor-pointer items-center gap-[6px] text-[#00BA80] text-[14px] leading-[21px] font-[600]">
                  Edit <RiEdit2Fill />
                </span>
                <span className="flex cursor-pointer items-center gap-[6px] text-[#AD0010] text-[14px] leading-[21px] font-[600]">
                  Delete <RiCloseFill />
                </span>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  };

export default PaymentCard