import React, { useState, createContext, useContext } from "react";

const reactContext = createContext();

const DataContextProvider = (props) => {
  const [profile, setProfile] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addressModal, setAddressModal] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(
    localStorage.address ? JSON.parse(localStorage.address) : null
  );
  const [categories, setCategories] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [rawFavourites, setRawFavourites] = useState([]);

  const [dealsOfTheDay, setDealsOfTheDay] = useState([]);
  const [popularDishes, setPopularDishes] = useState([]);
  const [menuProducts, setMenuProducts] = useState(categories[0]?.items);
  const [hotReload, setHotReload] = useState(false);

  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [fetchingAllProducts, setFetchingAllProducts] = useState(false);

  return (
    <reactContext.Provider
      value={{
        profile,
        products,
        addresses,
        currentAddress,
        addressModal,
        categories,
        favourites,
        rawFavourites,
        setCategories,
        setProfile,
        setProducts,
        setAddresses,
        setCurrentAddress,
        setAddressModal,
        setFavourites,
        setRawFavourites,
        dealsOfTheDay,
        setDealsOfTheDay,
        popularDishes,
        setPopularDishes,
        menuProducts,
        setMenuProducts,
        hotReload,
        setHotReload,
        fetchingProducts,
        setFetchingProducts,
        allProducts,
        setAllProducts,
        fetchingAllProducts,
        setFetchingAllProducts,
      }}
      {...props}
    />
  );
};

const useDataContext = () => {
  const context = useContext(reactContext);
  return context;
};

export { DataContextProvider, useDataContext };
