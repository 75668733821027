import { useState } from "react";
import { appService } from "../../middle-ware/Services/appService";
// import { useCatalogue } from "./useCatalogue";
// import { useDishes } from "../components/home/hooks/useDishes";
// import { useFlowContext } from "../../middle-ware/StateContext/FlowContext";
import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import toast from "react-hot-toast";
// import { useAuthContext } from "../../middle-ware/StateContext/AuthContext";

export const useHotReload = () => {
  const service = new appService();

  const { setPopularDishes, setDealsOfTheDay, setHotReload } = useDataContext();
  // const currentLocation = localStorage.currentAddress
  //   ? JSON.parse(localStorage.currentAddress)
  //   : {};
  const path = window.location.pathname;
  const getPopularDishes = async () => {
    setLoading(true);
    setHotReload(false);
    const dishes = await service.getPorpularDishes();
    if (dishes.message === "success") {
      setPopularDishes(dishes.data.products);
      setHotReload(false);
    }
  };

  const getAllCategories = async (id) => {
    const res = await service.getAllCategories();
    if (res.message === "success") {
      const { data } = res;
      const menuRoute = path.includes("menu")
        ? path.slice(6).replaceAll("%20", " ")
        : "";
      const category_id =
        menuRoute.length > 0
          ? data.find((c) => c.title === menuRoute)?.id
          : data[0].id;
      // console.log("response", category_id, menuRoute);
      if (localStorage.token && localStorage.currentAddress) {
        await service.getProductsByCategory(category_id);
      } else {
        await service.getProductsByCategoryAndLocation(
          category_id,
          id
        );
      }
    } else {
      if (res?.message === "unauthorized") {
        localStorage.clear();
        toast.error("Session timeout");
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    setHotReload(true);
    if (localStorage.token && localStorage.currentAddress) {
      await service.getCart();
      await service.getFavourites();
      await service.getPorpularDishes();
    } else if (!localStorage.token && localStorage.currentAddress) {
      service.getLocalCart();
      await service.getPorpularDishes();
      setHotReload(false);
    }
  };
  const dealsOfTheDay = async () => {
    setLoading(true);
    setHotReload(true);
    const res = await service.getDealsOfTheDay();
    if (res.message === "success") {
      localStorage.removeItem("forAFriend");
      setDealsOfTheDay(res.data.products);
      setHotReload(false);
    }
  };

  const [loading_, setLoading] = useState(true);

  const handleHotReload = (id) => {
    setLoading(true);
    getPopularDishes();
    getData(id);
    dealsOfTheDay();
    setLoading(false);
    getAllCategories(id);
  };

  return { loading_, handleHotReload };
};
