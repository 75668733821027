import React from "react";
import rider from "../../../assets/images/rider.svg";
import call from "../../../assets/icons/call.svg";
import chat from "../../../assets/icons/message.svg";

const OrderWaitCard = ({ message }) => {
 
  return (
    <div className={"sm-tablet:w-[358px] w-full bg-[#151515] p-[20px] rounded-[18px] flex flex-col gap-[16px]"}>
      <div className="flex flex-col items-center border-b-[0.65px] pb-[16px] border-[#ffffff17] ">
        <span className="text-[14px] text-center font-[500] leading-[21px] text-[#ffffff] ">
          Estimated Delivery Time is{" "}
          <span className="text-[#ff0018]"> 12pm</span>
        </span>
        <span className="text-[#ffffff] text-[12px] font-[400] leading-[18px] opacity-[0.65]">
          Your order is already on the way
        </span>
      </div>
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-[12px] w-full">
          <img src={rider} className="w-[34px] h-[34px] rounded-full" alt="" />
          <div className="flex flex-col">
            <span className="text-[#ffffff] text-[12px] font-[500] leading-[18px]">
              Alex Uzor
            </span>
            <span className="text-[#ffffff] text-[12px] font-[400] leading-[18px] opacity-[0.65]">
              Dispatch Rider
            </span>
          </div>
        </div>
        <div className="flex gap-[10px]">
          <span className="w-[34px] h-[34px] flex justify-center transparent_bg3 rounded-full items-center  ">
            <img src={call} className="w-[13.5px] h-[13.5px]" alt="" />{" "}
          </span>
          <span
            onClick={() => message()}
            className="w-[34px] h-[34px] flex justify-center transparent_bg3 rounded-full items-center  "
          >
            <img src={chat} className="w-[13.5px] h-[13.5px]" alt="" />{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderWaitCard;
