import React, { Fragment } from "react";
// import { useState } from "react";
// import { appService } from "../../hooks/Services/appService";
import { useFlowContext } from "../../../middle-ware/StateContext/FlowContext";
import CartCard from "../ui/CartCard";
import OrderCartCard from "../ui/OrderCartCard";
// import Loader from "../ui/Loader";
import bag from "../../../assets/icons/bag.svg";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";

const CurrentOrder = ({ processed, title, completed }) => {
  const context = useFlowContext();
  const navigate = useNavigate();
  const path = useResolvedPath();

  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    });
    return formatter.format(amount);
  };

  return (
    <div className="flex flex-col gap-[24px] tablet:pl-[24px]">
      {context?.cart?.items?.length < 1 ? (
        <Fragment>
          <span className="tablet:text-[40px] text-[24px] tablet:leading-[60px] leading-[36px] font-[600] text-[#ffffff]">
            {title}
          </span>
          <div className="h-[455px] gap-[24px] px-5 flex flex-col items-center justify-center">
            <span className="bg-[rgba(255,255,255,0.05)] flex justify-center items-center w-[130px] h-[130px] rounded-full">
              <img className="w-[55px]" src={bag} alt="" />
            </span>
            <span className="text-[#ffffff] text-[24px] leading-[36px] text-center max-w-[292px] ">
              Cart is currently empty
            </span>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="flex flex-col">
            <span className="tablet:text-[40px] text-[24px] tablet:leading-[60px] leading-[36px] font-[600] text-[#ffffff]">
              {title}
            </span>
            <span className="tablet:text-[18px] text-[16px] tablet:leading-[27px] leading-[24px] font-[600] text-[#ffffff] opacity-[0.65] ">
              {context?.cart?.items?.length} ITEM
              {context?.cart?.items?.length > 1 && "S"}
            </span>
          </div>
          {processed ? (
            <div className="flex flex-col w-full gap-[16px]">
              {context?.cart?.items?.map((i, n) => (
                <OrderCartCard item={i} key={n} />
              ))}
            </div>
          ) : (
            <div className="flex flex-col w-full gap-[16px]">
              {context?.cart?.items?.map((i, n) => (
                <CartCard item={i} key={n} />
              ))}
            </div>
          )}
          <div className="flex justify-between items-center ">
            <div className="flex flex-col gap-[8px] w-full">
              <span className="text-[#ffffff] mobile:text-[24px] text-[20px] mobile:leading-[36px] leading-[30px] font-[600]">
                Bill details
              </span>
              <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
                <span className="opacity-[0.65]">Item Total</span>
                <span>
                  {localStorage?.token
                    ? toPrice(context?.cart?.currency, context?.cart?.sub_total)
                    : toPrice(context?.cart?.currency, context?.cart?.total)}
                </span>
              </span>
              {localStorage.token && (
                <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
                  <span className="opacity-[0.65]">
                    Discount
                    {context?.cart?.voucher?.percentage &&
                      `(${context?.cart?.voucher?.percentage}% off)`}
                  </span>
                  <span className="text-[#FF0018]">
                    -
                    {toPrice(
                      context?.cart?.currency,
                      context?.cart?.discount_amount
                    )}
                  </span>
                </span>
              )}
              {localStorage.token && (
                <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
                  <span className="opacity-[0.65]">Delivery Fee</span>
                  <span>
                    {toPrice(
                      context?.cart?.currency,
                      context?.cart?.delivery_charge
                    )}
                  </span>
                </span>
              )}

              {localStorage.token && (
                <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
                  <span className="opacity-[0.65]">VAT</span>
                  <span>
                    {toPrice(
                      context?.cart?.currency,
                      context?.cart?.vat_charge
                    )}
                  </span>
                </span>
              )}
            </div>
          </div>
          {localStorage.token && (
            <span className="flex border-t justify-between pt-[24px] w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
              <span> Total</span>
              <span>
                {toPrice(
                  context?.cart?.currency,
                  context?.cart?.payment?.total
                )}
              </span>
            </span>
          )}
          {path.pathname !== "/checkout" && (
            <span
              onClick={() => navigate("/checkout")}
              className={` text-[#fff] bg-[#FF0018] flex mobile:text-[20px] text-[14px] leading-[30px] font-[500] cursor-pointer sm:h-[66px] h-[52px] w-full justify-center items-center  rounded-[100px]`}
            >
              Checkout
            </span>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default CurrentOrder;
