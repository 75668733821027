import React from "react";
import AuthLayout from "../components/ui/layouts/AuthLayout";
import Input from "../components/ui/input/Input";
import eyeSlash from "../../assets/icons/eye-slash.png";
import eye from "../../assets/icons/eye.png";
import { Link } from "react-router-dom";
import Loader from "../components/ui/Loader";
import useResetPassword from "../hooks/useResetPassword";
// import { useAuthContext } from "../../middle-ware/StateContext/AuthContext";

const ResetPassword = () => {
  const {
    form,
    passwordView,
    cfPasswordView,
    errors,
    setPasswordView,
    setCfPasswordView,
    handleChange,
    handleSubmit,
    loading,
  } = useResetPassword();
  return (
    <AuthLayout
      title={"Create a new password"}
      text={"Reset the password for your account"}
    >
      <form className="w-full flex-col flex gap-[32px]" action="">
        <Input
          value={form.password}
          type={passwordView ? "text" : "password"}
          onChange={handleChange}
          name="password"
          error={errors.password}
          label={"Password"}
        >
          {!passwordView ? (
            <img
              src={eye}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setPasswordView(true)}
            />
          ) : (
            <img
              src={eyeSlash}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setPasswordView(false)}
            />
          )}
        </Input>
        <Input
          value={form.cf_password}
          type={cfPasswordView ? "text" : "password"}
          onChange={handleChange}
          name="cf_password"
          error={errors.cf_password}
          label={"Confirm Password"}
        >
          {!cfPasswordView ? (
            <img
              src={eye}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setCfPasswordView(true)}
            />
          ) : (
            <img
              src={eyeSlash}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setCfPasswordView(false)}
            />
          )}
        </Input>

        <span
          onClick={() => handleSubmit()}
          className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
        >
          {loading ? <Loader size={[40, 40]} color="#ffffff" /> : "Submit"}
        </span>

        <span className="text-[16px] text-center leading-[24px] font-[400] text-[#A3A3A3]">
          Have an account?
          <Link to="/login" className="text-[#FF0018] cursor-pointer">
            Sign In
          </Link>
        </span>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
