import React from "react";
import { useProductItem } from "../hooks/useProductItem";
import Loader from "../../ui/Loader";

const Btn = ({ cart, loading, updateCart, addToCart }) => {
  // console.log(cart)
  return (
    <span
      onClick={() => (cart && cart?.length ? updateCart("plus") : addToCart())}
      className="px-[32px] w-full flex justify-center text-center sm:h-[66px] h-[52px] items-center cursor-pointer red_btn_shadow rounded-[100px]  max-mobile:text-[20px] text-[14px] text-[#ffffff] bg-[#FF0018] leading-[30px] font-[500]"
    >
      {loading ? (
        <Loader color={"#ffffff"} size={[20, 20]} />
      ) : cart && cart?.length > 0 ? (
        "Update Cart"
      ) : (
        "Add To Cart"
      )}
    </span>
  );
};

export default Btn;
