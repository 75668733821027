import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import client from "../../middle-ware/axiosInstance";

const useResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    password: "",
    cf_password: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    cf_password: "",
  });

  const [passwordView, setPasswordView] = useState(false);
  const [cfPasswordView, setCfPasswordView] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "password" && value.length < 8) {
      setErrors({
        ...errors,
        [name]: "Password should not be less than 8 characters*",
      });
    } else if (name === "cf_password" && value !== form.password) {
      setErrors({ ...errors, [name]: "Passwords do not match*" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    const { password, cf_password } = form;
    const error = {};
    if (password === "") error.password = "Required*";
    else if (password.length < 8)
      error.password = "Passwords should be at least 8 characters";
    if (cf_password === "") error.cf_password = "Required*";
    else if (cf_password !== password)
      error.password = "Passwords do not match*";
    return error;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        const response = await client.post("/auth/password/update", {
          user: {
            password_reset_token: params["*"],
            password: form.password,
            password_confirmation: form.cf_password,
          },
        });
        // console.log(response);
        localStorage.setItem("token", response.data.data.auth.token);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        setLoading(false);
        localStorage.removeItem("currentAddress");
        localStorage.removeItem("localCart");
        localStorage.removeItem("guestUser");
        toast.success("Welcome back to Eve After Dark!");
        navigate("/");
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("User unauthorized!");
        } else toast.error("Failed to login, Please try again");
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (params["*"] === "") navigate("/not-found");
    // eslint-disable-next-line
  }, [params]);

  return {
    params,
    form,
    errors,
    passwordView,
    cfPasswordView,
    loading,
    navigate,
    handleChange,
    handleSubmit,
    setCfPasswordView,
    setPasswordView,
  };
};

export default useResetPassword;
