import React, { Fragment } from "react";
import play from "../../../assets/vectors/GooglePlay.svg";
import apple from "../../../assets/vectors/AppStore.svg";
import phones from "../../../assets/images/phoneui-image.png";
import flag from "../../../assets/images/logo.png";
import { BsApple } from "react-icons/bs";

const MobilePreview = () => {
  return (
    <Fragment>
      <div
        style={{
          background:
            "linear-gradient(260.15deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%)",
        }}
        className="w-full max-mobile:block hidden mini-tablet:h-[600px] min-h-[348px] "
      >
        <div className="max-w-[1288px] gap-[30px] m-auto grid grid-cols-12">
          <div className="col-span-6 pl-[35px] flex flex-col justify-center gap-[24px]">
            <div className="max-w-[324px]">
              <span className="font-[500] mini-tablet:h-[144px] h-[96px] mini-tablet:text-[48px] text-[32px] mini-tablet:leading-[72px] leading-[48px] text-[#ffffff]">
                Restaurant in your pocket
              </span>
            </div>
            <span className="mini-tablet:text-[24px] text-[16px] mini-tablet:leading-[36px] leading-[24px] font-[400] text-[#ffffff] opacity-[0.65]">
              Order from anywhere & track on the go, with the all-new Eve After
              Dark app.
            </span>
            <div className="flex gap-y-3 gap-x-[24px] flex-wrap">
              <img
                onClick={() =>
                  (window.location.href =
                    "https://play.google.com/store/apps/details?id=com.theeveafterdark.customer")
                }
                src={play}
                className="mini-tablet:w-[263px] cursor-pointer w-[140px] mini-tablet:h-[92px] h-[48px]"
                alt=""
              />
              <img
                onClick={() =>
                  (window.location.href =
                    "https://apps.apple.com/gb/app/eve-after-dark/id6450600671")
                }
                src={apple}
                className="mini-tablet:w-[263px] cursor-pointer w-[140px] mini-tablet:h-[92px] h-[48px]"
                alt=""
              />
            </div>
          </div>
          <div className=" col-span-6">
            <img
              src={phones}
              className="mini-tablet:h-[600px] h-[348px]"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="max-mobile:hidden flex flex-col gap-[24px] w-full max-mobile:px-[35px] px-[16px] ">
        <div className="flex gap-[16px] items-center">
          <img
            src={flag}
            className="w-[72.25px] h-[72.25px] rounded-[16px]"
            alt=""
          />
          <span className="text-[#ffffff] max-w-[275px] text-[24px] leading-[36px] font-[600]">
            There’s more to love in the app.
          </span>
        </div>
        <span className="flex transparent_bg3 justify-center items-center gap-[8px] p-[16px] rounded-[16px] ">
          <BsApple className="text-[20px] text-[#ffffff]" />
          <span
            onClick={() =>
              (window.location.href =
                "https://apps.apple.com/gb/app/eve-after-dark/id6450600671")
            }
            className="text-[#ffffff] text-[17.7px] leading-[22px] font-[600]"
          >
            Get the app
          </span>
        </span>
      </div>
    </Fragment>
  );
};

export default MobilePreview;
