import React, { Fragment } from "react";

const PolicyTemplate = ({ title, data, subTitle, list }) => {
  return (
    <div className="flex flex-col w-full gap-[16px]">
      <span className="text-[28px] leading-[42px] text-[#ffffff] font-[500]">
        {title}
      </span>
      <span className="text-[20px] leading-[30px] text-[#ffffff] font-[500]">
        {subTitle}
      </span>
      <div className="flex flex-col gap-[8px]">
        {data?.map((d, n) => (
          <p
            key={n}
            className="text-[#ffffff] text-[16px] leading-[24px] font-[400] opacity-[0.65] "
          >
            {d}
          </p>
        ))}
      </div>
      <div className="flex flex-col gap-[16px] mobile:ml-5 ml-0 ">
        {list?.map((l, n) => (
          <div key={n * 20} className="flex mobile:gap-5 gap-1">
            <span className="text-[20px] leading-[30px] text-[#ffffff] font-[500]">
              {n + 1}.
            </span>
            <div className="flex flex-col gap-[8px]" key={n}>
              <span className="text-[20px] leading-[30px] text-[#ffffff] font-[500]">
                {l.title}
              </span>
              {l.sub && (
                <div className="flex mobile:gap-5 gap-1">
                  <span className="text-[#ffffff] text-[16px] leading-[24px] font-[400]">
                    {`${n + 1}.${1}`}
                  </span>
                  <div className="flex flex-col gap-[8px]">
                    <p className="text-[#ffffff] text-[16px] leading-[24px] font-[400] opacity-[0.65] ">
                      {l.sub.title}
                    </p>
                    {l.sub.items.map((i, s) => (
                      <p
                        key={s}
                        className="text-[#ffffff] text-[16px] leading-[24px] font-[400] opacity-[0.65] "
                      >
                        {i}
                      </p>
                    ))}
                  </div>
                </div>
              )}
              {l.items.map((i, d) => (
                <div key={d} className="flex mobile:gap-5 gap-3">
                  <span className="text-[#ffffff] text-[16px] leading-[24px] font-[400]">
                    {`${n + 1}.${d + l.sub ? 2 : 1}`}
                  </span>
                  <p className="text-[#ffffff] text-[16px] leading-[24px] font-[400] opacity-[0.65] ">
                    {i}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PolicyTemplate;
