import React from "react";
import AddressModal from "../../views/components/modals/addressModal/AddressModals";
// import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import { useFlowContext } from "../../middle-ware/StateContext/FlowContext";
import CartModal from "../../views/components/modals/cartModal/CartModal";

export const useModal = () => {
  // const context = useDataContext();
  const { cartModalView, addressModalView, openModal } = useFlowContext();
  if (openModal)
    return (
      <div className="fixed h-screen z-[9999] w-full  top-0 left-0 right-0 min-h-screen flex justify-center overscroll-y-auto bg-[rgba(0,0,0,0.6)]">
        {addressModalView &&
          localStorage.token &&
          !window.location.href.includes("login") &&
          !window.location.href.includes("signup") && <AddressModal />}
        {cartModalView && <CartModal />}
      </div>
    );
  else return null;
};
