import React from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/icons/arrow-left.svg";
import LocationCard from "../components/ui/LocationCard";
import Loader from "../components/ui/Loader";
import { useLocationHandler } from "../hooks/useLocationHandler";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

const ForFriend = () => {
  const navigate = useNavigate();
  const { locations, location, setLocation, loading, setFriendsLocation } =
    useLocationHandler();

  return (
    <div className="flex grow-0 relative  mini-tablet:gap-[80px] max-mobile:gap-[60px] gap-[40px] flex-col z-40 w-full px-[32px] mini-tablet:py-0 py-[129.5px] mini-tablet:h-[1024px] justify-center items-center ">
      <span className="absolute max-w-[1288px] top-[35px] h-[20px] w-full">
        <img
          className=" cursor-pointer"
          onClick={() => navigate("/")}
          src={arrow}
          alt=""
        />
      </span>

      <span className="text-[#ffffff] px-5 text-center  mini-tablet:text-[60px] max-mobile:text-[40px] text-[24px]  max-w-[794px] flex justify-center  mini-tablet:leading-[90px] max-mobile:leading-[60px] leading-[36px] font-[600]">
        Select your Friend’s location to continue
      </span>
      <div className="w-[100%] max-w-[1288px] max-mobile:block hidden h-[296px] mx-auto ">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={24}
          slidesPerView={3}
          breakpoints={{
            0: {
              width: 329,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            964: {
              width: 329,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            1248: {
              width: 1288,
              slidesPerView: 3,
            },
          }}
        >
          {locations.map((l, n) => (
            <SwiperSlide key={n}>
              <LocationCard
                value={l}
                location={location}
                setLocation={(id) => setFriendsLocation(id)}
                loading={loading}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className=" max-mobile:hidden flex w-full justify-center mini-tablet:flex-row flex-col items-center mini-tablet:gap-[32px] gap-[16px] mini-tablet:h-[296px] ">
        {locations.map((l, n) => (
          <LocationCard
            value={l}
            location={location}
            setLocation={(id) => setFriendsLocation(id)}
            loading={loading}
            key={n}
          />
        ))}
      </div>
      {/* <span
        onClick={() => location !== "" && setFriendsLocation()}
        className={`${
          location !== ""
            ? "bg-[#FF0018] text-[#ffffff]"
            : "bg-[#FFE5E8] text-[#FF8A95]"
        } cursor-pointer flex justify-center items-center justify-self-center max-mobile:w-[408px] w-full h-[66px] transition-all ease-in-out duration-300 text-[20px] leading-[30px] font-[500]  rounded-[100px]  `}
      >
        {loading ? <Loader size={[30, 30]} color={"#fff"} /> : "Continue"}
      </span> */}
    </div>
  );
};

export default ForFriend;
