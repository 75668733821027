import React from "react";
import Skeleton from "react-loading-skeleton";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";

const getUrl = (url) => {
  return url;
};
const Images = ({ classes, url, width, height, radius }) => {
  const { hotReload } = useDataContext();
  if (hotReload) {
    return (
      <div className={classes + "overflow-hidden bg-[#ffffff2c]"}>
        <Skeleton
          count={1}
          width={width}
          height={height}
          borderRadius={radius}
          circle={radius === "100%"}
        />
      </div>
    );
  }

  if (url) {
    return (
      <div
        style={{
          backgroundImage: `url(${getUrl(url)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={classes + " bg-[#ffffff2c]"}
      ></div>
    );
  } else
    return (
      <div className={classes + "overflow-hidden bg-[#ffffff2c]"}>
        <Skeleton
          count={1}
          width={width}
          height={height}
          borderRadius={radius}
          circle={radius === "100%"}
        />
      </div>
    );
};

export default Images;
