import React, { useState } from 'react'
import { appService } from '../../../../middle-ware/Services/appService';

export const useCartCardFunctions = (item) => {
    const service = new appService();

  const [loading, setLoading] = useState(false);

  const updateCart = (action) => {
    setLoading(true);
    if (action === "plus") {
      if (localStorage.token) {
        service.updateCart(item.id, item.quantity + 1);
      } else {
        service.addToLocalCart(item.product, item.quantity + 1);
        service.getLocalCart();
      }
    } else if (action === "minus" && item.quantity)
      if (localStorage.token) {
        service.updateCart(item.id, item.quantity - 1);
      } else {
        service.addToLocalCart(item.product, item.quantity - 1);
        service.getLocalCart();
      }
    setLoading(false);
  };
  const deleteCartItem = () => {
    if (localStorage.token) {
      service.deleteCartItem(item.id);
    } else {
      service.deleteLocalCart(item.product);
      service.getLocalCart();
    }
  };
  return {deleteCartItem, updateCart, loading}
}
