import React from "react";
import { GiThreeFriends } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const SmallForFriendFloater = () => {
  const navigate = useNavigate();
  return (
    <div
      className=" cursor-pointer mini-tablet:hidden block"
      onClick={() => navigate("/buy-for-a-friend")}
    >
      <div
        style={
          localStorage.forAFriend
            ? { background: "#fff", color: "#FF0018" }
            : { background: "#ffffff17", color: "#FF0018" }
        }
        className=" relative flex gap-2 w-[35px]  rounded-full h-[35px] items-center justify-center"
      >
        <GiThreeFriends className="text-[18px]" />
      </div>
    </div>
  );
};

const ForFriendFloater = () => {
  const navigate = useNavigate();
  return (
    <div
      className=" w-[50px] h-[50px] cursor-pointer right-[50px] mini-tablet:hidden block"
      onClick={() => navigate("/buy-for-a-friend")}
    >
      <div
        // style={
        //   localStorage.forAFriend
        //     ? { background: "#fff", color: "#FF0018" }
        //     : { background: "#000", color: "#FF0018" }
        // }
        className={` relative flex gap-2 w-[50px] text-[#ff0018] border ${localStorage.forAFriend? "border-[#ff0018] bg-[#fff]": "border-[#16151548] bg-[#141212]"}   rounded-full h-[50px] items-center justify-center`}
      >
        <GiThreeFriends className="text-[20px]" />
      </div>
    </div>
  ); 
};

export { ForFriendFloater, SmallForFriendFloater };
