import React from "react";


const InfoCard = ({heading, desc, icon}) => {
  return (
    <div className="max-w-[381px] w-full flex flex-col gap-[8px] justify-center items-center">
      <span className="w-[60px] rounded-full h-[60px] flex justify-center items-center transparent_bg3">
        <img className="w-[30px] h-fit" src={icon} alt="" />
      </span>
      <span className="mini-tablet:text-[24px] text-[20px] mini-tablet:leading-[36px] leading-[30px] text-center text-[#ffffff] font-[500]">
        {heading}
      </span>
      <span className="mini-tablet:text-[18px] text-[16px] leading-[24px] mini-tablet:leading-[27px] text-center text-[#ffffff] font-[400] opacity-[0.65]">
        {desc}
      </span>
    </div>
  );
};

export default InfoCard;
