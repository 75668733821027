import axios from "axios";
import { toast } from "react-hot-toast";
import { useDataContext } from "../StateContext/DataContext";
import { useFlowContext } from "../StateContext/FlowContext";

export class appService {
  state = useDataContext();
  flow = useFlowContext();
  baseURL = `${process.env.REACT_APP_API_URL}/api/v1/fe`;

  headers() {
    const geolocation = JSON.parse(localStorage.getItem("geolocation"));
    const userIP = localStorage.getItem("userIP");
    const token = localStorage.getItem("token");

    return {
      Authorization: `Bearer ${token}`,
      "X-Latitude": geolocation?.latitude,
      "X-Longitude": geolocation?.longitude,
      "X-User-IP": userIP,
      "Content-Type": "application/json",
    };
  }

  getAddresses = async () => {
    try {
      const res = await fetch(this.baseURL + `/addresses`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getCountries = async () => {
    try {
      const res = await fetch(this.baseURL + `/addresses/countries`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getStates = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/addresses/countries/${id}`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getAreas = async (country_id, state_id) => {
    try {
      const res = await fetch(
        this.baseURL + `/addresses/countries/${country_id}/state/${state_id}`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  setCurrentAddress = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/addresses/set_current`, {
        method: "POST",
        headers: this.headers(),
        body: JSON.stringify({
          user: {
            current_address_id: id,
          },
        }),
      });
      const response = await res.json();

      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  setCurrentLocation = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/addresses/set_location`, {
        method: "POST",
        headers: this.headers(),
        body: JSON.stringify({
          user: {
            location_id: id,
          },
        }),
      });
      const response = await res.json();

      const location = localStorage.currentAddress
        ? JSON.parse(localStorage.currentAddress)
        : undefined;
      if (
        location &&
        location.location_id === response.data[0].location_id &&
        localStorage.localCart
      ) {
        const localCart = JSON.parse(localStorage.localCart);
        localCart.items.forEach(async (item) => {
          await this.addLocalToMainCart(item.product.id, item.quantity);
        });
        localStorage.removeItem("localCart");
      }
      if (localStorage.currentAddress && localStorage.initialSetup)
        localStorage.setItem("initialSetup", false);
      else localStorage.setItem("initialSetup", true);
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getAllLocations = async () => {
    try {
      const res = await fetch(this.baseURL + `/addresses/locations`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();

      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getAllCategories = async () => {
    const { setCategories, setFetchingProducts } = this.state;
    try {
      setFetchingProducts(true);
      const res = await fetch(this.baseURL + `/products/categories`, {
        method: "GET",
        headers: this.headers(),
      });
      const json = await res.json();

      if (json.status && json.status === "unauthorized") {
        localStorage.clear();
        return { message: "unauthorized" };
      } else {
        const categories = json.data.map((c) => {
          return { ...c, items: [], title: c.name };
        });
        setCategories(categories);
        return { message: "success", data: categories };
      }
    } catch (err) {
      return { message: "failed" };
    }
  };
  getProductsByCategoryAndLocation = async (category_id, location_id) => {
    const { setProducts, setFetchingProducts } = this.state;

    try {
      setFetchingProducts(true);
      const res = await fetch(
        this.baseURL +
          `/products/location/${location_id}/products/category/${category_id}?page=1&per_page=${3000}`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const json = await res.json();

      setProducts(json.data.products);
      setFetchingProducts(false);
      localStorage.setItem("max-price", json.data.pagination.highest_price);
      localStorage.setItem("currency", json.data.pagination.highest_price);
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getProductsByCategory = async (category_id) => {
    const { setProducts, setFetchingProducts } = this.state;
    try {
      setFetchingProducts(true);
      const res = await fetch(
        this.baseURL +
          `/products/category/:category_id?page=1&per_page=${3000}`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const json = await res.json();
      setProducts(json.data.products);

      localStorage.setItem("max-price", json.data.pagination.highest_price);
      setFetchingProducts(false);
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getPrductsByLoaction = async (id) => {
    // const { setProducts, setCategories, setMenuProducts } = this.state;
    try {
      // const res = await fetch(
      //   this.baseURL +
      //     `/products/location/${id}/products?page=1&per_page=${3000}`,
      //   {
      //     method: "GET",
      //     headers: this.headers(),
      //   }
      // );
      // const json = await res.json();

      // if (json.status && json.status === "unauthorized") {
      //   localStorage.clear();
      //   return { message: "unauthorized" };
      // }
      // const products = json.data.products;
      // const categories = [];
      // localStorage.setItem("max-price", json.data.pagination.highest_price);
      // // const combos = products.filter(p =>p.category.name ==="Combos" )

      // products.forEach((p) => {
      //   if (!categories.includes(p.category.name))
      //     categories.push(p.category.name);
      // });

      // const cat = categories;

      // let allItems = [];
      // cat.forEach((c) => {
      //   const items = products.filter((p) => p.category.name === c);
      //   allItems.push({
      //     title: c,
      //     items: items,
      //     image: items[0].category.image,
      //     id: items[0].category.id,
      //   });
      // });

      // const sortOrder = allItems.sort(function (a, b) {
      //   if (a.title < b.title) {
      //     return -1;
      //   }
      //   if (a.title > b.title) {
      //     return 1;
      //   }
      //   return 0;
      // });

      // setCategories(sortOrder);
      // setProducts(json.data.products);
      // setMenuProducts(sortOrder[0].items);
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getProducts = async () => {
    // const { setProducts, setCategories, setMenuProducts } = this.state;
    // fetch(this.baseURL + `/products?page=${1}&per_page=${3000}`, {
    //   method: "GET",
    //   headers: this.headers(),
    // })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((json) => {
    //     const products = json?.data?.products;
    //     const categories = [];
    //     localStorage.setItem("max-price", json.data.pagination.highest_price);
    //     products.forEach((p) => {
    //       if (!categories.includes(p.category.name))
    //         categories.push(p.category.name);
    //     });
    //     const cat = categories;
    //     let allItems = [];
    //     cat.forEach((c) => {
    //       const items = products.filter((p) => p.category.name === c);
    //       allItems.push({
    //         title: c,
    //         items: items,
    //         image: items[0].category.image,
    //         id: items[0].category.id,
    //       });
    //     });

    //     const sortOrder = allItems.sort(function (a, b) {
    //       if (a.title < b.title) {
    //         return -1;
    //       }
    //       if (a.title > b.title) {
    //         return 1;
    //       }
    //       return 0;
    //     });
    //     setCategories(sortOrder);
    //     setProducts(json.data.products);
    //     setMenuProducts(sortOrder[0].items);
    //   return { message: "success" };
    // })
    // .catch((err) => {
    //   if (err.response?.data?.status === "unauthorized") {
    //     localStorage.clear();
    //     return { message: "unauthorized" };
    //   }
    //   if (err?.response?.data?.message === "You have to set current address")
    //     this.state.setAddressModal(true);
    //   return { message: "failed" };
    // });
    return { message: "success" };
  };

  getAllProducts = async () => {
    if (localStorage.token && localStorage.currentAddress) {
      this.state.setFetchingAllProducts(true);
      fetch(this.baseURL + `/products?page=${1}&per_page=${3000}`, {
        method: "GET",
        headers: this.headers(),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.state.setAllProducts(json.data.products);
          this.state.setFetchingAllProducts(false);
          return { message: "success" };
        })
        .catch((err) => {
          return { message: "failed" };
        });
    } else if (!localStorage.token) {
      this.state.setFetchingAllProducts(true);
      const add = localStorage.currentAddress
        ? JSON.parse(localStorage.currentAddress)
        : {};
      fetch(
        this.baseURL +
          `/products/location/${add?.id}/products?page=1&per_page=${3000}`,
        {
          method: "GET",
          headers: this.headers(),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.state.setAllProducts(json.data.products);
          this.state.setFetchingAllProducts(false);
          return { message: "success" };
        })
        .catch((err) => {
          return { message: "failed" };
        });
    } else {
      return;
    }
  };
  postAddress = async (data) => {
    try {
      const res = await axios.post(this.baseURL + "/addresses/new", data, {
        headers: this.headers(),
      });
      const response = await axios.post(
        this.baseURL + "/addresses/set_current",
        { user: { current_address_id: res.data.data.id } },
        { headers: this.headers() }
      );
      return { message: "success", data: response.data.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  updateAddress = async (data, id) => {
    try {
      const res = await axios.put(this.baseURL + `/addresses/${id}`, data, {
        headers: this.headers(),
      });
      const response = await axios.post(
        this.baseURL + "/addresses/set_current",
        {
          user: {
            current_address_id: res.data.data.id,
          },
        },
        {
          headers: this.headers(),
        }
      );
      toast.success("Address updated ");
      return { message: "success", data: response.data.data };
    } catch (err) {
      toast.error("Failed to update address");
      return { message: "failed" };
    }
  };

  deletAddress = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/addresses/${id}`, {
        method: "DELETE",
        headers: this.headers(),
      });
      const response = await res.json();
      toast.success("Adddress deleted");
      return { message: "success", data: response.data };
    } catch (err) {
      toast.error("Failed to delete address");
      return { message: "failed" };
    }
  };
  updateProfile = async (data) => {
    try {
      const res = await fetch(this.baseURL + `/user/profile`, {
        method: "PUT",
        body: JSON.stringify({
          user: data,
        }),
        headers: this.headers(),
      });
      const response = await res.json();
      localStorage.setItem("user", JSON.stringify(response.data));
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  deleteAccount = async () => {
    try {
      const res = await fetch(this.baseURL + `/user/disable`, {
        method: "DELETE",
        headers: this.headers(),
      });
      await res.json();
      return { message: "success" };
    } catch (err) {
      toast.error("Failed to delete address");
      return { message: "failed" };
    }
  };
  getProduct = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/products/${id}`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      if (err.response.data.message === "You have to set current address")
        this.state.setAddressModal(true);
      else return { message: "failed" };
    }
  };
  getProductByLocation = async (location, id) => {
    try {
      const res = await fetch(
        this.baseURL + `/products/location/${location}/products/${id}`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      if (err.response.data.message === "You have to set current address")
        this.state.setAddressModal(true);
      else return { message: "failed" };
    }
  };
  getPorpularDishes = async (id) => {
    const location = localStorage.currentAddress
      ? JSON.parse(localStorage.currentAddress)
      : {};
    try {
      const res = await fetch(
        this.baseURL +
          `/products/location/${id ? id : location?.id}/products/popular`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getDealsOfTheDay = async () => {
    const location = JSON.parse(localStorage.currentAddress);
    try {
      const res = await fetch(
        this.baseURL + `/products/location/${location.id}/products/deals`,
        {
          method: "GET",
          headers: this.headers(),
        }
      );
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getCart = async () => {
    try {
      if (localStorage.localCart) {
        const localCart = JSON.parse(localStorage.localCart);
        localCart.items.forEach(async (item) => {
          await this.addToCart(item.product.id, item.quantity);
        });
      }
      localStorage.removeItem("localCart");
      const res = await fetch(this.baseURL + `/cart`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();

      if (response.status && response.status === "unauthorized") {
        localStorage.clear();
        return { message: "unauthorized" };
      }
      this.flow.setCart(response.data);
      // const cart = response.data
      // localStorage.setItem("currency", cart.currency);

      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getLocalCart = () => {
    if (!localStorage.token) {
      if (localStorage.localCart) {
        // const cart = JSON.parse(localStorage.localCart());
        this.flow.setCart(JSON.parse(localStorage.localCart));
        // localStorage.setItem("currency", cart.currency);
        return { message: "success", data: JSON.parse(localStorage.localCart) };
      } else {
        const location = localStorage.currentAddress
          ? JSON.parse(localStorage.currentAddress)
          : {};
        const cart = {
          total: 0,
          currency: location?.currency,
          items: [],
        };
        localStorage.setItem("localCart", JSON.stringify(cart));
        this.flow.setCart(cart);
        // localStorage.setItem("currency", cart.currency);
        return { message: "success", data: cart };
      }
    }
  };
  addLocalToMainCart = async (id, quantity, spice) => {
    try {
      const res = await fetch(this.baseURL + `/cart/add`, {
        method: "POST",
        body: spice
          ? JSON.stringify({
              order_item: {
                product_id: id,
                quantity: quantity,
                spice_level: spice,
              },
            })
          : JSON.stringify({
              order_item: {
                product_id: id,
                quantity: quantity,
              },
            }),
        headers: this.headers(),
      });
      const response = await res.json();
      await this.getCart();

      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  addToCart = async (id, quantity) => {
    try {
      const res = await fetch(this.baseURL + `/cart/add`, {
        method: "POST",
        body: JSON.stringify({
          order_item: {
            product_id: id,
            quantity: quantity,
          },
        }),
        headers: this.headers(),
      });
      const response = await res.json();
      await this.getCart();
      toast.success("Added to cart");
      return { message: "success", data: response.data };
    } catch (err) {
      toast.error("Failed to add to cart");
      return { message: "failed" };
    }
  };
  addDiscountToCart = async (code) => {
    try {
      const res = await fetch(this.baseURL + `/cart/discount/${code}`, {
        method: "POST",
        headers: this.headers(),
      });
      const response = await res.json();
      await this.getCart();
      if (response.status === "success") {
        toast.success("Discount Added to cart");
        return { message: "success", data: response.message };
      } else {
        return { message: "invalid", data: response.message };
      }
    } catch (err) {
      toast.error("Discount not valid");
      return { message: "failed" };
    }
  };
  addToLocalCart = (product, quantity, spice) => {
    const localCart = localStorage.localCart
      ? JSON.parse(localStorage.localCart)
      : { currency: "NGN", items: [], total: 0 };
    // const location = JSON.parse(localStorage.currentAddress);
    const items = localCart?.items;
    const cart = items.find((item) => item?.product.id === product?.id);
    if (!cart) {
      spice
        ? items.push({
            product: product,
            subtotal: parseInt(product?.product_price?.price),
            quantity: quantity,
            spice_level: spice,
          })
        : items.push({
            product: product,
            subtotal: parseInt(product?.product_price?.price),
            quantity: quantity,
          });
      let total = 0;
      items.forEach((f) => {
        let t = f.subtotal * f.quantity;
        total = total + t;
      });
      localStorage.setItem(
        "localCart",
        JSON.stringify({
          items: items,
          total: total,
          currency: product?.product_price?.currency,
        })
      );
      toast.success("Added to cart");
    } else {
      const filter = items.filter(
        (item) => item.product.id !== cart?.product?.id
      );
      spice
        ? filter.push({
            product: product,
            subtotal: parseInt(product?.product_price?.price),
            quantity: quantity,
            spice_level: spice,
          })
        : filter.push({
            product: product,
            subtotal: parseInt(product?.product_price?.price),
            quantity: quantity,
          });
      let total = 0;
      filter.forEach((f) => {
        let t = f.subtotal * f.quantity;
        total = total + t;
      });
      localStorage.setItem(
        "localCart",
        JSON.stringify({
          items: filter,
          total: total,
          currency: product.product_price.currency,
        })
      );
    }
  };
  updateCart = async (id, quantity, spice) => {
    try {
      const res = await fetch(this.baseURL + `/cart/item/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          order_item: spice
            ? {
                spice_level: spice,
                quantity: quantity,
              }
            : {
                quantity: quantity,
              },
        }),
        headers: this.headers(),
      });
      const response = await res.json();
      await this.getCart();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  deleteCartItem = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/cart/item/${id}`, {
        method: "DELETE",
        headers: this.headers(),
      });
      await res.json();
      await this.getCart();
      toast.success("Item deleted");
    } catch (err) {
      toast.error("Failed to delete item");
    }
  };

  deleteLocalCart = (product) => {
    const localCart = JSON.parse(localStorage.localCart);
    const filter = localCart.items.filter(
      (item) => item.product.id !== product.id
    );
    const location = localStorage.currentAddress;
    let total = 0;
    filter.forEach((f) => {
      let t = f.subtotal * f.quantity;
      total = total + t;
    });
    localStorage.setItem(
      "localCart",
      JSON.stringify({
        items: filter,
        total: total,
        currency: location.currency,
      })
    );
    toast.success("Item deleted");
  };
  updateRecipient = async (form) => {
    try {
      const res = await fetch(this.baseURL + `/cart/recipient`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: this.headers(),
      });
      await res.json();
      return { message: "success" };
    } catch (err) {
      toast.error("failed to update recipient");
      return { message: "failed" };
    }
  };
  addGuestUserAsRecipient = async (form) => {
    try {
      const res = await fetch(this.baseURL + `/auth/temp/signup`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: this.headers(),
      });
      const response = await res.json();

      localStorage.setItem("guestUser", true);
      localStorage.setItem("token", response.data.auth.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      const location = JSON.parse(localStorage.currentAddress);
      await this.setCurrentLocation(location.location_id);
      return { message: "success" };
      // }
    } catch (err) {
      toast.error("failed to update recipient");
      return { message: "failed" };
    }
  };
  initiatePayment = async () => {
    try {
      const res = await fetch(this.baseURL + `/cart/payment/initiate`, {
        method: "POST",
        headers: this.headers(),
      });
      const response = await res.json();

      localStorage.removeItem("hasRecipient");
      localStorage.removeItem("hasRecipientAddress");

      setTimeout(() => {
        window.location.href = response?.payment?.flutterwave?.checkout_url;
      }, 30);
      return { message: "success", data: response.payment };
    } catch (err) {
      toast.error("Something went wrong please try again");
      return { message: "failed" };
    }
  };
  getFavourites = async () => {
    try {
      const res = await fetch(this.baseURL + "/favourites", {
        method: "GET",
        headers: this.headers(),
      });
      const data = await res.json();
      if (data.status && data.status === "unauthorized") {
        localStorage.clear();
        return { message: "unauthorized" };
      }
      const products = data?.data?.products;
      const categories = [];

      products.forEach((p) => {
        if (!categories.includes(p.category.name))
          categories.push(p.category.name);
      });
      const cat = categories;
      let allItems = [];
      cat.forEach((c) => {
        const items = products.filter((p) => p.category.name === c);
        allItems.push({
          title: c,
          items: items,
          image: items[0].category.image,
          id: items[0].category.id,
        });
      });
      this.state.setRawFavourites(products);
      this.state.setFavourites(allItems);
      return { message: "success", data: allItems };
    } catch (err) {
      return { message: "failed" };
    }
  };
  addAsFavourite = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/products/${id}/like`, {
        method: "POST",
        headers: this.headers(),
      });
      await res.json();
      this.getFavourites();
      toast.success("Added as favourite");
      return { message: "success" };
    } catch (err) {
      toast.error("Failed to add as favourite");
      return { message: "failed" };
    }
  };
  removeFavourite = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/products/${id}/like`, {
        method: "DELETE",
        headers: this.headers(),
      });
      await res.json();

      this.getFavourites();
      toast.success("Removed from favourites");
      return { message: "success" };
    } catch (err) {
      toast.success("Failed to remove from favourites");
      return { message: "failed" };
    }
  };
  verifyPayment = async (ref) => {
    try {
      const res = await fetch(
        this.baseURL + `/cart/payment/verify/flutter/${ref}`,
        {
          method: "POST",
          headers: this.headers(),
        }
      );
      const response = await res.json();

      return { message: "success", data: response.data };
    } catch (err) {
      toast.error("Failed to verify transaction");
      return { message: "failed" };
    }
  };
  getAllOrders = async () => {
    try {
      const res = await fetch(this.baseURL + `/orders`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      toast.error("Something went wrong please try again");
      return { message: "failed" };
    }
  };
  getOrder = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/orders/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.token}`,
        },
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      toast.error("Something went wrong please try again");
      return { message: "failed" };
    }
  };
  getRidersLocation = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/orders/${id}/rider/location`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getRider = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/orders/${id}/rider`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getRiderChats = async (id) => {
    try {
      const res = await fetch(this.baseURL + `/orders/${id}/chat/messages`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  sendRiderChat = async (id, message) => {
    try {
      const res = await fetch(this.baseURL + `/orders/${id}/chat/send`, {
        method: "POST",
        headers: this.headers(),
        body: JSON.stringify({
          message: {
            sender_type: "user",
            message: message,
          },
        }),
      });
      const response = await res.json();
      // this.getRiderChats(id)
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getReviews = async () => {
    try {
      const res = await fetch(this.baseURL + `/reviews`, {
        method: "GET",
        headers: this.headers(),
      });
      const response = await res.json();
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  attachDiscount = async (code) => {
    try {
      const res = await fetch(this.baseURL + `/cart/discount/${code}`, {
        method: "POST",
        headers: this.headers(),
      });
      const response = await res.json();
      if (response.status === "not found") {
        toast.error(response.message);
      } else toast.error("Discount saved successfully");
      return { message: "success", data: response.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
}
