import React from "react";
import Category from "../ui/Category";
import { BsPlus } from "react-icons/bs";
import Dishes from "./Dishes";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";

const Catalogue = () => {
  const context = useDataContext();
  const { categories } = context;
  return (
    <div className="w-full flex flex-col items-center">
      <span className="max-w-[433px] m-auto text-center mini-tablet:text-[48px] mobile:text-[32px] text-[24px] mini-tablet:leading-[72px] mobile:leading-[48px] leading-[36px] text-[#ffffff]">
        Our Catalogue <br /> of Delicious Meals
      </span>
      <div className="max-w-[1288px] px-[30px] w-full overflow-auto m-auto">
        <div className="flex w-full gap-[29px] z-10 max-tablet:justify-center justify-start mt-[24px] mb-[44px]">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={29}
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
                width: 360,
                spaceBetween: 15,
              },
              550: {
                slidesPerView: 3,
                width: 640,
                spaceBetween: 20,
              },
              964: {
                slidesPerView: 4,
                width: 920,
                spaceBetween: 29,
              },
            }}
          >
            {categories?.slice(0, 4)?.map((c, n) => {
              return (
                <SwiperSlide key={n}>
                  <div className="">
                    <Category
                      img={c.image.url}
                      title={c.title}
                      minW={146}
                      w={185}
                    />
                  </div>
                </SwiperSlide>
              );
            })}

            <SwiperSlide>
              <Category Icon={BsPlus} title="And More" minW={146} w={175} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Dishes />
    </div>
  );
};

export default Catalogue;
