import React from "react";
import { BsChevronRight } from "react-icons/bs";
// import chat from "../../../assets/icons/l-chat.svg";

const MobilNavigator = ({ active, setActive }) => {
  return (
    <div className="flex flex-col gap-[24px] pb-10">
      <div className="transparent_bg4 rounded-[24px] flex flex-col items-center">
        <div
          onClick={() => setActive("FAQs")}
          className="flex cursor-pointer w-full gap-[10px] border-b border-[rgba(255,255,255,0.1)] p-[24px] pb-[34px] items-center justify-between"
        >
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#fff]">
              FAQs
            </span>
            <BsChevronRight className="text-[#ffffff] text-[20px]" />
          </div>
        </div>

        {/* <div
          onClick={() => setActive("Legal")}
          className="flex w-full cursor-pointer gap-[10px] p-[24px] py-[34px] items-center justify-between"
        >
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#fff]">
              Legal
            </span>
            <BsChevronRight className="text-[#ffffff] text-[20px]" />
          </div>
        </div> */}

        {/* <div
          onClick={() => setActive("Live Support")}
          className="flex w-full p-[24px] pt-[34px] cursor-pointer border-t border-[rgba(255,255,255,0.1)] gap-[10px] items-center justify-between"
        >
          <img className="w-[20pxpx] h-[20px] rounded-full" src={chat} alt="" />
          <div className="flex w-full rounded-[24px] items-center justify-between">
            <span className="text-[18px] leading-[27px] font-[400] text-[#fff]">
              Live Support
            </span>
            <BsChevronRight className="text-[#ffffff] text-[20px]" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MobilNavigator;
