import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const Accordion = ({q, ans, last}) => {
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!toggle)
      setTimeout(() => {
        setOpen(false);
      }, 200);
  }, [toggle]);

  return (
    <div className="w-full h-fit overflow-hidden transition-all ease-in-out duration-500">
      <div
        onClick={() => {
          setToggle(!toggle);
          setOpen(true);
        }}
        className="flex cursor-pointer justify-between pb-[16px] items-center text-[18px] leading-[27px] font-[400] text-[#ffffff] opacity-[0.85]"
      >
        <span className="">{q}</span>
        <MdKeyboardArrowDown
          className={`transition-transform ease-in-out duration-500 ${
            toggle ? "rotate-180" : "rotate-0"
          } `}
        />
      </div>
      <div
        className={`overflow-hidden pb-[16px] border-b-[0.65px] ${last? " border-none":"border-b-[#ffffff17]"} transition-all ease-in-out duration-500 `}
      >
        <div
          className={`transition-all ease-in-out  duration-500 ${
            toggle ? " translate-y-0 opacity-1" : "translate-y-[-100px]"
          }`}
        >
          {open && (
            <span className="text-[16px] leading-[24px] font-[300] text-[#ffffff] opacity-[0.65]">
              {ans}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
