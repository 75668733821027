import React from "react";
import { BsDot, BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useProduct } from "../../hooks/useProduct";
import watch from "../../../assets/icons/watch.svg";
import Loader from "./Loader";
import Images from "./Images";
import Prices from "./Prices";
import { useFlowContext } from "../../../middle-ware/StateContext/FlowContext";

const MobileProductCard = ({ img, title, price, currency, product }) => {
  const navigate = useNavigate();
  const { cart, loading, addToCart } = useProduct(product);
  
  return (
    <div className="h-[298px] relative productCard overflow-hidden transparent_bg backdrop-blur-[15.7px] rounded-[18px] gap-[16px] bg-blue-100 flex flex-col items-center">
      <span
        onClick={() =>{
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          navigate(
            `/product/${product?.category?.name}/${product?.title}/${product?.id}`
          )
        }}
      >
        <Images
          classes={
            "w-full rounded-[18px] h-[298px] absolute left-0 right-0 z-[-999]  "
          }
          url={img}
          width={"100%"}
          height={"298px"}
          radius={"100%"}
        />
      </span>
      <div className="absolute z-10 w-full p-[16px] productCard bottom-0 rounded-b-[16px] flex flex-col gap-[7.83px]">
        <div className="flex flex-wrap w-full justify-between gap-[14.6px]">
          <span
            onClick={() =>
              navigate(
                `/product/${product?.category?.name}/${product?.title}/${product?.id}`
              )
            }
            className=" whitespace-nowrap cursor-pointer truncate text-[17.5px] leading-[26px] font-[500] text-[#ffffff] opacity-[0.85] "
          >
            {title}
          </span>
          <span className="flex text-[14px] text-[#ffffff] items-center gap-[5px]">
            <BsStarFill className="text-[10.7px] text-[#ffa503]" />
            4.8<span className="text-[10px] opacity-[0.65]">(245)</span>
          </span>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-wrap text-[#FFfFFF] items-center gap-[8px] opacity-[0.85]">
            <Prices
              amount={price}
              currency={currency}
              height={14}
              classes={
                "text-[14px] text-[#ffffff] opacity-[0.85] leading-[22px] font-[600]"
              }
            />
            <BsDot className="text-[14px]" />
            <img src={watch} alt="" />
          </div>
          <span
            onClick={() => addToCart()}
            className={` ${
              cart && cart?.length > 0
                ? "text-[#ffffff] bg-[#ad0010] "
                : "text-[#ff0018]"
            } transition-all ease-in-out duration-500 hover:bg-[#ff0018] hover:text-[#ffffff] cursor-pointer text-[10.7px] border border-[#ff0018] p-[7px] rounded-[100px]`}
          >
            {loading ? (
              <Loader color={"#ffffff"} size={[20, 20]} />
            ) : cart && cart?.length > 0 ? (
              "In Cart"
            ) : (
              "Add To Cart"
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileProductCard;
