import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import home from "../../../../assets/icons/l-home2.png";
import Footer from "../../footer/Footer";
import { useNavbar } from "../../../hooks/useNavbar";
import { ForFriendFloater } from "../../navbar/utils/ForFriendFloater";
// import { useNavbar as Hook } from "../../navbar/hooks/useNav";
// import FloatingCart from "../../navbar/utils/FloatingCart";

const LayoutTwo = ({ LeftComponent, RightComponent, title, Path }) => {
  const navigate = useNavigate();
  // const { numOfItems } = Hook();

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="max-w-[1440px] w-full m-auto max-mobile:px-[35px] px-[16px] ">
      {useNavbar()}
      <div className="max-w-[1288px] w-full m-auto py-[24px] pb-[35px]">
        <div className="w-full grid grid-cols-12">
          <div className=" tablet:col-span-8 col-span-12 w-full tablet:border-r border-[#ffffff29] tablet:pr-[24px] ">
            <div className="flex gap-[18px] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] text-[#ffffff] font-[400] items-center">
              <img src={home} className="w-[20px] h-[19.9px]" alt="" />
              <span className="mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] text-[#ffffff] ">
                /
              </span>
              <span onClick={() => title === "Menu" && navigate("/menu")}>
                {title}
              </span>
              {Path && Path}
            </div>
            <div className="w-full pt-[32px]">{LeftComponent}</div>
          </div>
          <div className="tablet:col-span-4 tablet:block hidden ">
            {RightComponent}
          </div>
        </div>
      </div>
      <Footer />
      <div className="fixed bottom-[50px] z-[999999999] flex flex-col gap-3 mobile:right-[50px] right-[25px] w-[50px] h-fit">
        <ForFriendFloater />
      </div>
    </div>
  );
};

export default LayoutTwo;
