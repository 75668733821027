import React, { useState } from "react";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import eyeSlash from "../../assets/icons/eye-slash.png";
import caution from "../../assets/icons/caution.svg";
import check from "../../assets/icons/circlecheck.svg";
import eye from "../../assets/icons/eye.png";
import AuthLayout from "../components/ui/layouts/AuthLayout";
import Loader from "../components/ui/Loader";
import Input from "../components/ui/input/Input";
import client from "../../middle-ware/axiosInstance";
import { useAuthContext } from "../../middle-ware/StateContext/AuthContext";
import PhoneInput from "../components/ui/input/PhoneInput";
// import { useDataContext } from "../../middle-ware/StateContext/DataContext";

const Singup = () => {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    cf_password: "",
    medium: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    cf_password: "",
    medium: "",
  });

  const [emails, setEmails] = useState(true);
  const [passwordView, setPasswordView] = useState(false);
  const [cfPasswordView, setCfPasswordView] = useState(false);
  const [terms, acceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termError, setTermError] = useState(false);

  const auth = useAuthContext();

  const navigate = useNavigate();

  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};
  const country_code = location?.state === "London" ? "+44" : "+234";
  const [phoneType, setPhonetype] = useState(country_code);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkPhone = (value) => {
    const arr = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
    if (arr.includes(value[value.length - 1])) return true;
    else return false;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const phoneChecker = checkPhone(value);
    if (name === "phone") {
      if (phoneChecker || form.phone.length === 1)
        setForm({ ...form, [name]: value });
    } else setForm({ ...form, [name]: value });
    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, [name]: "Enter a valid email adddress" });
    } else if (name === "phone" && value.length < 10 && phoneType === "+234") {
      setErrors({
        ...errors,
        [name]: "Phone number should not be less than 10 characters",
      });
    } else if (name === "password" && value.length < 8) {
      setErrors({
        ...errors,
        [name]: "Passwords should be at least 8 characters",
      });
    } else if (name === "cf_password" && form.password !== value) {
      setErrors({ ...errors, [name]: "Password do not match" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const validateForm = () => {
    const { first_name, last_name, email, phone, password, cf_password } = form;
    const error = {};
    if (first_name === "") error.first_name = "Required*";
    if (last_name === "") error.last_name = "Required*";
    if (email === "") error.email = "Required*";
    else if (email !== "" && !validateEmail(email))
      error.email = "Enter a valid email adddress";
    if (phone === "") error.phone = "Required*";
    else if (phone.length < 10 && phoneType === "+234")
      error.phone = "Phone number should not be less than 10 characters";
    if (password === "") error.password = "Required*";
    else if (password.length < 8)
      error.password = "Passwords should be at least 8 characters";
    if (cf_password === "") error.cf_password = "Required*";
    else if (cf_password !== "" && password !== cf_password)
      error.cf_password = "Password do not match";
    return error;
  };
  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (!terms) setTermError(true);
    else {
      try {
        setLoading(true);
        await client.post("/auth/signup", {
          user: {
            first_name: form.first_name,
            last_name: form.last_name,
            phone: phoneType + form.phone,
            email: form.email,
            receive_email: emails,
            terms: terms,
            password: form.password,
            password_confirmation: form.cf_password,
          },
        });
        const res2 = await client.post("/auth/login", {
          email: form.email,
          password: form.password,
        });
        localStorage.setItem("token", res2.data.data.auth.token);
        localStorage.setItem("user", JSON.stringify(res2.data.data.user));
        setLoading(false);
        localStorage.removeItem("currentAddress");
        if (localStorage.localCart) {
          const localCart = JSON.parse(localStorage.localCart);
          localCart.items.forEach(async (item) => {
            await this.addToCart(item.product.id, item.quantity);
          });
        }
        localStorage.removeItem("localCart");
        localStorage.removeItem("guestUser");
        auth.setIsLoggedIn(true);
        navigate(-1);
        // context.setAddressModal(true);
      } catch (err) {
        if (err.response.status === 422) {
          if (err.response.data.data.email)
            toast.error("Email " + err.response.data.data.email[0]);
          if (err.response.data.data.phone)
            toast.error("Phone number " + err.response.data.data.phone[0]);
        } else {
          toast.error("Failed to create account, please try again");
        }
        setLoading(false);
      }
    }
  };

  return (
    <AuthLayout
      title={"Hey There!"}
      text={
        "We will use this information to provide you with benefits like faster checkout, order history and a personalized chow experience"
      }
    >
      <form className="flex-col flex gap-[32px] m-auto" action="">
        <Input
          value={form.first_name}
          type={"text"}
          onChange={handleChange}
          name="first_name"
          error={errors.first_name}
          label={"First Name"}
        />
        <Input
          value={form.last_name}
          type={"text"}
          onChange={handleChange}
          name="last_name"
          error={errors.last_name}
          label={"Last Name"}
        />
        <div className="flex flex-col gap-[20px]">
          <Input
            value={form.email}
            type={"email"}
            onChange={handleChange}
            name="email"
            error={errors.email}
            label={"Email"}
          />
          <span
            onClick={() => setEmails(!emails)}
            className="flex gap-[16px] text-[#8F8F8F] transition-all ease-in-out duration-500 text-[16px] leading-[24px] items-center"
          >
            {emails ? (
              <BsCheckSquare className=" rounded-[4px] text-[#34C759] text-[20px]  transition-all ease-in-out duration-500" />
            ) : (
              <BsSquare className=" rounded-[4px] text-[#8F8F8F] text-[20px]  transition-all ease-in-out duration-500" />
            )}
            I would like to receive emails
          </span>
        </div>
        <PhoneInput
          value={form.phone}
          type={"text"}
          onChange={handleChange}
          name="phone"
          error={errors.phone}
          label={"Phone"}
          code={phoneType}
          setPhonetype={setPhonetype}
        />
        <Input
          value={form.medium}
          type={"text"}
          onChange={handleChange}
          name="medium"
          // error={errors.last_name}
          label={"How did you hear about us?"}
        />
        <Input
          value={form.password}
          type={passwordView ? "text" : "password"}
          onChange={handleChange}
          name="password"
          error={errors.password}
          label={"Password"}
        >
          {!passwordView ? (
            <img
              src={eye}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setPasswordView(true)}
            />
          ) : (
            <img
              src={eyeSlash}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setPasswordView(false)}
            />
          )}
        </Input>
        <Input
          value={form.cf_password}
          type={passwordView ? "text" : "password"}
          onChange={handleChange}
          name="cf_password"
          error={errors.cf_password}
          label={"Confirm Password"}
        >
          {!cfPasswordView ? (
            <img
              src={eye}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setCfPasswordView(true)}
            />
          ) : (
            <img
              src={eyeSlash}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setCfPasswordView(false)}
            />
          )}
        </Input>
        <div className="flex justify-center gap-[8px] items-center">
          {termError && <img src={caution} alt="" />}
          {terms ? (
            <img
              src={check}
              className="cursor-pointer w-[18px] transition-all ease-in-out duration-300"
              alt=""
              onClick={() => acceptTerms(!terms)}
            />
          ) : (
            <span
              onClick={() => {
                acceptTerms(!terms);
                setTermError(false);
              }}
              className={`w-[18px] transition-all ease-in-out duration-500 cursor-pointer h-[18px] ${
                terms && "bg-[#34C759]"
              } border ${!terms && "border-[#A3A3A3]"} rounded-full`}
            ></span>
          )}
          <span className="text-[18px] leading-[27px] text-[#A3A3A3]">
            I accept{" "}
            <span className="text-[#FF0018] cursor-pointer"> Terms</span> &{" "}
            <span className="text-[#FF0018] cursor-pointer"> Conditions</span>
          </span>
        </div>
        <span
          onClick={() => handleSubmit()}
          className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
        >
          {loading ? (
            <Loader size={[40, 40]} color="#ffffff" />
          ) : (
            "Create An Account"
          )}
        </span>
        <span className="text-[16px] text-center leading-[24px] font-[400] text-[#A3A3A3]">
          Have an account?{" "}
          <Link to={"/login"} className="text-[#FF0018] cursor-pointer">
            Sign In
          </Link>
        </span>
      </form>
    </AuthLayout>
  );
};

export default Singup;
