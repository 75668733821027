import React from "react";
import caution from "../../../../assets/icons/caution.svg";

const Input = ({
  value,
  type,
  onChange,
  name,
  error,
  label,
  light,
  children,
  isLondon,
  textarea,
}) => {
  return (
    <div>
      <div
        className={`flex transition-all capitalize w-full ease-in-out duration-500 flex-col py-[8px] border-b-[0.65px] ${
          light ? "border-[#ffffff9a]" : "mobile:border-[#00000081]"
        }`}
      >
        <span
          className={`text-[12px] transition-all ease-in-out duration-500 h-[18px] leading-[18px] ${
            light ? "text-[#FFFFFF]" : "mobile:text-[#000000] text-[#FFFFFF]"
          } opacity-[0.5]`}
        >
          {value !== "" && label}
        </span>
        <div className="flex gap-[16px] w-full items-center relative">
          {name === "phone" && (
            <span
              className={`${
                error !== ""
                  ? "text-[#FF0018]"
                  : light
                  ? "text-[#FFFFFF]"
                  : "mobile:text-[#000000] text-[#ffffff]"
              } mini-tablet:text-[20px] mobile:text-[18px] text-[16px] mini-tablet:leading-[30px] mobile:leading-[27px] leading-[24px] font-[400]`}
            >
              {isLondon ? "+44" : "+234"}
            </span>
          )}
          {textarea ? (
            <textarea
              type={type}
              placeholder={label}
              name={name}
              value={value}
              maxLength={name === "phone" ? 10 : name === "cvc" ? 3 : 59}
              minLength={0}
              onChange={onChange}
              className={`w-full ${
                name !== "email" &&
                name !== "password" &&
                name !== "cf_password" &&
                "capitalize"
              } bg-transparent transition-all ease-in-out duration-500 outline-0 border-none autofill:bg-transparent placeholder:text-[20px] ${
                light
                  ? " placeholder:text-[#ffffff9a]"
                  : "mobile:placeholder:text-[#000000] placeholder:text-[#ffffff9a] "
              } placeholder:opacity-[0.65] mini-tablet:text-[20px] mobile:text-[18px] text-[16px] mini-tablet:leading-[30px] mobile:leading-[27px] leading-[24px] font-[400] ${
                error && error !== ""
                  ? "text-[#FF0018]"
                  : light
                  ? "text-[#FFFFFF]"
                  : "mobile:text-[#000000] text-[#FFFFFF]"
              }`}
            />
          ) : (
            <input
              type={type}
              placeholder={label}
              name={name}
              value={value}
              maxLength={name === "phone" ? 10 : name === "cvc" ? 3 : 59}
              minLength={0}
              onChange={onChange}
              className={`w-full ${
                name !== "email" &&
                name !== "password" &&
                name !== "cf_password" &&
                "capitalize"
              } bg-transparent transition-all ease-in-out duration-500 outline-0 border-none autofill:bg-transparent placeholder:text-[20px] ${
                light
                  ? " placeholder:text-[#ffffff9a]"
                  : "mobile:placeholder:text-[#000000] placeholder:text-[#ffffff9a] "
              } placeholder:opacity-[0.65] mini-tablet:text-[20px] mobile:text-[18px] text-[16px] mini-tablet:leading-[30px] mobile:leading-[27px] leading-[24px] font-[400] ${
                error && error !== ""
                  ? "text-[#FF0018]"
                  : light
                  ? "text-[#FFFFFF]"
                  : "mobile:text-[#000000] text-[#FFFFFF]"
              }`}
            />
          )}
          <div className=" absolute top-0 right-0 bottom-0 flex items-center">
            {children}
          </div>
        </div>
      </div>
      {error && (
        <span
          className={`${
            error !== "" ? "text-[#FF0018] opacity-[1]" : "opacity-0"
          } text-[12px] leading-[18px] font-[400] h-[18px]`}
        >
          <span className="flex gap-[8px] items-center mt-[8px] ">
            <img src={caution} alt="" /> {error}
          </span>
        </span>
      )}
    </div>
  );
};

export default Input;
