import React from "react";
import { Link } from "react-router-dom";

const PageLink = ({ path, children, to, background, style }) => {
  const route = window.location.href;
  return (
    <Link
      style={style ? style : {}}
      to={to ? `/${to}` : `/${path}`}
      className={`decoration-inherit rounded-[100px] whitespace-nowrap capitalize no-underline py-[18px] max-tablet:mr-[72px] mr-[30px] leading-[27px] text-[18px] font-[400px] ${
        route.includes(path) ? "text-[#FF0018]" : "text-[#a3a3a3] "
      } cursor-pointer`}
    >
      {path}
      {children}
    </Link>
  );
};

export default PageLink;
