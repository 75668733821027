import React from "react";
import Skeleton from "react-loading-skeleton";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";
import { useFlowContext } from "../../../middle-ware/StateContext/FlowContext";

const Prices = ({ currency, amount, classes, height }) => {
  const { hotReload } = useDataContext();
  const { reloadMenu } = useFlowContext();
  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : { currency: "NGN" };
  const toPrice = () => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: location?.currency,
    });
    return formatter.format(amount);
  };

  if (hotReload || reloadMenu) {
    return (
      <div className={" h-[20px]"}>
        <Skeleton
          count={1}
          baseColor="#ffffff2c"
          width={80}
          height={height}
          borderRadius={5}
        />
      </div>
    );
  }

  return <span className={classes}>{toPrice()}</span>;
};

export default Prices;
