export const policy = [
  {
    title: "PRIVACY POLICY",
    paragraphs: [
      "We understand that by choosing Eve After Dark to deliver your goods, you’ve placed a great deal of trust in us. We also understand that you want the personal information you give us to be kept private as well as secure. To reassure you that we feel the same way, here’s how we will and will not use the personal information you give us.",
      "Note : Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.",
    ],
  },
  {
    subTitle: "Collection of Personally Identifiable Information",
    paragraphs: [
      "We collect personally identifiable information (email address, name, phone number, etc.) from you when you set up an account with Eve After Dark. We may use this information to send you suggestions about promotions and offers based on your interest.",
    ],
  },
  {
    subTitle: "Use of Demographic and Profile Data",
    paragraphs: [
      "We use personal information to provide the services you request. You can opt out of marketing and other promotional material we send to you. We use your personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect fees owed; measure consumer interest in our products and services, inform you about online and offline offers, products, services, and updates; customize your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection. In our efforts to continually improve our product and service offerings, we collect and analyse demographic and profile data about our users’ activity on our website. We identify and use your IP address to help diagnose problems with our server, and to administer our website. Your IP address is also used to help identify you and to gather broad demographic information. We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like location, local government area, age, or income level). We use this data to tailor your experience at our site, providing you with content that we think you might be interested in–and to display content according to your preferences.",
    ],
  },
  {
    subTitle: "Cookies",
    paragraphs: [
      "A “cookie” is a small piece of information stored by a Web server on a Web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. Eve After Dark places both permanent and temporary cookies in your computer’s hard drive. Eve After Dark‘s cookies do not contain any of your personally identifiable information.",
    ],
  },
  {
    subTitle: "Sharing of personal information",
    paragraphs: [
      "We may share personal information with our other corporate entities and affiliates to: help detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts to prevent abuse of our services; and to facilitate joint or co-branded services that you request where such services are provided by more than one corporate entity. Those entities and affiliates may not market to you as a result of such sharing unless you explicitly opt-in.",
      "We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.",
      "Eve After Dark and its affiliates will share some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity. Should such a transaction occur, that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.",
    ],
  },
  {
    subTitle: "Links to Other Sites",
    paragraphs: [
      "Our site links to other websites that may collect personally identifiable information about you. Eve After Dark is not responsible for the privacy practices or the content of those linked websites.",
    ],
  },
  {
    subTitle: "Security Precautions",
    paragraphs: [
      "Our site has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to strict security guidelines, protecting it against unauthorized access.",
    ],
  },
  {
    subTitle: "Choice/Opt-Out",
    paragraphs: [
      "Eve After Dark provides all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us on behalf of our partners, and from us in general, after setting up an account. If you want to remove your contact information from all Eve After Dark lists and newsletters, please click on the unsubscribe button in our mails.",
    ],
  },
  {
    subTitle: "Advertisements on Eve After Dark",
    paragraphs: [
      "We use third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.",
    ],
  },
  {
    title: "GENERAL TERMS AND CONDITIONS",
    paragraphs: [
      "By using the Eve After Dark website services and tools (the “Mobile application and Website”), you (“you” or the “End User”) agree to the terms and conditions that we (“Eve After Dark herein referred to as Eve After Dark “) have provided. If you do not agree with the outlined terms and conditions (the “Terms of Use” or “Agreement”), please refrain from using the Site and its services.",
      "For the purpose of these terms of use, wherever the context requires, ‘You’ shall mean any natural or legal person who has agreed to make use of the site.",
      "The Site reserves the right, to change, modify, add, or remove portions of these Terms and Conditions of use at any time.",
      "Please check these Terms and Conditions regularly for updates as changes will be made on the website with no notice provided.",
    ],
  },
  {
    list: [
      {
        title: "GENERAL",
        items: [
          "This Agreement sets forth the terms and conditions that apply to the use of this Site by the End User. By using this Site (other than to read this Agreement for the first time), End User agrees to comply with all of the terms and conditions hereof. The right to use this Site is personal to End-User and is not transferable to any other person or entity.",
          "End User shall be responsible for protecting the confidentiality of End User’s password(s), if any. End User acknowledges that, although the Internet is often a secure environment, sometimes there are interruptions in service or events that are beyond the control of Eve After Dark, and Eve After Dark shall not be responsible for any data lost while transmitting information on the Internet. While it is Eve After Dark’ objective to make the Site and its tools accessible 24 hours per day, 7 days per week, the Site and its tools accessible may be unavailable from time to time for any reason including, without limitation, routine maintenance. You understand and acknowledge that due to circumstances both within and outside of the control of Eve After Dark access to the Site and its tools may be interrupted, suspended or terminated from time to time. Eve After Dark shall have the right at any time to change or discontinue any aspect or feature of Eve After Dark, including, but not limited to, content, hours of availability and equipment needed for access or use. Further, Eve After Dark may discontinue disseminating any portion of information or category of information, may change or eliminate any transmission method and may change transmission speeds or other signal characteristics.",
          "We take allergy information seriously as to this providing prior word or notice must be given while placing your orders which we will do our best to cater for",
        ],
      },
      {
        title: "USER SUBMISSIONS AND PRIVACY",
        items: [
          "Anything that you submit to the Site and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, “Submissions”) will become our sole and exclusive property and shall not be returned to you.",
          "In addition to the rights applicable to any Submission, when you post comments or reviews to the Site and its tools, you also grant us the right to use the name that you submit, in connection with such review, comment, or other content.",
          "We view protection of your privacy as a very important principle. We understand clearly that you and Your Personal Information are one of our most important assets. We store and process Your Information on computers that may be protected by physical as well as reasonable technological security measures and procedures our current Privacy Policy is available at link. If you object to your Information being transferred or used in this way, please do not use the Site or it’s tools.",
        ],
      },
      {
        title: "ORDERING AND PRICING",
        items: [
          "Once you have found the products you are looking for, you can buy them online by clicking the ‘add to cart’ button for the selected products. You will then be directed to your ‘cart’. Remember, you can remove products from your cart or stop the ordering process at any time. Please note that all prices are shown in Nigerian Naira (NGN) and are inclusive of VAT, unless stated otherwise. The law places certain restrictions on transactions involving alcohol, and accordingly these products must be either ordered by you or delivered by us during normal licensing hours. If you have ordered alcohol from us and cannot be at home at your selected delivery time, it is your responsibility to ensure that a suitable person is there to receive the order. We operate a “Challenge 18” policy, which means that if the person receiving delivery of alcohol looks under 18, we will request identification. We may also request identification in relation to other age restricted products such as knives and health products. We may retain the products if proof of age or an alternative adult is not available.",
          "Please note that there are cases when an order cannot be processed for various reasons. The Site reserves the right to refuse or cancel any order for any reason at any given time. You may be asked to provide additional verifications or information, including but not limited to phone number and address, before we accept the order.",
          "We are determined to provide the most accurate pricing information on the Site to our users; however, errors may still occur, such as cases when the price of an item is not displayed correctly on the website. If we discover an error in the price of products you have ordered we will inform you as soon as possible and give you the option of reconfirming your order at the correct price or cancelling it. If we are unable to contact you, we will treat the order as cancelled. If you cancel and you have already paid for the products, you will receive a full refund. As such, we reserve the right to refuse or cancel any order.",
        ],
      },
      {
        title: "PURCHASE CONTRACTS",
        items: [
          "This website is operated by Eve After Dark (“we”, “us” or “our”). By using the Http://Theeveafterdark.com website or it’s tools you are bound by these Terms & Conditions. All use and purchases made on this website are governed by these Terms & Conditions at any time although the Terms & Conditions governing any given use or purchase will be those in effect at the date of your order or specific use. If you use or order products after we have published any changes you will be bound by those changes. Accordingly, you should check prior to each use or order to ensure that you understand the precise Terms & Conditions applicable to your site visit or purchase. To assist you in determining whether the Terms & Conditions have changed since your most recent order we will display the date when these Terms & Conditions were most recently updated.",
          "We sell products only to end-users.",
          "We will confirm your order whether placed directly by using the Http://Theeveafterdark.com website, the Eve After Dark Apps, emails, the customer care line, Whatsapp line, Social Media platforms and the live chat. This will occur either by telephone calls or messages via these mediums.",
          "Confirmation constitutes our acceptance of your order but order is completed when payment is received.",
          "To participate in our services, the customer must either complete the registration process or check out as a guest. As a guest, customer must provide relevant information such as full name, phone number and delivery address.",
        ],
      },
      {
        title: "AMENDMENT OF ORDERS AND CANCELLATION RIGHTS",
        items: [
          "Any orders placed may be amended or cancelled by the customer at no charge, provided that this is done at the point of confirmation or order delivery.",
          "Please note, for cancellation to be effective, an email should be sent to mail@Http://Theeveafterdark.com or a call placed through to ............................. or any other customer care line as stated on the website or app.",
          "Any orders that have not been cancelled prior to the expiry the aforementioned points; the customer would be charged the cancellation fee which is equivalent to the delivery fee.",
        ],
      },
      {
        title: "METHODS OF PAYMENT",
        items: [
          "Payment may be made on the website and app, by Cash, POS and online.",
          "We only accept Nigerian Naira as payment currency.",
        ],
      },
      {
        title: "DELIVERY",
        items: [
          "Delivery days and times are Mondays – Sundays 24 hours a day. Delivery will be made to the address specified by you when you register on the website or app. You have the ability to change this address through the “Account & Profile Details” feature on the website and app, and you must do so if you move locations so that we can deliver to the correct address, Although Change of address will affect the delivery fee. On recognized public holidays, delivery times are subject to change.",
          "We reserve the right to restrict deliveries in certain areas, and this includes the right to eliminate certain areas from our delivery schedule altogether.",
          "Eve After Dark will be responsible for the delivery of the products you have ordered to your address. Until the time that the products are delivered to you, the products shall remain the property of Eve After Dark (together with risk of loss and damage) will not pass to you until the products are delivered to the address provided that we have processed and received payment in full for the products.",
          "We take special care to endeavour that deliveries are made within a short delivery slot and accordingly, it is your responsibility to ensure that an appropriate person is available at the delivery address at all times during the delivery slot. We will ensure we call at least three times to confirm your availability before we deliver the meal. We may ask that an appropriate person signs for the products on delivery. If no one is at the address when the delivery is attempted the products will be retained by us. We will leave notification of delivery and will telephone to attempt to rearrange the delivery with a new delivery cost and in an event where have to repackage the food an additional charge will be incurred.",
          "Please note adverse weather conditions or other events outside of our reasonable control may result in the occasional late or cancelled delivery. If that is the case, we will endeavour to contact you as soon as we are able to in order to reschedule your delivery time and date.",
          "Should you fail to be present for your delivery, if you want it delivered to another location, a new cost of delivery would be added.",
          "Eve After Dark will ordinarily only make deliveries when an appropriate person is able to receive the delivery.",
        ],
      },
      {
        title: "DEFECTIVE GOODS AND DISSATISFACTION",
        items: [
          "We guarantee the quality of our products. You must inspect the products and notify us immediately by phoning our customer care center numbers or sending an email.",
          "In the event that the products collected by you are damaged, defective, faulty or do not match their description at the point of collection, you would be sent a replacement or a full refund will be made to you. You are advised to check your products for any defect, fault or damage before signing the delivery note. If you discover a genuine fault, defect or damage after delivery, you will be required to return the Products to us (together with all relevant packaging).",
        ],
      },
      {
        title: "RETURNS AND RETURNS APPROVAL",
        sub: {
          title: "You can return your product as long as:",
          items: [
            "- You contact Customer Support within 1 hour of delivery. This is because we cannot vouch for the customer’s storage methods after delivery has been made.",
            "- You have proof of purchase (online invoice).",
            "- The product must be in an ‘as new’ condition and returned in the original, undamaged packaging along with the accessories.",
            "- The product must not have been used or opened.",
            "- All sealed items must not be opened otherwise it would not be accepted on return. Eve After Dark will only accept unsealed items in cases where maggots, insects or moulds are found in the item.",
          ],
        },
        items: [
          "Return Approval of All returned items are subject to inspection and approval by the authorized representative of Eve After Dark. For a returned item to be approved for return, it must be in an ‘as new’ condition and returned in the original, undamaged packaging along with the accessories received with it. Items that are not returned in the above condition will be sent back to you and payment will not be refunded.",
          "Items returned without all accompanying accessories and packaging would be replaced with another of the same product, refunded to the account linked to your debit card (if you paid by debit card) or refunded by bank transfer.",
          "You will be asked to choose one of the following options:",
          "The shipping will be free if return is due to Incorrect Delivery. Otherwise, you’ll need to pay for the cost of shipping products back to Eve After Dark. The period of refund will not exceed 14 working days from receipt of returned products to us and the accompanying documents, if approval of your application for the return of the products is granted.",
        ],
      },
      {
        title: "CUSTOMER COMPLAINTS",
        items: [
          "Any Customer complaints should be addressed to the Eve After Dark Customer Care ................... or any other customer care line stated on the website. An email should be sent to mail@ http://Theeveafterdark.com.",
        ],
      },
      {
        title: "SPECIAL OFFERS PROMOTIONS AND COMPETITIONS",
        items: [
          "From time to time, and in our complete discretion, purchases of products may be subject to special offers. In the event that such a special offer applies to your purchase, the terms of such special offer shall be subject to these Terms & Conditions. If there is any conflict between the terms of a special offer and these Terms & Conditions, these Terms & Conditions shall prevail unless specifically excluded.",
          "We may change the terms of special offers, or withdraw them altogether, at any time and without prior notice. We will honour at the offer price any order placed by you before an offer ends, or is amended or withdrawn.",
          "We reserve the right to offer in our complete discretion different customers different special offers, promotions and the ability to enter different competitions.",
        ],
      },
      {
        title: "CANCELLATION OF DELIVERIES AND SUSPENSION OF YOUR ACCOUNT",
        items: [
          "We reserve the right to cancel your delivery at any time if we suspect fraud, have reason to believe you are in breach of these Terms and Conditions or any other terms and conditions relating to your order, or if there is an outstanding payment for any account registered at your address.",
          "In addition to our right to cancel your order, we reserve the right to suspend your account. Your account will remain suspended until you contact our call center on ......................................... and remedy any breaches which are capable of remedy, or provide any information reasonably requested by our call center advisers to enable them to reactivate your account.",
          "In the rare event that unforeseen operational or technical issues occur, we may need to cancel or rearrange delivery. If this happens, we will endeavor to contact you to arrange an alternative delivery date.",
        ],
      },
      {
        title: "COPYRIGHT AND TRADEMARKS",
        items: [
          "All intellectual property rights, whether registered or unregistered, in the Site, or apps information content on the Site and all the website design, including, but not limited to, text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain our property.",
          "The entire contents of the Site also are protected by copyright as a collective work under Nigeria copyright laws and international conventions. All rights are reserved.",
        ],
      },
      {
        title: "APPLICABLE LAW AND JURISDICTION",
        items: [
          "These Terms and Conditions shall be interpreted and governed by the laws in force in the Federal Republic of Nigeria. The place of jurisdiction shall be Abuja/Lagos, Nigeria.",
          "Unless otherwise specified, the material on Http://Theeveafterdark.com and the Eve After Dark app is presented solely for the purpose of sale in Nigeria unless otherwise stated. Eve After Dark makes no representation that materials on the site are appropriate or available for use in other locations/Countries other than Nigeria unless otherwise stated. Those who choose to access this site from other locations/Countries other than Nigeria do so on their own initiative and Eve After Dark is not responsible for supply of products/refund for the products ordered from other locations/Countries other than Nigeria, compliance with local laws, if and to the extent local laws are applicable.",
        ],
      },
      {
        title: "TERMINATION",
        items: [
          "In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions.",
          "Upon any termination of this Agreement, you shall immediately cease all access to and use of the Site and App and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Site and App in whole or in part.",
          "Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Site and App shall not be liable to you or to any other person as a result of any such suspension or termination.",
          "If you are dissatisfied with the Site or App or with any terms, conditions, rules, policies, guidelines, or practices of Eve After Dark in operating the Site or App, your sole and exclusive remedy is to discontinue using the Site or App.",
        ],
      },
      {
        title: "LIMITATION OF LIABILITY",
        items: [
          "IN NO EVENT SHALL EVE AFTER DARK BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THIS AGREEMENT, EVEN IF EVE AFTER DARK HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.",
          "Our liability for losses you suffer as a result of us breaking this agreement is strictly limited to the purchase price of the products you purchased.",
        ],
      },
      {
        title: "INDEMNITY",
        items: [
          "You shall indemnify and hold harmless Eve After Dark its owner’s, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of your breach of this Terms of Use, privacy Policy and other Policies, or your violation of any law, rules or regulations or the rights of a third party.",
        ],
      },
    ],
  },
];
