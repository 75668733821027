import React from "react";
import SidebarItem from "./SidebarItem";

const Sidebar = ({ active, setActive }) => {
  const list = ["FAQs"];

  return (
    <div className="w-[256px] h-full gap-[16px] border-r-[2px] border-[#ffffff1a] flex flex-col">
      {list.map((l, n) => (
        <SidebarItem
          item={l}
          key={n}
          active={active === l}
          setActive={setActive}
        />
      ))}
    </div>
  );
};

export default Sidebar;
