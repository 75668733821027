import React, { Fragment, useEffect } from "react";
import "./rangeSlider.scss";
import { useDataContext } from "../../../../middle-ware/StateContext/DataContext";

const PriceRangeSlider = ({
  min,
  max,
  setMin,
  setMax,
  rangeProducts,
  currency,
}) => {
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };
  
  const slideVal = localStorage["max-price"];
  const calculateSlide = () => {
    if (parseInt(slideVal) > 9999) {
      const devisor = slideVal[0] + "00";
      return parseInt(devisor);
    } else if (parseInt(slideVal) < 9999 && parseInt(slideVal) > 999) {
      const devisor = slideVal[0] + "0";
      return parseInt(devisor);
    } else if (parseInt(slideVal) < 999 && parseInt(slideVal) > 99) {
      const devisor = slideVal[0];
      return parseInt(devisor);
    } else if (parseInt(slideVal) < 99 && parseInt(slideVal) > 9) {
      const devisor = "0." + slideVal[0];
      return parseFloat(devisor);
    } else if (parseInt(slideVal) < 9 && parseInt(slideVal) > 1) {
      const devisor = "0.0" + slideVal[0];
      return parseFloat(devisor);
    } else return parseInt(slideVal[0]);
  };
  calculateSlide();
  const { fetchingProducts } = useDataContext();
  useEffect(() => {
    rangeProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);
  return (
    <Fragment>
      <div className="range-slider ">
        <input
          type="range"
          id="input-left"
          min={0}
          max={max}
          value={min}
          onChange={(e) => setMin(e.target.value)}
        />
        <input
          type="range"
          id="input-right"
          min={0}
          max={max}
          value={max}
          onChange={(e) => setMax(e.target.value)}
        />
        <div className="slider">
          <div className="track"></div>
          <div className="range"></div>
          <div
            className="thumb left_"
            style={{ left: `${min / calculateSlide()}%` }}
          ></div>
          <div
            className="thumb right_"
            style={{ left: `${max / calculateSlide()}%` }}
          ></div>
        </div>
      </div>
      <div className="mt-[16px]">
        <div className="flex w-full text-[16px] text-[#ffffff] gap-[8px] items-center justify-between">
          <span className="text-[16px] w-full border-[0.6px] border-[#ffffff3b] p-[16px] rounded-[16px] flex flex-col font-[400] text-[#FFFFFF] leading-[24px]">
            <span className="text-[12px] text-[#ffffff] opacity-[0.65]">
              min price
            </span>
            <span
              className={`${
                fetchingProducts
                  ? "bg-gray-50 h-[16px] w-[50px] animate-pulse"
                  : ""
              }`}
            >
              {fetchingProducts ? "" : toPrice(currency, min)}
            </span>
          </span>
          -
          <span className="text-[16px] w-full border-[0.6px] border-[#ffffff3b] p-[16px] rounded-[16px] flex flex-col font-[400] text-[#FFFFFF] leading-[24px]">
            <span className="text-[12px] text-[#ffffff] opacity-[0.65]">
              max price
            </span>
            <span
              className={`${
                fetchingProducts
                  ? "bg-gray-50 h-[16px] w-full animate-pulse rounded-[5px]"
                  : ""
              }`}
            >
              {fetchingProducts ? "" : toPrice(currency, max)}
            </span>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default PriceRangeSlider;
