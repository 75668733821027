import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import img from "../../assets/images/about-meal.png";
import vector1 from "../../assets/vectors/ring1.png";
import vector2 from "../../assets/vectors/ring2.png";
import { BsStarFill } from "react-icons/bs";
import { RiInstagramFill, RiFacebookFill } from "react-icons/ri";
import Map1 from "../components/maps/Map1";
import logo from "../../assets/images/logo.png";
import { useNavbar } from "../hooks/useNavbar";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="max-w-[1440px] w-full m-auto max-mobile:px-[35px] px-[16px] ">
      {useNavbar()}
      <div className="max-w-[1288px] items-center m-auto py-[24px] flex flex-col gap-[40px]">
        <span className="text-[#ffffff] mini-tablet:text-[56px] max-mobile:text-[32px] text-[24px] mini-tablet:leading-[84px] max-mobile:leading-[48px] leading-[36px] font-[500] text-center mini-tablet:max-w-[550px] max-mobile:max-w-[300px] max-w-[250px]">
          Nigeria’s Biggest Cloud Kitchen
        </span>
        <img
          src={img}
          className="mini-tablet:h-[513px] max-mobile:h-[310px] h-[160px] w-full"
          alt=""
        />
        <div className="flex flex-col gap-[16px]">
          <span className="mini-tablet:text-[40px] max-mobile:text-[24px] text-[20px] mini-tablet:leading-[60px] max-mobile:leading-[36px] leading-[30px] text-[#ffffff] font-[500]">
            You don’t sleep, neither do we
          </span>
          <span className="mini-tablet:text-[24px] max-mobile:text-[18px] text-[16px] mini-tablet:leading-[36px] max-mobile:leading-[27px] leading-[24px] text-[#ffffff] opacity-[0.65] font-[400]">
            Eve After Dark is a premium cloud kitchen that offers home made
            meals and delivery service around the clock. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Nunc vulputate libero et velit
            interdum, ac aliquet odio mattis.
          </span>
        </div>
        <div className="relative bg-[#AD0010] max-tablet:px-[96px] sm-tablet:p-[32px] px-[16px] mobile:py-0 py-[16px] mobile:gap-0 gap-[16px] overflow-hidden rounded-[42px] w-full max-tablet:h-[264px] sm-tablet:h-[179px] mobile:h-[119px] flex mobile:flex-row flex-col items-center mobile:justify-between ">
          <img
            src={vector1}
            className="absolute left-[0] top-0 blur-0 max-tablet:w-[364px] sm-tablet:w-[217px] w-[112px] max-tablet:h-[238px] sm-tablet:h-[178px] mobile:h-[118px]"
            alt=""
          />
          <img
            src={vector2}
            className="absolute right-[0] top-0 blur-0  max-tablet:w-[364px] sm-tablet:w-[217px] w-[112px] max-tablet:h-[238px] sm-tablet:h-[178px] mobile:h-[118px]"
            alt=""
          />
          <span className="max-tablet:w-[284px] sm-tablet:w-[162px] w-[92px] text-[#ffffff] max-tablet:text-[56px] sm-tablet:text-[24px] text-[18px] max-tablet:leading-[84px] sm-tablet:leading-[36px] leading-[27px] text-center font-[600]">
            Changing the game
          </span>
          <div className="flex sm-tablet:gap-[43.5px] gap-[8px]">
            <div className="flex flex-col items-center gap-[10.5px]">
              <span className="max-tablet:text-[40px] sm-tablet:text-[32px] text-[18px] max-tablet:leading-[60px] sm-tablet:leading-[48px] leading-[27px] font-[600] text-[#ffffff] border-b">
                4.9
              </span>
              <div className="flex justify-center sm-tablet:gap-[12px] gap-[7px]">
                <BsStarFill className="sm-tablet:text-[14px] text-[12px] text-[#ffa503]" />
                <BsStarFill className="sm-tablet:text-[14px] text-[12px] text-[#ffa503]" />
                <BsStarFill className="sm-tablet:text-[14px] text-[12px] text-[#ffa503]" />
                <BsStarFill className="sm-tablet:text-[14px] text-[12px] text-[#ffa503]" />
                <BsStarFill className="sm-tablet:text-[14px] text-[12px] text-[#ffa503]" />
              </div>
              <span className=" sm-tablet:text-[20px] text-[12px] sm-tablet:leading-[30px] leading-[18px] font-[400] text-[#ffffff]">
                10,000 Ratings
              </span>
            </div>
            <div className="flex flex-col items-center max-mobile:max-w-full max-w-[120px] gap-[10.5px]">
              <span className="mini-tablet:text-[40px] sm-tablet:text-[32px] text-[18px] max-tablet:leading-[60px] sm-tablet:leading-[48px] leading-[27px] font-[600] text-[#ffffff] border-b">
                + 20,0000
              </span>

              <span className="sm-tablet:text-[20px] text-[12px] max-mobile:w-[161px] text-center sm-tablet:leading-[30px] leading-[18px] font-[400] text-[#ffffff]">
                Deliveries made since launch
              </span>
            </div>
          </div>
        </div>
        <span className="mimi-tablet:text-[40px] w-full text-start mini-tablet:leading-[60px] max-mobile:text-[24px] max-mobile:leading-[36px] text-[18px] text-white leading-[27px]">
          Get in touch
        </span>
        <div className="max-mobile:grid flex flex-col w-full gap-[30px] max-tablet:p-[40px] p-[24px] grid-cols-12 transparent_bg3 mini-tablet:h-[650px] max-mobile:h-[368px] h-[541px] light-shadow rounded-[24px] backdrop-blur-[20px]">
          <div className="max-tablet:col-span-5 max-mobile:col-span-6 col-span-12 px-3 flex flex-col max-mobile:justify-center justify-start items-center max-mobile:gap-[24px] gap-[16px]">
            <img
              className="max-tablet:w-[130px] max-tablet:h-[130px] w-[100px] h-[100px]"
              src={logo}
              alt=""
            />
            <span className=" text-center max-tablet:text-[24px] leading-[36px] text-[#ffffffcb] font-[500]">
              13c Ayinde Akimade, Lagos, Nigeria{" "}
            </span>
            <div className="flex justify-center items-center gap-[24px]">
              <span className="max-mobile:w-[64px] w-[40px] max-mobile:h-[64px] h-[40px] rounded-full bg-[#ffffff] flex justify-center items-center">
                <RiFacebookFill className="text-[#171717] max-mobile:text-[30px] text-[16px]" />
              </span>
              <span className="max-mobile:w-[64px] w-[40px] max-mobile:h-[64px] h-[40px] rounded-full bg-[#ffffff] flex justify-center items-center">
                <RiInstagramFill className="text-[#171717] max-mobile:text-[30px] text-[16px]" />
              </span>
            </div>
          </div>
          <div className="max-tablet:col-span-7 max-mobile:h-full h-[250px] max-mobile:col-span-6 col-span-12 ">
            <Map1 />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
