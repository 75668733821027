import React, { Fragment } from "react";
// import { useEffect } from "react";
import { useFlowContext } from "../../../middle-ware/StateContext/FlowContext";
import CartCard from "../ui/CartCard";
import bag from "../../../assets/icons/bigbag.svg";
import { Link } from "react-router-dom";

const Cart = () => {
  const context = useFlowContext();

  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };

  return (
    <div className="flex flex-col gap-[24px] pl-[24px]">
      {context?.cart?.items?.length < 1 ? (
        <div className="h-[455px]  gap-[24px] px-5 flex flex-col items-center justify-end">
          <span className="bg-[rgba(255,255,255,0.05)] flex justify-center items-center w-[130px] h-[130px] rounded-full">
            <img src={bag} alt="" />
          </span>
          <span className="text-[#ffffff] text-[24px] leading-[36px] text-center max-w-[292px] ">
            Your cart is empty Add items to get started
          </span>
        </div>
      ) : (
        <Fragment>
          <div className="flex flex-col">
            <span className="text-[40px] leading-[60px] font-[600] text-[#ffffff]">
              Cart
            </span>
            <span className="text-[18px] leading-[27px] font-[600] text-[#ffffff] opacity-[0.65] ">
              {context?.cart?.items?.length} ITEM
              {context?.cart?.items?.length > 1 && "S"}
            </span>
          </div>
          <div className="flex flex-col w-full gap-[16px]">
            {context?.cart?.items?.map((i, n) => (
              <CartCard key={n} item={i} />
            ))}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-[#ffffff] text-[24px] leading-[36px] font-[600]">
                Subtotal
              </span>
              <span className="text-[#ffffff] text-[14px] leading-[27px] font-[400] opacity-[0.65]">
                Extra charges may apply
              </span>
            </div>
            <span className="text-[18px] whitespace-nowrap text-[#ffffff] leading-[27px] font-[600]">
              {/* {context.cart?.currency} {context.cart?.total} */}
              {toPrice(context.cart?.currency, context.cart?.total)}
            </span>
          </div>
          <Link
            to="/checkout"
            className="text-[#ffffff] no-underline decoration-inherit flex text-[20px] leading-[30px] font-[500] cursor-pointer max-mobile:h-[66px] h-[50px] w-full justify-center items-center bg-[#ff0018] red_btn_shadow rounded-[100px]"
          >
            Checkout
          </Link>
        </Fragment>
      )}
    </div>
  );
};

export default Cart;
