import React from "react";
import { useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";

const Sidebar = ({ active, setActive }) => {
  const navigate = useNavigate();
  const list = [
    "Profile Settings",
    "My Addresses",
    // "Payment Methods",
    // "Live Support",
  ];

  return (
    <div className="mini-tablet:w-[256px] w-[220px] gap-[16px] border-r-[2px] border-[#ffffff1a] flex flex-col">
      {list.map((l, n) => (
        <SidebarItem
          item={l}
          key={n}
          active={active === l}
          setActive={setActive}
        />
      ))}
      <span
        onClick={() => {
          localStorage.clear();
          navigate("/");
        }}
        className="w-[196px] text-[#FF0018] cursor-pointer transition-all ease-in-out duration-300 text-[18px] leading-[27px] font-[400] h-[59px] p-[16px]"
      >
        Sign Out
      </span>
    </div>
  );
};

export default Sidebar;
