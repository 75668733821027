import React, { Fragment, useRef, useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import vector from "../../../assets/icons/clock.svg";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { orders } from "../../../constants/data";
// import OrderCard from "../ui/OrderCard";
import MiniOrderCard from "../ui/MiniOrderCard";

const OrderSlide2 = ({ data }) => {
  const forwardRef = useRef(null);
  const backwardRef = useRef(null);
  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(false);
  // console.log(data);
  return (
    <div className="max-w-[1288px] w-full m-auto">
      <div className="flex w-full justify-between">
        <span className="text-[#ffffff] opacity-[0.65] tablet:text-[32px] mobile:text-[24px] tablet:leading-[48px] tablet:pb-0 pb-[16px] leading-[36px] font-[500]">
          Order History
        </span>
        {data?.length > 0 && (
          <div className="flex flex-col gap-[8px]">
            <span className="text-[#FF0018] underline cursor-pointer text-end text-[14px] leading-[21px] font-[400]">
              Show All Orders
            </span>
            <div className="tablet:flex hidden gap-[16px]">
              <span
                onClick={() => backwardRef.current.click()}
                className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer w-[56px] h-[56px] ${
                  !start && "bg-[#ff0018]"
                } flex justify-center items-center rounded-full`}
              >
                <BsArrowLeftShort className="text-[21px] text-[#ffffff]" />
              </span>
              <span
                onClick={() => forwardRef.current.click()}
                className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer w-[56px] h-[56px] ${
                  !end && "bg-[#ff0018]"
                } flex justify-center items-center rounded-full`}
              >
                <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
              </span>
            </div>
          </div>
        )}
      </div>
      {data?.length > 0 ? (
        <Fragment>
          <div className="tablet:block hidden w-full mt-[28px] mb-[24px]]">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={29}
              slidesPerView={2}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                964: {
                  width: 700,
                  slidesPerView: 2,
                },
              }}
              onSwiper={(swiper) => {
                setStart(swiper.isBeginning);
                setEnd(swiper.isEnd);
              }}
              onSlideChange={(swiper) => {
                setStart(swiper.isBeginning);
                setEnd(swiper.isEnd);
              }}
            >
              {data.map((o, n) => (
                <SwiperSlide key={n}>
                  <MiniOrderCard order={o} key={n} />
                </SwiperSlide>
              ))}
              <SwiperButtonNext>
                <span className="hidden absolute" ref={forwardRef}>
                  <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
                </span>
              </SwiperButtonNext>
              <SwiperButtonPrev>
                <span className="hidden absolute" ref={backwardRef}>
                  <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
                </span>
              </SwiperButtonPrev>
            </Swiper>
          </div>
          <div className="tablet:hidden w-full flex flex-col gap-[24px]">
            {orders.map((o, n) => (
              <MiniOrderCard order={o} key={n} />
            ))}
          </div>
        </Fragment>
      ) : (
        <div className="h-[295px] flex flex-col gap-[24px] justify-center items-center w-full">
          <span className="bg-[rgba(255,255,255,0.05)]  max-mobile:w-[130px] w-[80px] max-mobile:h-[130px] h-[80px] rounded-full flex justify-center items-center">
            <img
              className="max-mobile:w-[61px] w-[36px] max-mobile:h-[61px] h-[36px]"
              src={vector}
              alt=""
            />
          </span>
          <span className="max-mobile:text-[24px] text-[18px] max-mobile:leading-[36px] leading-[27px] text-[#ffffff] max-w-[230px] text-center">
            No orders yet.
          </span>
        </div>
      )}
    </div>
  );
};

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slideNext()}>{children}</span>;
};
const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slidePrev()}>{children}</span>;
};

// {orders.map((e, n) => (
//   <MiniOrderCard order={e} key={n} />
// ))}

export default OrderSlide2;
