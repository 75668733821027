import React from "react";
import PaymentCard from "./PaymentCard";

const Payment = () => {
  return (
    <div className="flex h-full min-h-[500px] relative gap-[16px] flex-col">
      <span className="max-mobile:text-[32px] text-[24px] mb-[8px] leading-[48px] font-[500] text-[#ffffff]">
        Payment Methods
      </span>
      <PaymentCard />
      <PaymentCard />
      <span className="h-[66px] absolute bottom-0 cursor-pointer font-[500] w-full flex justify-center items-center bg-[#000000] text-[#ffffff] rounded-[100px] text-[20px] leading-[30px]">
        Add Card
      </span>
    </div>
  );
};

export default Payment;
