import React, { useState } from "react";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import AuthLayout from "../components/ui/layouts/AuthLayout";
import Input from "../components/ui/input/Input";
import eyeSlash from "../../assets/icons/eye-slash.png";
import eye from "../../assets/icons/eye.png";
import { Link, useNavigate } from "react-router-dom";
import client from "../../middle-ware/axiosInstance";
import Loader from "../components/ui/Loader";
import { toast } from "react-hot-toast";
import { useAuthContext } from "../../middle-ware/StateContext/AuthContext";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const auth = useAuthContext();
  const [emails, setEmails] = useState(true);
  const [passwordView, setPasswordView] = useState(false);
  // const [terms, acceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [termError, setTermError] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, [name]: "Enter a valid email adddress" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    const { email, password } = form;
    const error = {};
    if (email === "") error.email = "Required*";
    else if (email !== "" && !validateEmail(email))
      error.email = "Enter a valid email adddress";
    if (password === "") error.password = "Required*";
    else if (password.length < 8)
      error.password = "Passwords should be at least 8 characters";
    return error;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        const res2 = await client.post("/auth/login", {
          email: form.email,
          password: form.password,
        });
        localStorage.setItem("token", res2.data.data.auth.token);
        localStorage.setItem("user", JSON.stringify(res2.data.data.user));
        setLoading(false);
        localStorage.removeItem("currentAddress");
        localStorage.removeItem("localCart");
        localStorage.removeItem("guestUser");
        auth.setIsLoggedIn(true);
        navigate(-1);
        // context.setAddressModal(true);
      } catch (err) {
        if (err.response.data.status === "unauthorized") {
          toast.error("Invalid username or password");
        } else toast.error("Failed to login, Please try again");
        setLoading(false);
      }
    }
  };
  return (
    <AuthLayout
      title={"Hello Again!"}
      text={"Welcome back you’ve been missed."}
    >
      <form className="w-full flex-col flex gap-[32px]" action="">
        <div className="flex flex-col gap-[20px] w-full">
          <Input
            value={form.email}
            type={"email"}
            onChange={handleChange}
            name="email"
            error={errors.email}
            label={"Email"}
          />
          <span
            onClick={() => setEmails(!emails)}
            className="flex gap-[16px] text-[#8F8F8F] transition-all ease-in-out duration-500 text-[16px] leading-[24px] items-center"
          >
            {emails ? (
              <BsCheckSquare className=" rounded-[4px] text-[#34C759] text-[20px]  transition-all ease-in-out duration-500" />
            ) : (
              <BsSquare className=" rounded-[4px] text-[#8F8F8F] text-[20px]  transition-all ease-in-out duration-500" />
            )}
            I would like to receive emails
          </span>
        </div>

        <Input
          value={form.password}
          type={passwordView ? "text" : "password"}
          onChange={handleChange}
          name="password"
          error={errors.password}
          label={"Password"}
        >
          {!passwordView ? (
            <img
              src={eye}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setPasswordView(true)}
            />
          ) : (
            <img
              src={eyeSlash}
              className="cursor-pointer transition-all ease-in-out duration-500"
              alt=""
              onClick={() => setPasswordView(false)}
            />
          )}
        </Input>
        <div className="flex justify-end gap-[8px] items-center">
          <Link to="/forgot-password" className="text-[18px] leading-[27px] text-[#A3A3A3]">
            forgot password?
          </Link>
        </div>

        <span
          onClick={() => handleSubmit()}
          className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
        >
          {loading ? <Loader size={[40, 40]} color="#ffffff" /> : "Login"}
        </span>

        <span className="text-[16px] text-center leading-[24px] font-[400] text-[#A3A3A3]">
          Don't have an account?{" "}
          <Link to="/signup" className="text-[#FF0018] cursor-pointer">
            {" "}
            Sign Up
          </Link>
        </span>
      </form>
    </AuthLayout>
  );
};

export default Login;
