import React, { useState, useEffect } from "react";
import anchor from "../../../assets/icons/send.svg";
// import { FaEllipsisH } from "react-icons/fa";
import ChatCard from "./ChatCard";
import { BsArrowLeftShort } from "react-icons/bs";
import rider from "../../../assets/images/rider.svg";
import call from "../../../assets/icons/call.svg";
import { appService } from "../../../middle-ware/Services/appService";
import Loader from "../ui/Loader";

const OrderChat = ({ close, slide, order }) => {
  const [slide_, setSlide] = useState(false);
  const [chats, setChats] = useState([]);
  const service = new appService();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const getChats = async () => {
    const res = await service.getRiderChats(order?.id);
    if (res.message === "success") setChats(res.data);
  };
  const sendMessage = async () => {
    setLoading(true);
    const res = await service.sendRiderChat(order?.id, message);
    if (res.message === "success") {
      getChats();
      setMessage("");
    }
    setLoading(false);
  };

  useEffect(() => {
    setSlide(true);
    setInterval(getChats(), 20000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`sm-tablet:h-[381px] relative max-mobile:h-[384px] h-[500px] max-h-[500px] p-[16px] rounded-[18px] bg-[#151515] w-full flex flex-col transition-transform duration-300 ${
        slide_ ? "translate-y-0" : "translate-y-[100px]"
      } `}
    >
      <div className="flex justify-between">
        <BsArrowLeftShort
          onClick={() => {
            setSlide(false);
            setTimeout(() => close(), 100);
          }}
          className="text-[24px] text-[#ffffff]"
        />
        <div className="flex items-center gap-[12px]">
          <img src={rider} className="w-[34px] h-[34px] rounded-full" alt="" />
          <div className="flex flex-col">
            <span className="text-[#ffffff] text-[12px] font-[500] leading-[18px]">
              {order?.rider?.first_name} {order?.rider?.last_name}
            </span>
            <span className="text-[#ffffff] text-[12px] font-[400] leading-[18px] opacity-[0.65]">
              Dispatch Rider
            </span>
          </div>
        </div>
        <span className="w-[34px] h-[34px] flex justify-center transparent_bg3 rounded-full items-center  ">
          <img src={call} className="w-[13.5px] h-[13.5px]" alt="" />{" "}
        </span>
      </div>
      <div className="flex pt-16px flex-col z-auto max-mobile:max-h-[240px] py-[18px] max-h-[350px] h-full text-area overflow-y-auto">
        <div className="gap-[16px]  flex flex-col justify-end w-full  relative">
          {chats.map((c, n) => (
            <ChatCard
              key={n}
              external={c?.sender_type !== "user"}
              message={c?.message}
              time={c?.created_at}
            />
          ))}
        </div>
      </div>
      <div className="h-[65px] absolute bottom-[20px] left-[20px] right-[20px] justify-between p-[18px] px-[24px]  border-[0.65px] rounded-[24px] border-[#ffffff17] flex items-center">
        <textarea
          rows={1}
          cols={1}
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message here..."
          className="w-[80%] text-area text-[#ffffff] placeholder:text-[#ffffff] placeholder:opacity-[0.65] text-[18px] leading-[27px] font-[400] opacity-[0.65] outline-0 border-none bg-transparent"
        />
        <div className="flex items-center gap-[22px]">
          {loading && <Loader color={"white"} size={[20, 20]} />}
          <img
            onClick={() => message !== "" && !loading && sendMessage()}
            className=" cursor-pointer"
            src={anchor}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default OrderChat;
