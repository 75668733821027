import React, { useEffect, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  // Marker,
  // Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import locator from "../../../assets/vectors/locator.png";
import { appService } from "../../../middle-ware/Services/appService";

const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "24px",
};

const mapOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  //   overviewMapControl: false,
  rotateControl: true,
};

function Map2({ order }) {
  const service = new appService();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCN7pinc11ItQCUJH9VXgs3ubpr1GG0vOo",
  });

  const origin = {
    lat: order ? parseFloat(order?.rider?.location?.latitude) : 6.44758,
    lng: order ? parseFloat(order?.rider?.location?.longitude) : 3.48106,
  };

  // eslint-disable-next-line
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const destination = {
    lat: order ? parseFloat(order?.address?.latitude) : 0,
    lng: order ? parseFloat(order?.address?.longitude) : 0,
  };
  let center = {
    lat: 6.44758,
    lng: 3.48106,
  };
  const waypt = [
    { location: origin, stopover: true },
    { location: origin, stopover: true },
  ];
  async function calculateRoute() {
    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypt,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getCurrentRiderLoaction = async () => {
    const res = await service.getRidersLocation(order?.rider_id);
    if (res.message === "success") {
      const co_ordinates = { lat: res.data.latitude, lng: res.data.longitude };
      return co_ordinates;
    }
  };

  useEffect(() => {
    isLoaded && calculateRoute();
    // eslint-disable-next-line
    isLoaded &&
      setTimeout(() => {
        const location = getCurrentRiderLoaction();
        center = location;
      }, 5000000 * 60);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("directionsResponse", directionsResponse);
  return isLoaded ? (
    <GoogleMap
      center={destination}
      zoom={50}
      mapContainerStyle={containerStyle}
      options={mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {directionsResponse && (
        <DirectionsRenderer
          options={{
            polylineOptions: {
              stokeColor: "#FF0000",
              strokeOpacity: 0.5,
              strokeWeight: 4,
            },
            markerOptions: { icon: locator },
            icon: { scale: 3 },
          }}
          directions={directionsResponse}
        />
      )}

      {/* <Marker options={{ icon: locator , }} /> */}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map2);
