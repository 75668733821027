import React from "react";
import v1 from "../../../../assets/vectors/vector1.png";
import v2 from "../../../../assets/vectors/vector2.png";

const Content1 = () => {
  return (
    <div className="flex flex-col w-full h-full  items-center mini-tablet:mt-[180px] mobile:mt-[146px] mt-[60px]">
      <div className="mini-tablet:max-w-[654px] mobile:max-w-[385px] max-w-[335px] z-10 w-full mini-tablet:h-[216px] mobile:h-[120px] h-[108px] flex justify-center items-center relative">
        <img
          src={v1}
          className="absolute left-0 top-0 mini-tablet:w-[118px] w-[80px] mini-tablet:h-[74px] h-[60px]"
          alt=""
        />
        <span className="text-[#ffffff] mini-tablet:text-[72px] mobile:text-[40px] text-[30px] font-[500] mini-tablet:leading-[108px] mobile:leading-[60px] leading-[54px] text-center">
          Nigeria’s Biggest Cloud Kitchen
        </span>
        <img
          src={v2}
          className="absolute cursor-pointer right-0 bottom-0 mini-tablet:w-[118px] w-[80px] mini-tablet:h-[74px] h-[60px]"
          alt=""
        />
      </div>
      <span className="mini-tablet:max-w-[414px] max-w-[345px] z-10 opacity-[0.65] mini-tablet:text-[24px] mobile:text-[20px] text-[18px] mini-tablet:leading-[36px] mobile:leading-[30px] leading-[27px] text-[#ffffff] font-[400] text-center mt-[24px] ">
        Download our mobile app to enjoy amazing features.
      </span>
      <span
        onClick={() =>
          (window.location.href =
            "https://apps.apple.com/gb/app/eve-after-dark/id6450600671")
        }
        className="bg-[#ff0018] z-10 mobile:px-[32px] px-[24px] font-[500] mini-tablet:text-[20px] text-[18px] mt-[40px] cursor-pointer py-[18px] red_btn_shadow rounded-[100px] text-[#ffffff]"
      >
        Download App
      </span>
    </div>
  );
};

export default Content1;
