import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";
import { useNavbar } from "../hooks/useNavbar";

const Error = () => {
  return (
    <div className="max-w-[1440px] w-full m-auto max-mobile:px-[35px] px-[16px] ">
      {useNavbar()}
      <div className="flex px-[16px] justify-center items-center max-mobile:h-[625px] h-[500px] flex-col">
        <span className="sm-tablet:text-[140px] max-mobile:text-[120px] text-[100px] sm-tablet:leading-[210px] max-mobile:leading-[180px] leading-[150px] text-[#ffffff] font-[500]">
          4<span className="text-[#FF0018]">0</span>4
        </span>
        <div className="flex flex-col items-center  text-center">
          <span className="text-[24px] max-w-[361px] leading-[36px] text-[#ffffff] font-[600]">
            Oops! Something went wrong
          </span>
          <span className="text-[16px] max-w-[398px] text-center leading-[24px] text-[#ffffff] font-[400] opacity-[0.65]">
            The page you’re looking for couldn’t be found You might want to go
            back home.
          </span>
          <Link to="/" className="bg-[#FF0018] tablet:w-[738px] text-[20px] leading-[30px] mt-[32px] rounded-[100px] text-[#ffffff] w-full h-[66px] flex justify-center items-center">
            Go back home
          </Link>
        </div>
      </div>
      <div className="max-w-[1288px] w-full m-auto py-[24px] pb-[35px]"></div>
      <Footer />
    </div>
  );
};

export default Error;
