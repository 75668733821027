import React, { Fragment } from "react";
import { useState } from "react";
import { appService } from "../../../middle-ware/Services/appService";
// import { useFlowContext } from "../../hooks/StateContext/FlowContext";
// import CartCard from "../ui/CartCard";
import OrderCartCard from "../ui/OrderCartCard";
// import Loader from "../ui/Loader";
// import bag from "../../assets/icons/bag.svg";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const OrderCart = ({ processed, title, completed }) => {
  const service = new appService();
  const [order, setOrder] = useState({});
  const params = useParams();

  const getOrder = async () => {
    const res = await service.getOrder(params.id);
    // console.log(res);
    if (res.message === "success") {
      setOrder(res.data);
    }
  };
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <div className="flex flex-col gap-[24px] tablet:pl-[24px]">
      <Fragment>
        <div className="flex flex-col">
          <span className="tablet:text-[40px] text-[24px] tablet:leading-[60px] leading-[36px] font-[600] text-[#ffffff]">
            {title}
          </span>
          <span className="tablet:text-[18px] text-[16px] tablet:leading-[27px] leading-[24px] font-[600] text-[#ffffff] opacity-[0.65] ">
            {order?.order_items?.length} ITEM
            {order?.order_items?.length > 1 && "S"}
          </span>
        </div>

        <div className="flex flex-col w-full gap-[16px]">
          {order?.order_items?.map((i, n) => (
            <OrderCartCard item={i} key={n} />
          ))}
        </div>

        <div className="flex justify-between items-center ">
          <div className="flex flex-col gap-[8px] w-full">
            {/* <span className="text-[#ffffff] mobile:text-[24px] text-[20px] mobile:leading-[36px] leading-[30px] font-[600]">
              Bill details
            </span> */}
            {/* <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
              <span className="opacity-[0.65]">Item Total</span>
              <span>
                {context?.cart?.currency} {context?.cart?.total}
              </span>
            </span> */}
            {/* <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
              <span className="opacity-[0.65]">Delivery Fee</span>
              <span>
                {context?.cart?.currency} {context?.cart?.delivery_charge}
              </span>
            </span> */}
            {/* <span className="flex justify-between w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
              <span className="opacity-[0.65]">VAT(7.5%)</span>
              <span>
                {order?.currency} {context?.cart?.vat_charge}
              </span>
            </span> */}
          </div>
        </div>
        <span className="flex border-t justify-between pt-[24px] w-full items-center text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400]">
          <span> Total</span>
          <span>{toPrice(order?.currency, order?.total)}</span>
        </span>
      </Fragment>
    </div>
  );
};

export default OrderCart;
