import React from "react";

const RecipientCard = ({ info }) => {
  return (
    <div className="max-w-[358px] overflow-ellipsis  w-fit  bg-[#151515] p-[20px] rounded-[18px] flex flex-col gap-[11px]">
      <span className="text-[12px] flex justify-center items-center w-[74px] text-[#ffffff] leading-[18px] bg-[#00BA80] px-[10px] py-[5px] rounded-[32px]">
        {info?.status}
      </span>
      <span className="text-[16px] overflow-hidden font-[600] leading-[24px] flex gap-[11px] items-center text-[#ffffff] ">
        <span className="">{info?.recipient?.full_name}</span> •{" "}
        <span>{info?.recipient?.phone}</span>
      </span>
      <span className="text-[#ffffff] text-[12px] font-[400] leading-[18px] opacity-[0.65]">
        {info?.address?.house_number} {info?.address?.street},{" "}
        {info?.address?.area}, {info?.address?.state}.
      </span>
    </div>
  );
};

export default RecipientCard;
