import React, { Fragment } from "react";
import location_ from "../../../../assets/icons/location-dot.svg";
import Select from "../../ui/input/Select";
import Input from "../../ui/input/Input";
import Loader from "../../ui/Loader";
// import { useDataContext } from "../../../../middle-ware/StateContext/DataContext";
import AddressInput from "../../ui/input/AddressInput";
import { IoMdClose } from "react-icons/io";
import { useAddressHook } from "./hook/useAddressHook";
import { useFlowContext } from "../../../../middle-ware/StateContext/FlowContext";

const AddressModals = () => {
  const { setAddressModalView, setOpenModal } = useFlowContext();
  const {
    area,
    areas,
    loading,
    loadingOldAddresses,
    loadingAreas,
    newAddress,
    ready,
    value,
    status,
    data,
    setValue,
    clearSuggestions,
    address,
    oldAddress,
    oldAddresses,
    oldAddressError,
    errors,
    setCurrentLoaction,
    handleAddress,
    handleChange,
    handleSubmit,
    setErrors,
    setNewAddress,
    setAddress,
  } = useAddressHook();
 
  return (
    <div className="min-h-screen h-full py-[100px] px-[30px] newscroll w-full flex overflow-auto ">
      <div className="max-w-[574px] m-auto relative flex flex-col items-center w-full h-fit rounded-[24px] max-mobile:py-[76.5px] py-[32px] max-mobile:px-[40px] px-[16px] bg-[#151515]">
        <span
          onClick={() => {
            setAddressModalView(false);
            setOpenModal(false);
          }}
          className="absolute min-tablet:top-5 mini-tablet:right-10 top-10 right-5 text-[#FF0018] rounded-full transparent_bg cursor-pointer w-[30px] h-[30px] border border-[#FF0018] flex justify-center items-center"
        >
          <IoMdClose className="" />
        </span>

        <img className="w-[89px] h-[119px]" src={location_} alt="" />
        <span className="text-center mobile:text-[32px] text-[24px] mobile:leading-[48px] leading-[30px] font-[600] text-[#ffffff]">
          Provide a delivery address
        </span>
        <form
          className="flex-col flex max-mobile:gap-[32px] gap-[24px] w-full"
          action=""
        >
          <div
            className={`min-h-[200px] transition-all ease-out duration-200 ${
              loadingOldAddresses ? "opacity-0" : "opacity-1"
            }`}
          >
            {!newAddress ? (
              <div className="flex-col flex gap-[32px] w-full">
                <Select
                  list={oldAddresses}
                  holder={"Select from previous addresses"}
                  onChange={handleChange}
                  loading={loadingOldAddresses}
                  name={"oldAddress"}
                  value={oldAddress.name}
                  error={oldAddressError}
                  light={true}
                />
                <span
                  onClick={() => setNewAddress(true)}
                  className="text-[#ffffff] cursor-pointer text-[12px] underline  text-end w-full"
                >
                  Add a new address
                </span>
                <span
                  onClick={setCurrentLoaction}
                  className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
                >
                  {loading ? (
                    <Loader size={[40, 40]} color="#ffffff" />
                  ) : (
                    "Set as current address"
                  )}
                </span>
              </div>
            ) : (
              <div className="flex-col flex gap-[32px] w-full">
                <Select
                  list={areas}
                  holder={"Select Area"}
                  onChange={handleChange}
                  loading={loadingAreas}
                  name={"area"}
                  value={area.name}
                  error={errors.area}
                  light
                />
                <Fragment>
                  {JSON.parse(localStorage?.currentAddress)?.state ===
                    "London" && (
                    <Input
                      value={address.post_code}
                      type={"text"}
                      onChange={handleAddress}
                      name="post_code"
                      error={errors.post_code}
                      label={"Post Code"}
                      light
                    />
                  )}
                  <Input
                    value={address.house_number}
                    type={"text"}
                    onChange={handleAddress}
                    name="house_number"
                    error={errors.house_number}
                    label={"House Number"}
                    light
                  />
                  <AddressInput
                    value={value}
                    type={"text"}
                    onChange={(e) => {
                      setValue(e.target.value);
                      e.target.value === "" &&
                        setErrors({ ...errors, street: "Required" });
                    }}
                    name="street"
                    error={errors.street}
                    label={"Street"}
                    ready={ready}
                    loading={status === "OK"}
                    light
                    list={data}
                    onSelect={(description) => {
                      setAddress({ ...address, street: description });
                      setValue(description, false);
                      clearSuggestions();
                      setErrors({ ...errors, street: "" });
                    }}
                  />
                </Fragment>
                {/* )} */}
                {oldAddresses.length > 0 && (
                  <span
                    onClick={() => setNewAddress(false)}
                    className="text-[#ffffff] cursor-pointer text-[12px] underline  text-end w-full"
                  >
                    Use a previous address
                  </span>
                )}
                <span
                  onClick={handleSubmit}
                  className="mobile:h-[66px] h-[57px] cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[14px] red_btn_shadow mobile:leading-[30px] leading-[21px]"
                >
                  {loading ? (
                    <Loader size={[40, 40]} color="#ffffff" />
                  ) : (
                    "Set as current address"
                  )}
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressModals;
