import React from "react";
import badge from "../../../assets/images/badge.png";
import v1 from "../../../assets/vectors/v1.svg";
import v2 from "../../../assets/vectors/v2.svg";
import v3 from "../../../assets/vectors/v3.svg";
import v4 from "../../../assets/vectors/v4.svg";
import { useNavbar } from "../../hooks/useNavbar";

const Header = () => {
  return (
    <div className="w-full">
      {useNavbar()}
      <div className=" bg-[#B8000A] w-full px-[30px]">
        <div className="mini-tablet:grid hidden grid-cols-12 gap-[32px] h-[700px]  max-w-[1290px] w-full m-auto px-[32px]">
          <div className="col-span-6 flex items-center h-full">
            <div className="flex flex-col max-w-[597px]">
              <span className="text-[48px] leading-[72px] font-[600] text-[#ffffff] ">
                Be part of Nigeria’s Biggest Cloud kitchen
              </span>
              <span className="text-[28px] leading-[42px] font-[400] text-[#ffffff] opacity-[0.85]">
                by delivering tasty meals to our customers{" "}
              </span>
            </div>
          </div>
          <div className="col-span-6 h-full flex items-center ">
            <img src={badge} className="w-full h-fit" alt="" />
          </div>
        </div>
        <div className="mini-tablet:hidden flex justify-center items-center max-mobile:h-[450px] h-[400px] w-full">
          <div className="relative p-[20px] max-w-[512px] w-full h-[204px] flex flex-col justify-center items-center ">
            <div className="absolute right-0 top-0">
              <img src={v1} alt="" />
            </div>
            <div className="absolute left-0 top-0">
              <img src={v2} alt="" />
            </div>
            <div className="absolute bottom-0 right-0">
              <img src={v3} alt="" />
            </div>
            <div className="absolute bottom-0 left-0">
              <img src={v4} alt="" />
            </div>
            <span className="max-mobile:text-[40px] mobile:text-[32px] text-[27px] max-mobile:leading-[60px] mobile:leading-[48px] leading-[36px] font-[600] text-[#ffffff] text-center">
              Be part of Nigeria’s Biggest Cloud kitchen
            </span>
            <span className="max-mobile:text-[24px] mobile:text-[18px] text-[16px] max-mobile:leading-[36px] mobile:leading-[27px] leading-[24px] font-[400] text-[#ffffff] text-center opacity-[0.85]">
              by delivering tasty meals to our customers
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
