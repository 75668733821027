import React from "react";
import bag from "../../../../assets/icons/bag.svg";
import { useNavbar } from "../hooks/useNav";
import { useNavigate } from "react-router-dom";

const FloatingCart = () => {
  const { numOfItems } = useNavbar();
  const navigate = useNavigate();
  return (
    <div
      className=" w-[50px] h-[50px] cursor-pointer z-[999999999]"
      onClick={() => navigate("/checkout")}
    >
      <div className=" relative flex gap-2 w-[50px] bg-[#ffffff17] rounded-full h-[50px] items-center justify-center">
        <img src={bag} className="w-[30.69px] h-[30.69px]" alt="" />
        {numOfItems > 0 && (
          <span className="absolute bg-[#ad0010] text-[#fff] text-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-full top-0 right-0">
            {numOfItems}
          </span>
        )}
      </div>
    </div>
  );
};

export default FloatingCart;
