import React from "react";
import EmptyCart from "./layouts/EmptyCart";
import { useNavbar } from "../hooks/useNav";
import CartLayout from "./layouts/CartLayout";
// import { usePrices  from "../../../hooks/usePrices";

const Cart = () => {
  const { cart } = useNavbar();

  return (
    <div className="bg-[#000] w-fit h-fit rounded-lg  sm-tablet:p-[32px] p-[16px]">
      {cart?.items?.length < 1 ? <EmptyCart /> : <CartLayout />}
    </div>
  );
};

export default Cart;
