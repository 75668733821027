import React from "react";

const PendingOrderCard = () => {
  return (
    <div className="sm-tablet:max-w-[401px] flex flex-col gap-[16px] w-full h-fit p-[22px] px-[30px] bg-[#151515] rounded-[18px] ">
      <span className="text-[#ffffff] text-[18px] leading-[27px] font-[500] text-center">Connecting to a rider please be patient.</span>
      <span className="text-[#ffffff] text-[14px] leading-[21px] font-[400] opacity-[0.65] text-center">
        Your order is ready! Please be patient while we connect you to one of
        our riders.{" "}
      </span>
    </div>
  );
};

export default PendingOrderCard;
