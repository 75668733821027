import { useEffect, useState } from "react";
import { appService } from "../../../../middle-ware/Services/appService";
import { useFlowContext } from "../../../../middle-ware/StateContext/FlowContext";
import { useDataContext } from "../../../../middle-ware/StateContext/DataContext";
import { useParams } from "react-router-dom";

export const useProductItem = () => {
  const path = useParams();
  const service = new appService();
  const appContext = useFlowContext();
  const { setCartModalView, setOpenModal, setCartModalItem } = useFlowContext();
  const context = useDataContext();
  const location = !localStorage.currentAddress
    ? ""
    : JSON.parse(localStorage.currentAddress);

  const [item, setItem] = useState({});
  const cart = appContext?.cart?.items?.filter(
    (item) => item?.product?.id?.toString() === path?.id?.toString()
  );

  const quantity = cart && cart?.length !== 0 ? cart[0]?.quantity : 0;
  const id = cart ? cart[0]?.id : null;
  // eslint-disable-next-line
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(false);
  // const [inCart, setInCart] = useState(false);
  const [share, setShare] = useState(false);
  const [read, setRead] = useState(false);

  const favourite =
    item && context?.rawFavourites?.find((r) => r.id === path?.id);

  const fetchProduct = async () => {
    if (localStorage.token) {
      const res = await service.getProduct(path?.id);
      if (res.message === "success") {
        setItem(res.data);
      }
    } else {
      const res = await service.getProductByLocation(location?.id, path?.id);
      if (res.message === "success") {
        setItem(res.data);
      }
    }
  };
  // console.log(item);
  const addToCart = async () => {
    setLoading(true);
    if (localStorage.token) {
      const res = await service.addToCart(item.id, count);
      if (res.message === "success") {
        const res = await service.getCart();
        setLoading(false);
        if (res.message === "success") {
          if (
            item?.has_related_products ||
            item?.has_spice ||
            item?.has_allergiess
          ) {
            setCartModalView(true);
            setOpenModal(true);
            setCartModalItem(item);
          }
        }
      }
    } else {
      service.addToLocalCart(item, count);
      const res = service.getLocalCart();
      setLoading(false);
      if (res.message === "success") {
        if (
          item.has_related_products ||
          item.has_spice ||
          item.has_allergiess
        ) {
          setTimeout(() => {
            setCartModalView(true);
            setOpenModal(true);
            setCartModalItem(item);
          }, 200);
        }
      }
    }
    // setQuantity(quantity + 1);
  };

  const likeItem = async () => {
    const res = await service.addAsFavourite(item.id);
    if (res.message === "success") service.getFavourites();
  };
  const dislikeItem = async () => {
    const res = await service.removeFavourite(item.id);
    if (res.message === "success") service.getFavourites();
  };

  const resetCart = async () => {
    if (localStorage.token) {
      const res = await service.addToCart(item.id, count);
      if (res.message === "success") {
        service.getCart();
      }
    } else {
      service.addToLocalCart(item, count);
      service.getLocalCart();
    }
  };

  const updateCart = (action) => {
    if (localStorage.token) {
      if (action === "plus" && cart?.length === 0) {
        resetCart();
      } else if (action === "plus" && cart?.length > 0) {
        service.updateCart(id, quantity + 1);
      } else {
        quantity > 1 && service.updateCart(id, quantity - 1);
      }
    } else {
      if (action === "plus") {
        service.addToLocalCart(item, quantity + 1);
        service.getLocalCart();
      } else {
        service.addToLocalCart(item, quantity - 1);
        service.getLocalCart();
      }
      // setCartModalView(true);
      // setOpenModal(true);
    }
    setLoadingCount(false);
  };
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };
  const { allProducts } = context;

  const checkForItemInStore = () => {
    const p = allProducts.find(
      (a) => a?.id?.toString() === path?.id?.toString()
    );
    console.log("all", allProducts);
    if (p) setItem(p);
    else {
      fetchProduct();
    }
  };

  useEffect(() => {
    checkForItemInStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    loadingCount,
    share,
    favourite,
    item,
    cart,
    quantity,
    addToCart,
    updateCart,
    toPrice,
    likeItem,
    dislikeItem,
    setShare,
    read,
    setRead,
  };
};
