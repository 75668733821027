import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LayoutOne from "../components/ui/layouts/LayoutOne";
import ProductsSlide from "../components/ui/ProductsSlide";
// import { appService } from "../hooks/Services/appService";
import heart from "../../assets/icons/l-heart.svg";
import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import PageLoader from "../components/ui/PageLoader";

const Favourites = () => {
  const navigate = useNavigate();
  // const service = new appService();
  const appContext = useDataContext();
  const [favourites, setFavourites] = useState(null);

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    else if (!favourites) setFavourites(appContext?.favourites);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourites]);

  return (
    <LayoutOne title={"Favourites"}>
      {!favourites ? (
        <div className="fixed top-0 right-0 w-full">
          <PageLoader transparent={true} />
        </div>
      ) : (
        <div className="flex flex-col max-w-[1288px] m-auto pb-[16px] gap-[44px]">
          <span className=" mini-tablet:text-[40px] max-mobile:text-[24px] text-[18px] mini-tablet:leading-[48px] max-mobile:leading-[36px] leading-[27px] mt-[16px] font-[500] text-[#ffffff]">
            Favorites
          </span>
          {favourites.length === 0 ? (
            <div className="flex justify-center gap-[24px] items-center w-full h-[610px] flex-col">
              <span className="mini-tablet:w-[130px] max-mobile:w-[100px] w-[80px] mini-tablet:h-[130px] max-mobile:h-[100px] h-[80px] transparent_bg4 rounded-full flex justify-center items-center">
                <img
                  className="mini-tablet:w-[58pxpx] max-mobile:w-[45px] w-[36px] h-fit"
                  src={heart}
                  alt=""
                />
              </span>
              <span className="min-tablet:text-[24px] max-mobile:text-[20px] text-[18px] min-tablet:leading-[36px] max-mobile:leading-[30px] leading-[27px] font-[400] text-[#ffffff]">
                No Favorites Yet
              </span>
            </div>
          ) : (
            favourites?.map((f, n) => {
              return (
                <div key={n} className="border-b-[0.65px] border-[#ffffff17]">
                  <ProductsSlide title={f?.title} products={f?.items} />
                </div>
              );
            })
          )}
        </div>
      )}
    </LayoutOne>
  );
};

export default Favourites;
