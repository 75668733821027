import { useCallback, useEffect, useRef, useState } from "react";
import { appService } from "../../../../middle-ware/Services/appService";
// import { useFlowContext } from "../../../../middle-ware/StateContext/FlowContext";
import { useDataContext } from "../../../../middle-ware/StateContext/DataContext";

export const useDishes = () => {
  const service = new appService();
  const forwardRef = useRef(null);
  const backwardRef = useRef(null);

  const [loadingProducts, setLoadingProducts] = useState(true);
  const { popularDishes, setPopularDishes } = useDataContext();

  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(false);
  const [products, setProducts] = useState(popularDishes);

  const getDishes = useCallback(async () => {
    const dishes = await service.getPorpularDishes();
    if (dishes.message === "success") {
      setPopularDishes(dishes?.data?.products);
      setProducts(dishes?.data?.products);
    }
    setLoadingProducts(false);
    // eslint-disable-next-line
  }, [popularDishes]);
  // console.log(popularDishes);
  useEffect(() => {
    getDishes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    backwardRef,
    forwardRef,
    start,
    setStart,
    loadingProducts,
    end,
    setEnd,
    products,
    getDishes,
    popularDishes,
  };
};
