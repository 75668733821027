import axios from "axios";

const client = axios.create({
  baseURL: "https://ead-api-backend-p55d5.ondigitalocean.app/api/v1/fe",
  //   baseURL: process.env.REACT_APP_API_URL,
});

if (localStorage.token)
  client.interceptors.request.use(function (config) {
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    config.headers["Content-Type"] = "application/json";

    const geolocation = localStorage.getItem("geolocation");
    const userIP = localStorage.getItem("userIP");

    if (geolocation) {
      const { latitude, longitude } = JSON.parse(geolocation);
      config.headers["X-Latitude"] = latitude;
      config.headers["X-Longitude"] = longitude;
    }

    if (userIP) {
      config.headers["X-User-IP"] = userIP;
    }
    return config;
  });

export default client;
