import React, { Fragment } from "react";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";
import location from "../../../assets/icons/location-fill.svg";
import { useFlowContext } from "../../../middle-ware/StateContext/FlowContext";

const Address = ({ vissible, showNext }) => {
  const context = useDataContext();
  const { setAddressModalView, setOpenModal } = useFlowContext();
  const recipientAddress = localStorage.recipientAddress
    ? JSON.parse(localStorage.recipientAddress)
    : {};
  const address =
    recipientAddress.house_number +
    " " +
    recipientAddress.street +
    ", " +
    recipientAddress.area +
    ", " +
    recipientAddress.state +
    ", " +
    recipientAddress.country;
  // console.log(recipientAddress);
  return (
    <Fragment>
      <span className="mobile:text-[24px] text-[18px] mobile:leading-[36px] leading-[27px] font-[500]">
        Delivery Address
      </span>
      <span className="text-[16px] max-w-[430px] w-full opacity-[0.65] leading-[24px] text-center font-[400]">
        This will help us to check availability and delivery fees.
      </span>
      <div className="flex flex-col gap-[16px] w-full max-w-[430px] mt-[8px]">
        {recipientAddress?.street ? (
          <>
            <div className="flex items-center gap-[16px] bg-[#ffffff] w-full px-[27px] py-[16px]">
              <img src={location} alt="" />
              <span className="truncate text-[#000000] opacity-[.5] mobile:text-[18px] text-[16px]">
                {address}
              </span>
            </div>
            <div
              onClick={() => {
                setAddressModalView(true);
                setOpenModal(true);
              }}
              className="flex w-full justify-center items-center cursor-pointer border border-[#ff0018] h-[55px] rounded-[100px] text-[#ff0018] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[24px] font-[500]"
            >
              Use a different address
            </div>

            <div
              onClick={() => {
                vissible(false);
                localStorage.setItem("hasRecipientAddress", true);
                showNext();
              }}
              className="flex w-full justify-center items-center cursor-pointer bg-[#ff0018] h-[55px] rounded-[100px] text-[#ffffff] mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[500]"
            >
              Continue
            </div>
          </>
        ) : (
          <div
            onClick={() => {
              setAddressModalView(true);
              setOpenModal(true);
            }}
            className="flex w-full justify-center items-center cursor-pointer bg-[#ff0018] border border-[#ff0018] h-[55px] rounded-[100px] text-[#ffffff] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[24px] font-[500]"
          >
            Add a delivery address
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Address;
