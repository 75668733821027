import React from "react";

const ChatCard = ({ external }) => {
  return (
    <div
      className={`w-full flex flex-col ${
        external ? "items-start" : "items-end"
      }`}
    >
      <span
        className={`text-[#FFFFFf] text-[14px] leading-[24px] ${
          external ? "bg-[#ffffff7e]" : "bg-[#ff0018]"
        } max-w-[349px] px-[21px] py-[14px] rounded-[100px]`}
      >
        My card isn’t working, I’m trying to order.
      </span>
      <span className="text-[12px] mt-[8px] leading-[18px] font-[400] text-[#ffffff] opacity-[0.65]">
        12:45pm • {external ? "Eve After..." : " You"}
      </span>
    </div>
  );
};

export default ChatCard;
