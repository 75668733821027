export const products = [
  {
    img: require("../assets/images/product/1.png"),
    title: "Basmati Jollof Rice",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "7,000",
  },
  {
    img: require("../assets/images/product/2.png"),
    title: "Edikang Ikang Soup",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "6,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
  {
    img: require("../assets/images/product/2.png"),
    title: "Monkey Jollof Rice",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "6,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Potty Jollof Rice",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "8,000",
  },
  {
    img: require("../assets/images/product/1.png"),
    title: "Indomie Jollof Rice",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "10,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
  {
    img: require("../assets/images/product/3.png"),
    title: "Spicy Goat Meat (Asun)",
    description:
      "Our Mouth Watering Basmati Jollof Rice Is Prepared With Love In the heart of the kidney of the bones",
    price: "9,000",
  },
];

export const catalogue = [
  {
    img: require("../assets/images/meals/rice.png"),
    name: "Rice Meals",
    quantity: "14",
  },
  {
    img: require("../assets/images/meals/cradle2.png"),
    name: "Eve's Cradle",
    quantity: "10",
  },
  {
    img: require("../assets/images/meals/extra.png"),
    name: "Main, Sides & Extras",
    quantity: "27",
  },
  {
    img: require("../assets/images/meals/soup.png"),
    name: "Soups",
    quantity: "21",
  },
  {
    img: require("../assets/images/meals/combo2.png"),
    name: "Combos",
    quantity: "8",
  },
  {
    img: require("../assets/images/meals/proteins.png"),
    name: "Proteins",
    quantity: "14",
  },
  {
    img: require("../assets/images/meals/grill.png"),
    name: "Grills",
    quantity: "8",
  },
  {
    img: require("../assets/images/meals/drinks.png"),
    name: "Drinks",
    quantity: "13",
  },
];

export const orders = [
  {
    items: [
      {
        img: require("../assets/images/meals/rice.png"),
        name: "Rice Meals",
        quantity: "14",
      },
      {
        img: require("../assets/images/meals/cradle2.png"),
        name: "Eve's Cradle",
        quantity: "10",
      },
      {
        img: require("../assets/images/meals/extra.png"),
        name: "Main, Sides & Extras",
        quantity: "27",
      },
      {
        img: require("../assets/images/meals/soup.png"),
        name: "Soups",
        quantity: "21",
      },
    ],
    status: "Processing",
    date: "1st August, 2022",
    time: "8:45AM",
    amount: "15,0000",
  },
  {
    items: [
      {
        img: require("../assets/images/meals/rice.png"),
        name: "Rice Meals",
        quantity: "14",
      },
      {
        img: require("../assets/images/meals/cradle2.png"),
        name: "Eve's Cradle",
        quantity: "10",
      },
    ],
    status: "Delivering",
    date: "1st August, 2022",
    time: "8:45AM",
    amount: "15,0000",
  },
  {
    items: [
      {
        img: require("../assets/images/meals/rice.png"),
        name: "Rice Meals",
        quantity: "14",
      },
      {
        img: require("../assets/images/meals/cradle2.png"),
        name: "Eve's Cradle",
        quantity: "10",
      },
      {
        img: require("../assets/images/meals/extra.png"),
        name: "Main, Sides & Extras",
        quantity: "27",
      },
      {
        img: require("../assets/images/meals/soup.png"),
        name: "Soups",
        quantity: "21",
      },
    ],
    status: "Delivered",
    date: "1st August, 2022",
    time: "8:45AM",
    amount: "15,0000",
  },
  {
    items: [
      {
        img: require("../assets/images/meals/rice.png"),
        name: "Rice Meals",
        quantity: "14",
      },
      {
        img: require("../assets/images/meals/cradle2.png"),
        name: "Eve's Cradle",
        quantity: "10",
      },
      {
        img: require("../assets/images/meals/extra.png"),
        name: "Main, Sides & Extras",
        quantity: "27",
      },
      {
        img: require("../assets/images/meals/soup.png"),
        name: "Soups",
        quantity: "21",
      },
    ],
    status: "Delivered",
    date: "1st August, 2022",
    time: "8:45AM",
    amount: "15,0000",
  },
  {
    items: [
      {
        img: require("../assets/images/meals/rice.png"),
        name: "Rice Meals",
        quantity: "14",
      },
      {
        img: require("../assets/images/meals/cradle2.png"),
        name: "Eve's Cradle",
        quantity: "10",
      },
      {
        img: require("../assets/images/meals/extra.png"),
        name: "Main, Sides & Extras",
        quantity: "27",
      },
      {
        img: require("../assets/images/meals/soup.png"),
        name: "Soups",
        quantity: "21",
      },
    ],
    status: "Delivered",
    date: "1st August, 2022",
    time: "8:45AM",
    amount: "15,0000",
  },
  {
    items: [
      {
        img: require("../assets/images/meals/rice.png"),
        name: "Rice Meals",
        quantity: "14",
      },
      {
        img: require("../assets/images/meals/cradle2.png"),
        name: "Eve's Cradle",
        quantity: "10",
      },
      {
        img: require("../assets/images/meals/extra.png"),
        name: "Main, Sides & Extras",
        quantity: "27",
      },
      {
        img: require("../assets/images/meals/soup.png"),
        name: "Soups",
        quantity: "21",
      },
    ],
    status: "Delivered",
    date: "1st August, 2022",
    time: "8:45AM",
    amount: "15,0000",
  },
];

export const homeReviews = [
  {
    image: require("../assets/images/reviewers/ava_g.png"),
    name: "George Freeman",
    text: "The food was absolutely amazing! I had the jollof with asùn and it was cooked to perfection. The service was excellent too, Highly recommend!",
  },
  {
    image: require("../assets/images/reviewers/ava_k.png"),
    name: "Kate Griffon",
    text: "Omgggg, the chicken wings are heavenly. I enjoyed every bit of it with my siblings.",
  },
  {
    image: require("../assets/images/reviewers/ava_en.png"),
    name: "Eren Yeager",
    text: "Eve After Dark is my go-to delivery platform. Their prices are really reasonable. Plus, the app is super easy to use.",
  },{
    image: require("../assets/images/reviewers/ava_g.png"),
    name: "Gwen Stacey",
    text: "Hi! So I just had my wings! Looks yum, tastes amazing. So perfect for this weather and smells heavenly. would definitely be back to get more.",
  },
  {
    image: require("../assets/images/reviewers/ava_l.png"),
    name: "Luffy Derick",
    text: "Received our soup with thanks. From here and there I can confess it’s fantastic. Thanks for delivering, we are totally hooked on Eve After Dark.",
  },
  {
    image: require("../assets/images/reviewers/ava_b.png"),
    name: "Babatunde Raji",
    text: "Received our soup with thanks. From here and there I can confess it’s fantastic. Thanks for delivering, we are totally hooked on Eve After Dark.",
  },
  {
    image: require("../assets/images/reviewers/ava_g.png"),
    name: "Gwen Stacey",
    text: "Hi! So I just had my wings! Looks yum, tastes amazing. So perfect for this weather and smells heavenly. would definitely be back to get more.",
  },
  {
    image: require("../assets/images/reviewers/ava_l.png"),
    name: "Luffy Derick",
    text: "Received our soup with thanks. From here and there I can confess it’s fantastic. Thanks for delivering, we are totally hooked on Eve After Dark.",
  },
  {
    image: require("../assets/images/reviewers/ava_b.png"),
    name: "Babatunde Raji",
    text: "Received our soup with thanks. From here and there I can confess it’s fantastic. Thanks for delivering, we are totally hooked on Eve After Dark.",
  },
  {
    image: require("../assets/images/reviewers/ava_en.png"),
    name: "Evan Steff",
    text: "Eve After Dark is my go-to delivery platform. Their prices are really reasonable. Plus, the app is super easy to use.",
  }
];
