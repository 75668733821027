import React from "react";
import InfoCard from "./InfoCard";
import icon1 from "../../../assets/icons/red-wallet.svg";
import icon2 from "../../../assets/icons/red-clock.svg";
import icon3 from "../../../assets/icons/red-pers.svg";

const RiderSpecs = () => {
  return (
    <div className="mini-tablet:grid flex flex-col grid-cols-12 mini-tablet:gap-[8px] gap-[32px] px-[32px] py-[64px] h-full max-w-[1290px] w-full m-auto ">
      <div className="mini-tablet:col-span-4 col-span-12 h-full flex justify-center items-center">
        <InfoCard
          heading={"Earn with the best"}
          icon={icon1}
          desc={"Deliver with Nigeria’s Biggest Cloud Kitchen."}
        />
      </div>
      <div className=" mini-tablet:col-span-4 col-span-12 h-full flex justify-center items-center">
        <InfoCard
          heading={"Work when you want"}
          icon={icon2}
          desc={"You decide when, where, and how much you work. You can forget about reporting to an office — or a boss."}
        />
      </div>
      <div className=" mini-tablet:col-span-4 col-span-12 h-full flex justify-center items-center">
        <InfoCard
          heading={"Set your own course"}
          icon={icon3}
          desc={"Choose whether or not to accept orders, find demand near you."}
        />
      </div>
    </div>
  );
};

export default RiderSpecs;
