// eslint-disable-next-line
import { useEffect, useRef, useState } from "react";
import { appService } from "../../middle-ware/Services/appService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useHotReload } from "./useHotReload";
import { useFlowContext } from "../../middle-ware/StateContext/FlowContext";
import { useFetchData } from "./useFetchData";

export const useLocationHandler = () => {
  const service = new appService();
  const navigate = useNavigate();
  const { reloadMenu } = useFlowContext();
  const { stopLoading } = useFetchData();
  const listRef = useRef();

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const [locationId, setLocationId] = useState("");
  const [toggler, setToggler] = useState(false);
  const [delayToggler, setDelayToggler] = useState(false);

  const currentLocation = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};

  const { handleHotReload } = useHotReload();

  const getLocations = async () => {
    const res = await service.getAllLocations();

    if (res.message === "success") {
      setLocations(res.data);
    }
  };
  const setUserLocation = async (id) => {
    setLoading(true);
    setLocation(id);
    if (!localStorage.token) {
      localStorage.setItem("initialSetup", false);
      localStorage.removeItem("localCart");
      localStorage.removeItem("forAFriend");
      stopLoading();
      navigate(-1);
    } else {
      const res = await service.setCurrentLocation(id);
      if (res.message === "success") {
        localStorage.setItem("currentAddress", JSON.stringify(res.data[0]));
        localStorage.removeItem("forAFriend");
        localStorage.setItem("initialSetup", false);
        stopLoading();
        navigate(-1);
      } else {
        toast.error("Something went wrong, Please try  again.");
      }
    }
    setLoading(false);
  };

  const setFriendsLocation = async (id) => {
    setLoading(true);
    setLocation(id);
    if (!localStorage.token) {
      localStorage.setItem(
        "currentAddress",
        JSON.stringify(locations.find((l) => l.id === location))
      );
      localStorage.setItem("initialSetup", false);
      localStorage.removeItem("localCart");
      localStorage.setItem("forAFriend", true);
      stopLoading();
      navigate("/menu");
    } else {
      const res = await service.setCurrentLocation(location);
      if (res.message === "success") {
        localStorage.setItem("currentAddress", JSON.stringify(res.data[0]));
        localStorage.setItem("forAFriend", true);
        localStorage.setItem("initialSetup", false);
        stopLoading();
        navigate("/menu");
      } else {
        toast.error("Something went wrong, Please try  again.");
      }
    }
    setLoading(false);
  };

  const changeLocation = async (param) => {
    setLoading(true);
    if (!localStorage.token) {
      localStorage.setItem("currentAddress", JSON.stringify(param));
      localStorage.removeItem("localCart");
      handleHotReload(param.id);
      // setLoading(false);
      // closeLocationList();
      forceLocation(param.id);
      // setReloadMenu(true);
      !loading && closeLocationList();
    } else {
      const res = await service.setCurrentLocation(param.id);
      if (res.message === "success") {
        localStorage.setItem("currentAddress", JSON.stringify(res.data[0]));
        handleHotReload(param.id);
        !loading && closeLocationList();
        forceLocation(param.id);
        // setReloadMenu(true);
      } else {
        toast.error("Something went wrong, Please try  again.");
      }
      // setLoading(false);
      // closeLocationList();
    }
  };

  const forceLocation = async (id) => {
    setLoading(true);
    setLocation(id);
    if (!localStorage.token) {
      localStorage.setItem("initialSetup", false);
      localStorage.removeItem("localCart");
      localStorage.removeItem("forAFriend");
      // await service.getPrductsByLoaction(id);
      // setReloadMenu(true);
    } else {
      const res = await service.setCurrentLocation(id);
      if (res.message === "success") {
        localStorage.setItem("currentAddress", JSON.stringify(res.data[0]));
        localStorage.removeItem("forAFriend");
        localStorage.setItem("initialSetup", false);
        // await service.getPrductsByLoaction(id);
        // setReloadMenu(true);
      } else {
        toast.error("Something went wrong, Please try  again.");
      }
    }
    // window.location.reload();
    setLoading(false);
  };

  const openLocationList = () => {
    setToggler(true);
    setTimeout(() => {
      setDelayToggler(true);
    }, 10);
  };
  const closeLocationList = () => {
    setDelayToggler(false);
    setTimeout(() => {
      setToggler(false);
    }, 10);
  };

  window.addEventListener("click", (e) => {
    if (toggler && !loading && !listRef.current?.contains(e.target)) {
      closeLocationList();
    }
  });

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    locations,
    listRef,
    setLocations,
    location,
    setLocation,
    loading,
    setUserLocation,
    setFriendsLocation,
    currentLocation,
    changeLocation,
    locationId,
    setLocationId,
    toggler,
    setToggler,
    delayToggler,
    setDelayToggler,
    openLocationList,
    closeLocationList,
    reloadMenu,
  };
};
