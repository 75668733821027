import { useState } from "react";
import { appService } from "../../../../middle-ware/Services/appService";
import { toast } from "react-hot-toast";

export const useRecipientForm = (vissible, showNext) => {
  const service = new appService();
  const user = localStorage.user ? JSON.parse(localStorage.user) : {};
  const userInfo = Object.keys(user).length;

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    full_name:
      userInfo > 0 && !localStorage.forAFriend
        ? user.first_name + " " + user.last_name
        : "",
    email: userInfo > 0 && !localStorage.forAFriend ? user.email : "",
    phone: userInfo > 0 && !localStorage.forAfriend ? user?.phone?.slice(4) : "",
    customer_note: "",
  });

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    phone: "",
    dob: false,
  });

  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkPhone = (value) => {
    const arr = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
    if (arr.includes(value[value.length - 1])) return true;
    else return false;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const phoneChecker = checkPhone(value);
    if (name === "phone") {
      if (phoneChecker || form.phone.length === 1)
        setForm({ ...form, [name]: value });
    } else setForm({ ...form, [name]: value });
    if (value === "") {
      setErrors({ ...errors, [name]: "Required*" });
    } else if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, [name]: "Email is not valid*" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const vaildateRecipient = () => {
    const newErrors = {};
    if (form.full_name === "") newErrors.full_name = "Required*";
    else if (!form.full_name.includes(" ") || form.full_name.length < 6)
      newErrors.full_name = "Enter your full name*";
    else {
      const firstName = form.full_name.slice(0, form.full_name.indexOf(" "));
      const lastName = form.full_name.slice(0, form.full_name.indexOf(" "));
      if (firstName.length < 3 || lastName.length < 3)
        newErrors.full_name = "Enter your full name*";
    }
    if (form.email === "") newErrors.email = "Required*";
    else if (!validateEmail(form.email))
      newErrors.email = "Email is not valid*";
    if (form.phone === "") newErrors.phone = "Required*";
    else if (location?.state !== "London" && form.phone.length < 10)
      newErrors.phone = "Phone number should be up to 10 digits*";
    return newErrors;
  };

  const setRecipient = async () => {
    const err = vaildateRecipient();
    if (Object.keys(err).length > 0) {
      setErrors(err);
    } else {
      setLoading(true);
      if (localStorage.token && !localStorage.guestUser) {
        const res = await service.updateRecipient({
          recipient: {
            full_name: form.full_name,
            email: form.email,
            phone: "+234" + form.phone,
            customer_note: form.customer_note,
          },
        });
        if (res.message === "success") {
          toast.success("Recipient information updated");
          vissible(false);
          showNext();
          localStorage.setItem("hasRecipient", true);
        }
      } else {
        const firstName = form.full_name.slice(0, form.full_name.indexOf(" "));
        const lastName = form.full_name.slice(
          form.full_name.indexOf(" "),
          form.full_name.length
        );
        const res = await service.addGuestUserAsRecipient({
          user: {
            first_name: firstName,
            last_name: lastName,
            phone: "+234" + form.phone,
            email: form.email,
            receive_email: true,
            terms: true,
          },
        });
        if (res.message === "success") {
          toast.success("Recipient information updated");
          vissible(false);
          localStorage.setItem("hasRecipient", true);
          showNext();
        }
      }

      setLoading(false);
    }
  };
  return {
    loading,
    form,
    errors,
    user,
    userInfo,
    location,
    handleChange,
    setRecipient,
  };
};
