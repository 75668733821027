import React from "react";
import { useSidebar } from "../hooks/useSidebar";
import ProfileBar from "../sides/ProfileBar";
import { IoIosArrowForward } from "react-icons/io";

const SidebarContainer = ({ position, children }) => {
  const {
    sidebarRef,
    menuRef,
    openSidebar,
    sidebar,
    animatedStyle,
    closeSidebar,
  } = useSidebar();

  return (
    <div
      ref={position === "left" ? menuRef : sidebarRef}
      className={`${
        position === "left"
          ? "mini-tablet:hidden flex"
          : "mini-tablet:flex hidden items-center cursor-pointer "
      }`}
    >
      <div onClick={openSidebar} className="flex items-center cursor-pointer">
        {children}
      </div>
      {sidebar && (
        <div className="fixed right-0 z-50 left-[0] w-full top-0 bg-[rgba(0,0,0,0.5)]">
          <div className="relative w-full h-full bg-[rgba(0,0,0,0.5)]">
            <div
              className={`absolute transition-transform duration-300 ease-in-out ${animatedStyle(
                position
              )} text-area bg-[rgba(0,0,0,0.5)] overflow-y-auto min-h-screen h-screen top-0 bottom-0 `}
            >
              <ProfileBar close={closeSidebar} />
            </div>
          </div>
        </div>
      )}
      {position === "right" && (
        <IoIosArrowForward className="text-[#ffffff] min-w-[12px] ml-3" />
      )}
    </div>
  );
};

export default SidebarContainer;
