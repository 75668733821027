import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Faqs from "../components/help/Faqs";
import Legal from "../components/help/Legal";
import Sidebar from "../components/help/Sidebar";
import LayoutOne from "../components/ui/layouts/LayoutOne";
import Support from "../components/help/Support";
import MobilNavigator from "../components/help/MobilNavigator";
import { MdOutlineClose } from "react-icons/md";

const Help = () => {
  const [active, setActive] = useState("FAQs");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutOne title={"Help"} action={() => setActive("")}>
      <div className="flex flex-col  transprent_bg4 max-w-[1288px] m-auto sm-tablet:pb-[16px] gap-[44px]">
        <span className=" mini-tablet:text-[40px] max-mobile:text-[24px] text-[18px] mini-tablet:leading-[48px] max-mobile:leading-[36px] leading-[27px] mt-[16px] font-[500] text-[#ffffff]">
          Help & Support
        </span>

        <div className="w-ull sm-tablet:transparent_bg sm-tablet:min-h-[535px] h-fit rounded-[24px] sm-tablet:mb-[32px] sm-tablet:p-[40px] sm-tablet:transparent_bg4 m-auto w-full flex sm-tablet:flex-row flex-col sm-tablet:gap-[60px] ">
          <div className={` sm-tablet:block hidden`}>
            <Sidebar active={active} setActive={setActive} />
          </div>
          <div className={`sm-tablet:hidden block `}>
            {mobile === "" && (
              <MobilNavigator active={mobile} setActive={setMobile} />
            )}
          </div>
          <div className="sm-tablet:block hidden w-full transition-transform ease-in-out duration-500">
            {active === "FAQs" && <Faqs />}
            {active === "Legal" && <Legal />}
            {active === "Live Support" && <Support />}
          </div>

          <div className="sm-tablet:max-w-[494px] relative  sm-tablet:hidden block w-full transition-transform ease-in-out duration-500">
            {mobile !== "" && (
              <span className="w-full absolute top-0 flex h-[30px] justify-end text-[#fff] ">
                <MdOutlineClose
                  onClick={() => setMobile("")}
                  color="#ffffffa1"
                  className="text-[#ffffffa1] text-[20px]"
                />
              </span>
            )}
            {mobile === "FAQs" && <Faqs />}
            {mobile === "Legal" && <Legal />}
            {mobile === "Live Support" && <Support />}
          </div>
        </div>
      </div>
    </LayoutOne>
  );
};

export default Help;
