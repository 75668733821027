import React from 'react'
import { BsStarFill } from 'react-icons/bs'

const Rating = ({param, handler, title}) => {
  return (
    <div className="flex justify-between items-center">
    <span className="text-[14px] text-[#ffffff] font-[400] leading-[21px]">
      {title}:
    </span>
    <div className="flex gap-[4px]">
      <BsStarFill
        onClick={() =>
          param < 1 ? handler(1) : handler(0)
        }
        className={`transition-all ease-in-out duration-500 ${
          param > 0 ? "text-[#ffc909]" : "text-[#CBCBCB]"
        } cursor-pointer text-[20px]`}
      />
      <BsStarFill
        onClick={() =>
          param < 2 ? handler(2) : handler(1)
        }
        className={` transition-all ease-in-out duration-500 ${
          param > 1 ? "text-[#ffc909]" : "text-[#CBCBCB]"
        } cursor-pointer text-[20px]`}
      />
      <BsStarFill
        onClick={() =>
          param < 3 ? handler(3) : handler(2)
        }
        className={` transition-all ease-in-out duration-500 ${
          param > 2 ? "text-[#ffc909]" : "text-[#CBCBCB]"
        } cursor-pointer text-[20px]`}
      />
      <BsStarFill
        onClick={() =>
          param < 4 ? handler(4) : handler(3)
        }
        className={` transition-all ease-in-out duration-500 ${
          param > 3 ? "text-[#ffc909]" : "text-[#CBCBCB]"
        } cursor-pointer text-[20px]`}
      />
      <BsStarFill
        onClick={() =>
          param < 5 ? handler(5) : handler(4)
        }
        className={` transition-all ease-in-out duration-500 ${
          param > 4 ? "text-[#ffc909]" : "text-[#CBCBCB]"
        } cursor-pointer text-[20px]`}
      />
    </div>
  </div>
  )
}

export default Rating