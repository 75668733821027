import { useRef, useState } from "react";

export const useSidebar = () => {
  const user = localStorage.user
    ? JSON.parse(localStorage.user)
    : { first_name: "", last_name: "", email: "" };
  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};

  const [sidebar, setSidebar] = useState(false);
  const [active, setActive] = useState(false);

  const sidebarRef = useRef();
  const menuRef = useRef();

  const closeSidebar = () => {
    setActive(false);
    setTimeout(() => setSidebar(false), [500]);
  };
  const openSidebar = () => {
    setSidebar(true);
    setTimeout(() => setActive(true), [200]);
  };

  window.addEventListener("click", (e) => {
    if (
      sidebar &&
      !sidebarRef.current?.contains(e.target) &&
      !menuRef.current?.contains(e.target)
    )
      closeSidebar();
  });

  const animatedStyle = (position) => {
    if (position === "left") {
      return active
        ? "translate-x-0 mini-tablet:hidden block left-0"
        : "translate-x-[-317px] mini-tablet:hidden block left-0";
    } else {
      return active
        ? "translate-x-0 mini-tablet:block hidden right-0"
        : "translate-x-[317px] mini-tablet:block hidden right-0";
    }
  };

  return {
    location,
    user,
    sidebar,
    setSidebar,
    sidebarRef,
    menuRef,
    active,
    setActive,
    openSidebar,
    closeSidebar,
    animatedStyle,
  };
};
