import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { useProduct } from "../../hooks/useProduct";
import Images from "./Images";

const MiniOrderCard = ({ order }) => {
  const navigate = useNavigate();
  const { toPrice } = useProduct();
  return (
    <div
      onClick={() => navigate(`/orders/history/${order.id}`)}
      className=" tablet:w-[354px] p-[16px] h-fit transparent_bg light-shadow flex flex-col gap-[24px] rounded-[16px]"
    >
      <div className="flex flex-col gap-[8px]">
        <div className="flex justify-between items-center">
          <div className="flex relative w-[128px] h-[50px]">
            {order?.order_items?.slice(0, 3).map((i, n) => {
              const position = n * 25;
              return (
                <span style={{ left: position + "%" }}>
                  <Images
                    key={n}
                    classes={`w-[50px] h-[50px] absolute rounded-full left-[${position}%] border-2 border-[#333333]`}
                    width={"50px"}
                    height={"50px"}
                    radius={"100%"}
                    url={i?.product?.image?.url}
                  />
                </span>
              );
            })}

            {order?.order_items?.length > 3 && (
              <span className="w-[50px] text-[20px] h-[50px] absolute left-[75%] rounded-full flex gap-[8px] items-center justify-center bg-[#CCCCCC] border-2 border-[#333333]">
                <FaEllipsisH className="text-[#333333]" />
              </span>
            )}
          </div>
          <span
            className={`${
              order.status === "Delivering"
                ? "text-[#FF0018]"
                : "text-[#ffffff]"
            } text-[12px] leading-[18px] font-[400] px-[9.5px] py-[5px] rounded-[32px] 
            ${order.status === "paid" && "bg-[#00BA80]"} ${
              order.status !== "paid" && "bg-[#FFB530]"
            }`}
          >
            {order.status}
          </span>
        </div>
        <span className="text-[16px] text-[#ffffff] leading-[24px] font-[500] ">
          {order?.order_items?.length} Item
          {order?.order_items?.length > 1 && "s"}
        </span>
        <div className="flex justify-between items-center">
          <span className="text-[14px] text-[#ffffff] leading-[21px] font-[400] opacity-[0.65]">
            <Moment format={"DD/MM/YYYY - HH:mm"} date={order?.created_at} />
          </span>
          <span className="text-[14px] text-[#ffffff] leading-[21px] font-[400]">
            {toPrice(order?.currency, order?.total)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MiniOrderCard;
