import React from "react";
import Loader from "../../../ui/Loader";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
// import { useProductItem } from "../../../product/hooks/useProductItem";

export const Counter = ({ quantity, loadingCount, setQuantity }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="flex max-mobile:h-[52px] sm:h-[60px] h-[42px]  rounded-[100px] justify-bewtween border-[0.65px] max-mobile:px-[16px] px-[10px] border-[#ffffff80] max-mobile:w-[116px] w-[138px] items-center"
    >
      <span
        onClick={() => setQuantity("minus")}
        className="w-[24px] w cursor-pointer h-[24px] flex justify-center items-center transparent_bg2 rounded-full"
      >
        <BiMinus className="text-[#ffffff] text-[14.4px] " />
      </span>
      <span className="text-[#FFFFFF] text-[14px]">
        {loadingCount ? <Loader color={"#ffffff"} size={[20, 20]} /> : quantity}
      </span>
      <span
        onClick={() => setQuantity("plus")}
        className="w-[24px] cursor-pointer h-[24px] flex justify-center items-center transparent_bg2 rounded-full"
      >
        <BsPlus className="text-[#ffffff] text-[14.4px]" />
      </span>
    </div>
  );
};
export const MainCounter = () => {
  //   const { updateCart, loadingCount, quantity } = useProductItem();
  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="flex max-mobile:h-[66px] h-[57px] rounded-[100px] justify-bewtween border-[0.65px] max-mobile:px-[32px] gap-[18px] px-[18px] border-[#ffffff80] w-[189px] items-center"
    >
      <span
        onClick={() => {}}
        className="w-[40px] cursor-pointer h-[40px] flex justify-center items-center transparent_bg2 rounded-full"
      >
        <BiMinus className="text-[#ffffff]" />
      </span>
      <span className="text-[#FFFFFF]">
        {false ? <Loader color={"#ffffff"} size={[20, 20]} /> : 1}
      </span>
      <span
        onClick={() => {}}
        className="w-[40px] cursor-pointer h-[40px] flex justify-center items-center transparent_bg2 rounded-full"
      >
        <BsPlus className="text-[#ffffff]" />
      </span>
    </div>
  );
};
// export default Counter;
