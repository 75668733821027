import React from "react";
import logo from "../../../../assets/images/logo.png";
import ng from "../../../../assets/images/ng.svg";
import uk from "../../../../assets/images/uk.svg";
import au from "../../../../assets/images/dubar-flag.png";
import tx from "../../../../assets/images/texas.png";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import back from "../../../../assets/icons/l-left.png";
import PageLink from "../utils/PageLink";
import CartContainer from "../utils/CartContainer";
import SidebarContainer from "../utils/SidebarContainer";
import { useSidebar } from "../hooks/useSidebar";
import { useLocationHandler } from "../../../hooks/useLocationHandler";
import { FiChevronDown } from "react-icons/fi";
import Loader from "../../ui/Loader";
import { SmallForFriendFloater } from "../utils/ForFriendFloater";

const UserNavbar = () => {
  const { user, location, openSidebar, menuRef, sidebarRef } = useSidebar();
  const {
    locations,
    currentLocation,
    changeLocation,
    loading,
    locationId,
    setLocationId,
    setUserLocation,
    delayToggler,
    openLocationList,
    closeLocationList,
    toggler,
    listRef,
  } = useLocationHandler();

  const route = window.location.pathname;

  const getCountryFlag = (name) => {
    if (name === "Dubai") return au;
    else if (name === "London") return uk;
    else if (name === "Texas") return tx;
    else if (name === "FCT") return ng;
    else if (name === "Lagos") return ng;
    else return logo;
  };

  return (
    <div className="max-w-[1288px] relative m-auto mini-tablet:grid flex justify-between items-center grid-cols-12 ">
      {route !== "/" && route !== "/menu" ? (
        <Link to="/menu" className="mini-tablet:hidden block">
          <img src={back} alt="" />
        </Link>
      ) : (
        <SidebarContainer position={"left"} ref={menuRef}>
          <BiMenu
            onClick={openSidebar}
            className="text-[20px]  text-[#ffffff] cursor-pointer mr-[27px]"
          />
        </SidebarContainer>
      )}

      <Link
        to="/"
        className="mini-tablet:col-span-2 col-span-6 mr-4 flex items-center "
      >
        <img
          src={logo}
          className=" mini-tablet:min-w-[120px] mobile:min-w-[80px] min-w-[70px] mini-tablet:h-[120px] mobile:h-[80px] h-[70px] "
          alt=""
        />
      </Link>
      <div className="tablet:col-span-6 mini-tablet:col-span-5 mini-tablet:flex  hidden items-center mr-4">
        <div
          ref={listRef}
          className="flex relative max-tablet:mr-[72px] gap-2 items-center text-[#a3a3a3] mr-[30px] leading-[27px] text-[18px] font-[400px]"
        >
          <span
            onClick={toggler ? closeLocationList : openLocationList}
            className="w-[24px] h-[24px] rounded-full bg-[#a3a3a3] overflow-hidden"
          >
            <img src={getCountryFlag(currentLocation?.state)} alt="" />
          </span>
          <span
            className="cursor-pointer"
            onClick={toggler ? closeLocationList : openLocationList}
          >
            {currentLocation?.state}
          </span>
          <FiChevronDown />
          {toggler && (
            <div
              className={`absolute transition-all ease-in-out ${
                delayToggler ? "translate-y-0" : "translate-y-[-20px]"
              } top-[30px] text-[14px] px-[4] flex flex-col transparent_bg w-[150px] p-2 rounded-[14px] `}
            >
              {locations?.map((l, n) => {
                return (
                  <span
                    onClick={() => {
                      setLocationId(l.id);
                      changeLocation(l);
                    }}
                    className="cursor-pointer flex"
                    key={n}
                  >
                    {l.state}
                    {loading && l.id === locationId && (
                      <Loader size={[20, 20]} color={"#a3a3a3"} />
                    )}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <PageLink path={"menu"} background={""} />
        <PageLink
          path={"Buy For A Friend"}
          to={"buy-for-a-friend"}
          style={
            localStorage.forAFriend
              ? { background: "#fff", color: "#FF0018", padding: "18px 32px" }
              : {}
          }
        />
      </div>
      <div className="tablet:col-span-4 mini-tablet:col-span-5 col-span-6 mini-tablet:flex hidden items-center justify-end gap-[32px]">
        <div className="flex items-center gap-3">
          <CartContainer />
          <SmallForFriendFloater />
        </div>
        <SidebarContainer position={"right"} ref={sidebarRef}>
          <span className="min-w-[44px] max-w-[44px] w-[44px]  h-[44px] mr-[24px] flex cursor-pointer justify-center items-center rounded-full bg-[rgba(255,255,255,0.2)]">
            <img
              src={user?.avatar?.url}
              className="w-full h-full rounded-full"
              alt=""
            />
          </span>
          <div className="flex flex-col">
            <span className="capitalize whitespace-nowrap text-[#ffffff] font-[400] text-[16px] leading-[24px] opacity-[0.85]">
              {user?.first_name} {user?.last_name[0]}.
            </span>
            <span className=" capitalize text-[12px] leading-[18px] opacity-[0.65] text-[#ffffff]">
              {location?.state} {location?.area}
            </span>
          </div>
        </SidebarContainer>
      </div>
      <div className="mini-tablet:hidden relative">
        <div className="flex items-center gap-3">
          <CartContainer />
          <SmallForFriendFloater />
        </div>
      </div>
    </div>
  );
};

export default UserNavbar;
