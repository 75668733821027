import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "../components/ui/PageLoader";
import { appService } from "../../middle-ware/Services/appService";
// import { appService } from "../hooks/Services/appService";

const VerifyPayment = () => {
  const navigate = useNavigate();
  const service = new appService();

  const nextStep = (id) => {
    // triggerConversion();
    if (localStorage.guestUser) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("guestUser");
      localStorage.removeItem("localCart");
      navigate(`/orders/tracking/${id}`);
    } else {
      navigate("/orders");
    }
  };

  const verifyPayment = async () => {
    const res = await service.getCart();
    if (res.message === "success") {
      nextStep(res.data?.id);
    } else {
      setTimeout(() => {
        verifyPayment();
      }, 10000);
    }
  };
  useEffect(() => {
    verifyPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="">
      <PageLoader>
        <span className="text-white">Verifying payment</span>
      </PageLoader>
    </div>
  );
};

export default VerifyPayment;
