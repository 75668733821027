import React, { Fragment } from "react";
import vector from "../../../assets/images/phoneui-image.png";
import flag from "../../../assets/images/logo.png";
import { BsApple } from "react-icons/bs";

const MobilePreview = () => {
  return (
    <Fragment>
      <div className=" bg-[#B8000A] min-h-[401px] w-full max-mobile:block hidden">
        <div className="grid grid-cols-12 gap-[32px] min-h-[401px] h-full max-w-[1290px]  w-full m-auto px-[32px]">
          <div className="col-span-6 flex items-center h-full">
            <div className="flex flex-col max-w-[597px]">
              <span className="mini-tablet:text-[48px] text-[32px] mini-tablet:leading-[72px] leading-[48px] font-[600] text-[#ffffff] ">
                The Driver App
              </span>
              <span className="mini-tablet:text-[28px] text-[18px] mini-tablet:leading-[42px] leading-[27px] font-[400] text-[#ffffff] opacity-[0.85]">
                Easy to use and reliable, the app was built for drivers.
              </span>
            </div>
          </div>
          <div className="col-span-6 h-full flex items-center ">
            <img
              src={vector}
              className="w-[573px]  min-h-[401px] h-fit"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="max-mobile:hidden flex flex-col gap-[24px] w-full max-mobile:px-[35px] px-[16px] ">
        <div className="flex gap-[16px] items-center">
          <img
            src={flag}
            className="w-[72.25px] h-[72.25px] rounded-[16px]"
            alt=""
          />
          <div className="flex flex-col">
            <span className="text-[24px] leading-[36px] font-[600] text-[#ffffff]  ">
              The Driver App
            </span>
            <span className="text-[#ffffff] text-[16px] leading-[24px] font-[400] opacity-[0.65]">
              Easy to use and reliable, the app was built for drivers
            </span>
          </div>
        </div>
        <span className="flex transparent_bg3 justify-center items-center gap-[8px] p-[16px] rounded-[16px] ">
          <BsApple className="text-[20px] text-[#ffffff]" />
          <span className="text-[#ffffff] text-[17.7px] leading-[22px] font-[600]">
            Get the app
          </span>
        </span>
      </div>
    </Fragment>
  );
};

export default MobilePreview;
