import React from "react";
import { useNavigate } from "react-router-dom";

const Category = ({ img, title, Icon }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        title === "And More" ? navigate(`/menu `) : navigate(`/menu/${title}`)
      }
      className={`mobile:py-[16px]  cursor-pointer py-[10px] p-[16px] rounded-[16px] mobile:min-w-[184px]  min-w-[164px] w-full backdrop-blur-[20px] transparent_cat_bg hover:opacity-[5] light-shadow flex mobile:gap-[16px] gap-[10px] items-center`}
    >
      {img ? (
        <img src={img} className="w-[24px] h-[24px] rounded-full" alt="" />
      ) : (
        <span className="w-[24px] flex justify-center items-center h-[24px] rounded-full bg-[#FFFFFF]">
          <Icon className="text-[#8F8F8F]" />
        </span>
      )}
      <span className="border-l border-[#ffffff1a] h-[30px] w-[1px]"></span>
      <span className="mobile:text-[18px] text-[16px] mobile:leading-[27px] leading-[24px] font-[400] whitespace-nowrap truncate text-[#ffffff]">
        {title}
      </span>
    </div>
  );
};

export default Category;
