import React, { useEffect } from "react";
import home from "../../../../assets/icons/l-home2.png";
import Footer from "../../footer/Footer";
import { useNavigate } from "react-router-dom";

import { useNavbar } from "../../../hooks/useNavbar";
import { useNavbar as Hook } from "../../navbar/hooks/useNav";

import FloatingCart from "../../navbar/utils/FloatingCart";
import {ForFriendFloater} from "../../navbar/utils/ForFriendFloater";

const LayoutOne = ({ children, title, Path }) => {
  const navigate = useNavigate();
  const { numOfItems } = Hook();
  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="max-w-[1440px] w-full m-auto max-mobile:px-[35px] px-[16px] ">
      {useNavbar()}
      <div className="max-w-[1288px] m-auto py-[24px]">
        <div className="flex gap-[18px] mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] text-[#ffffff] font-[400] items-center ">
          <img
            src={home}
            className="mobile:w-[20px] w-[13px] mobile:h-[19.9px] h-[13px]"
            alt=""
          />
          <span className="mobile:text-[18px] text-[14px] mobile:leading-[27px] leading-[21px] text-[#ffffff] ">
            /
          </span>
          <span>{title}</span>
          {Path && <Path />}
        </div>
      </div>
      {children}
      <Footer />
      <div className="fixed bottom-[50px] z-[999999999] flex flex-col gap-3 mobile:right-[50px] right-[25px] w-[50px] h-fit">
        {numOfItems > 0 && <FloatingCart />}
        <ForFriendFloater />
      </div>
    </div>
  );
};

export default LayoutOne;
