import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppProviders from "./middle-ware/StateContext/AppProvider";
import { Toaster } from "react-hot-toast";
import {BrowserRouter as Router,} from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AppProviders>
      <App />
      <Toaster />
    </AppProviders>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
