import React, { useEffect, useRef, useState } from "react";
// import search from "../../assets/icons/l-search.png";
import PriceRangeSlider from "../ui/PriceRangeSlider/PriceRangeSlider";
import { IoClose } from "react-icons/io5";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";
import { useParams } from "react-router-dom";

const CatalogueModal = ({
  handleProducts,
  onClose,
  open,
  min,
  max,
  setMax,
  setMin,
  rangeProducts,
}) => {
  const context = useDataContext();
  const { categories } = context;
  const route = useParams();
  const [target, setTarget] = useState(route["*"].replace("%20", " "));
  const modalRef = useRef();
  // window.addEventListener("click", (e) => {
  //   if (open && !modalRef.current?.contains(e.target)) onClose();
  // });
  // console.log(open);
  useEffect(() => {
    rangeProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);
  return (
    <div
      ref={modalRef}
      className="w-full relative max-mobile:h-[612px] h-[712px] rounded-[24px] max-w-[630px]  bg-[#151515]"
    >
      <div className="mobile:h-[76px] border-b border-[rgba(255,255,255,0.1)] h-[68px] justify-center text-[#ffffff] flex items-center">
        <IoClose
          onClick={onClose}
          className="text-[24px] cursor-pointer absolute left-[24px] t"
        />
        Filter
      </div>
      <div className="p-[24px] flex flex-col gap-[42px]">
        <div className="flex flex-col ">
          <span className=" font-[400] text-[#ffffff] text-[18px] leading-[27px]">
            Price
          </span>
          <span className="pb-[24px] text-[#FFFFFF] opacity-[0.65] font-[400] text-[14px] leading-[21px]">
            The average price is 3,000 NGN
          </span>
          <PriceRangeSlider
            min={min}
            max={max}
            setMin={setMin}
            setMax={setMax}
            rangeProducts={rangeProducts}
          />
        </div>
        <div className="flex flex-col gap-[20px] ">
          <span className="text-[#FFFFFF] font-[600] text-[18px] leading-[27px]">
            Catalogue
          </span>
          <div className="grid max-mobile:grid-cols-2 grid-cols-1 max-mobile:gap-[24px] gap-[16px]">
            {categories.map((c, n) => {
              return (
                <div
                  key={n}
                  onClick={() => {
                    setTarget(c?.title);
                    handleProducts(c.title, c.id);
                    onClose();
                  }}
                  className="flex flex-nowrap cursor-pointer gap-[8px] items-center flex-[0.5]"
                >
                  <img
                    src={c?.image.url}
                    className="w-[24px] h-[24px] rounded-full"
                    alt=""
                  />
                  <span
                    className={`text-[18px] whitespace-nowrap transition-all ease-in-out duration-500 leading-[27px] ${
                      target === c?.title ? "text-[#FF0018]" : "text-[#ffffff]"
                    } font-[400]`}
                  >
                    {c?.title}
                  </span>
                  <span
                    className={`text-[18px] transition-all ease-in-out duration-500 leading-[27px] ${
                      target === c?.title ? "text-[#FF0018]" : "text-[#ffffff]"
                    } font-[400]`}
                  >
                    {c.product_count ? `` : ""}
                    {/* (${c.product_count}) */}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogueModal;
