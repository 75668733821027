import React from "react";
import anchor from "../../../assets/icons/anchor.svg";
import { FaEllipsisH } from "react-icons/fa";
import ChatCard from "./ChatCard";

const Support = () => {
  return (
    <div className="h-[667px] w-full flex flex-col">
      <div className="flex flex-col">
        <span className="max-mobile:text-[32px] text-[24px] leading-[48px] font-[500] text-[#ffffff]">
          Live Support
        </span>
        <span className="text-[18px] leading-[27px] font-[400] text-[#ffffff] opacity-[0.65] ">
          Message us we’ll get back to you immediately
        </span>
      </div>
      <div className="flex h-[591px] relative w-fullflex flex-col">
        <div className="h-[516px] gap-[16px] text-area overflow-auto flex flex-col justify-end w-full py-[24px] relative">
          <ChatCard external={true} />
          <ChatCard />
        </div>
        <div className="h-[75px] justify-between p-[18px] px-[24px] w-full border-[0.65px] rounded-[24px] border-[#ffffff17] flex items-center">
          <textarea
            rows={1}
            cols={1}
            type="text"
            placeholder="Type a message here..."
            className="w-[80%] text-area text-[#ffffff] placeholder:text-[#ffffff] placeholder:opacity-[0.65] text-[18px] leading-[27px] font-[400] opacity-[0.65] outline-0 border-none bg-transparent"
          />
          <div className="flex items-center gap-[22px]">
            <span className="border-[1.5px] w-[20px] h-[20px] border-[#ffffff] rounded-full flex justify-center items-center">
              <img src={anchor} alt="" />
              <input
                className="w-[20px] h-[20px] absolute opacity-0"
                type="file"
              />
            </span>
            <FaEllipsisH className="text-[18px] text-[#ffffff]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
