import React, { useEffect } from "react";
import CatalogueList from "../components/catalogue/CatalogueList";
import MiniProductCard from "../components/ui/MiniProductCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import ProductsSlide from "../components/ui/ProductsSlide";
import MobilePreview from "../components/general/MobilePreview";
import LayoutOne from "../components/ui/layouts/LayoutOne";
import search_ from "../../assets/icons/l-search.svg";
import list from "../../assets/icons/list.svg";
import MobileProductCard from "../components/ui/MobileProductCard";
import CatalogueModal from "../components/modals/CatalogueModal";
import PageLoader from "../components/ui/PageLoader";
import { useCatalogue } from "../hooks/useCatalogue";
import { useNavigate } from "react-router-dom";
import { VscSearchStop } from "react-icons/vsc";
import { IoClose } from "react-icons/io5";
import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import AppLoader from "../components/ui/AppLoader";
import Loader from "../components/ui/Loader";
import { useFetchData } from "../hooks/useFetchData";

const Catalogue = () => {
  const {
    category,
    products,
    modal,
    setModal,
    allowedIndex,
    pages,
    currentPge,
    setCurrent,
    slice,
    setSlice,
    max,
    setMax,
    min,
    setMin,
    rangeProducts,
    handleProducts,
    search,
    searchResults,
    vissible,
    handleSearch,
    closeSearch,
  } = useCatalogue();

  const { dealsOfTheDay, fetchingProducts, fetchingAllProducts } =
    useDataContext();
  const { loading, getAllCategories, getData, getAllProducts } = useFetchData();

  useEffect(() => {
    getAllCategories();
    getData();
    // getAllProducts();
  }, []);

  const navigate = useNavigate();
  const currency = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress).currency
    : "";

  return !products ? (
    <PageLoader transparent={true} />
  ) : (
    <LayoutOne title={"Menu"} Path={undefined}>
      <div className="w-full max-w-[1288px] gap-[30px] m-auto max-mobile:grid grid-cols-12 ">
        <div className="tablet:col-span-4 tablet:block hidden">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <CatalogueList
              min={min}
              setMin={setMin}
              max={max}
              setMax={setMax}
              handleProducts={handleProducts}
              rangeProducts={rangeProducts}
              currency={currency}
            />
          )}
        </div>

        <div className="tablet:col-span-8 col-span-12 ">
          <span className="text-[18px] tablet:hidden block leading-[27px] font-[600] text-[#ffffff] ">
            Refine your search
          </span>
          <div className="tablet:hidden relative flex  bg-[#ffffff17] my-[32px] items-center max-mobile:p-[16px] p-[4px] max-mobile:rounded-[32px] rounded-[16px]">
            <span
              onClick={closeSearch}
              className="min-w-[42px] cursor-pointer rounded-[16px] h-[42px] mr-[4px] transparentBtn flex justify-center items-center"
            >
              {search === "" ? (
                <img src={search_} className="w-[24px] h-[24px]" alt="" />
              ) : (
                <IoClose color="#fff" className="text-[#fff] text-[24px]" />
              )}
            </span>
            <input
              placeholder="what do you want to eat?"
              className="w-full text-[12px] font-[400] placeholder:text-[#666666] text-[#9e9e9e] px-[20px] outline-none bg-transparent autofill:bg-inherit "
              type="text"
              value={search}
              onChange={handleSearch}
            />
            <span
              onClick={() => setModal(true)}
              className="min-w-[42px] cursor-pointer red_btn_shadow rounded-[16px] h-[42px] mr-[4px] bg-[#FF0018] flex justify-center items-center"
            >
              <img src={list} className="w-[24px] h-[24px]" alt="" />
            </span>
            {search !== "" && (
              <div
                className={`absolute transition-all w-full left-0 ease-in-out duration-100 ${
                  !vissible ? " translate-y-[30px]" : "translate-y-0"
                } top-[80px] rounded-[16px] max-h-[460px] h-fit overflow-y-auto bg-[#353434] z-[99999] p-[20px] flex flex-col gap-[19px]`}
              >
                <span className="text-[#FF0018] text-[18px] leading-[36px] font-[600]">
                  Search Results ({searchResults.length})
                </span>
                {searchResults.length > 0 ? (
                  searchResults?.map((result, n) => (
                    <div
                      key={n}
                      onClick={() =>
                        navigate(
                          `/product/${result?.category?.name}/${result?.title}/${result?.id}`
                        )
                      }
                      className="flex cursor-pointer gap-[8px] items-center"
                    >
                      <span className="w-[24px] overflow-hidden">
                        <img
                          src={result?.image?.url}
                          className="w-fit h-[24px] rounded-full"
                          alt=""
                        />
                      </span>
                      <span
                        className={`text-[16px] whitespace-nowrap truncate transition-all ease-in-out duration-500 leading-[27px] ${"text-[#ffffff]"} font-[400]`}
                      >
                        {result.title}
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="min-h-[250px] flex w-full justify-center items-center">
                    <span className="bg-[#525252] w-[100px] h-[100px] flex justify-center items-center rounded-full">
                      {fetchingAllProducts ? (
                        <Loader size={[30, 30]} color={"red"} />
                      ) : (
                        <VscSearchStop className="text-[#fff] text-[30px] " />
                      )}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center">
            <span className="text-[#FFFFFF] max-mobile:text-[24px] text-[16px] max-mobile:leading-[36px] leading-[24px] font-[300] ">
              {category?.title}
              {category?.product_count ? `` : ""}
              {/* (${category?.product_count}) */}
            </span>
          </div>
          {fetchingProducts ? (
            <div className="max-mobile:flex w-full h-full justify-center items-center hidden">
              <AppLoader contain={true} />
            </div>
          ) : (
            <div className="max-mobile:grid hidden grid-cols-12 mt-[22px] desktop:gap-[29px] gap-[16px]">
              {products?.length > 0 &&
                products
                  .slice(
                    (currentPge - 1) * allowedIndex,
                    allowedIndex * currentPge
                  )
                  .map((p, n) => (
                    <div key={n} className="mini-tablet:col-span-4 col-span-6">
                      <MiniProductCard product={p} />
                    </div>
                  ))}
            </div>
          )}
          <div className="max-mobile:flex hidden w-full justify-between items-center">
            <span
              onClick={() => currentPge > 1 && setCurrent(currentPge - 1)}
              className={`flex cursor-pointer transition-all ease-in-out duration-500 underline items-center gap-[19px] text-[18px] leading-[27px] font-[600] ${
                currentPge !== 1 ? "text-[#FF0018]" : "text-[#ff00194d]"
              }`}
            >
              <MdKeyboardArrowLeft />
              Previous
            </span>
            <div className="flex text-[18px] leading-[27px] text-[#ffffff] gap-[16px] items-center my-[24px] ">
              <span>Page</span>
              <span className="w-[40px] h-[40px] flex justify-center items-center border-[0.65px] border-[#ffffff] text-[18px] leading-[27px] rounded-[8px]">
                {currentPge}
              </span>
              <span>of</span>
              <span>{pages}</span>
            </div>
            <span
              onClick={() => currentPge < pages && setCurrent(currentPge + 1)}
              className={`flex cursor-pointer transition-all ease-in-out duration-500 underline items-center gap-[19px] text-[18px] leading-[27px] font-[600] ${
                currentPge !== pages ? "text-[#FF0018]" : "text-[#ff00194d]"
              }`}
            >
              Next
              <MdKeyboardArrowRight />
            </span>
          </div>
          <div className="max-mobile:hidden flex flex-col mt-[32px] gap-[24px]">
            {fetchingProducts ? (
              <div className=" w-full h-full min-h-[450px] justify-center items-center flex">
                <AppLoader contain={true} />
              </div>
            ) : (
              products?.length > 0 &&
              products?.slice(0, slice).map((p, n) => {
                return (
                  <div key={n}>
                    <MobileProductCard
                      title={p?.title}
                      img={p?.image?.url}
                      description={p?.description}
                      currency={currency}
                      price={p?.product_price?.price}
                      id={p.id}
                      product={p}
                    />
                  </div>
                );
              })
            )}
            <span
              onClick={() => slice < products?.length && setSlice(slice + 3)}
              className="text-center text-[#ff0018] mb-[16px] underline cursor-pointer text-[12px] leading-[21px]"
            >
              Show more
            </span>
          </div>
        </div>
      </div>
      {modal && (
        <div className="fixed h-screen z-[9999] w-full  top-0 left-0 right-0 min-h-screen flex justify-center  overscroll-y-auto bg-[rgba(0,0,0,0.6)]">
          <div className="h-[screen] py-[100px] textArea px-[16px] flex justify-center w-full overflow-auto ">
            <CatalogueModal
              min={min}
              setMin={setMin}
              max={max}
              setMax={setMax}
              handleProducts={handleProducts}
              onClose={() => setModal(false)}
              active={category}
              rangeProducts={rangeProducts}
              open={modal}
            />
          </div>
        </div>
      )}
      <div className="max-mobile:py-[100px] w-full">
        <ProductsSlide title={"Deals of the day"} products={dealsOfTheDay} />
      </div>
      <div className="max-mobile:pb-[0px] pb-[16px] w-full">
        <MobilePreview />
      </div>
    </LayoutOne>
  );
};

export default Catalogue;
