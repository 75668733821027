import React, { useState } from "react";
import logo from "../../../../assets/images/logo.png";
import donut from "../../../../assets/icons/donut.svg";
import love from "../../../../assets/icons/love.svg";
import order from "../../../../assets/icons/order.svg";
import play from "../../../../assets/vectors/GooglePlay.svg";
import apple from "../../../../assets/vectors/AppStore.svg";
import code from "../../../../assets/icons/discount.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSidebar } from "../hooks/useSidebar";
import { appService } from "../../../../middle-ware/Services/appService";
import Loader from "../../ui/Loader";

const ProfileBar = () => {
  const { closeSidebar, user, location } = useSidebar();
  const navigate = useNavigate();
  const service = new appService();
  const [discountValue, setDiscountValue] = useState("");
  const [discount, setDiscount] = useState(false);
  const [discountDelay, setDiscountDelay] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDiscount = (e) => {
    setDiscountValue(e.target.value);
  };
  const toggleDiscount = () => {
    if (discount) {
      setDiscountDelay(false);
      setTimeout(() => {
        setDiscount(false);
      }, 10);
    } else {
      setDiscount(true);
      setTimeout(() => {
        setDiscountDelay(true);
      }, 10);
    }
  };
  const saveDiscount = async () => {
    if (discountValue !== "") {
      setLoading(true);
      await service.attachDiscount(discountValue);

      setLoading(false);
      toggleDiscount();
    }
  };

  return (
    <div className="w-[317px] min-h-[1024px] h-screen bg-[#151515]">
      <div className="w-full mini-tablet:h-[773px] h-[716px] border-b-[2px] border-[rgba(255,255,255,0.1)] pt-[75px] px-[24px]">
        <div className="flex items-center gap-[16px]">
          <img
            src={user?.avatar?.url}
            className=" w-[90px] h-[90px] rounded-full "
            alt=""
          />
          <div className="flex flex-col gap-[8px] justify-between">
            <div className="flex flex-col ">
              <span className="capitalize text-[#ffffff] font-[600] text-[18px] leading-[27px] opacity-[0.85]">
                {user?.first_name} {user?.last_name[0]}.
              </span>
              <span className="text-[#ffffff] text-[14px] leading-[21px] font-[400] opacity-[0.65]">
                {location.state}, {location.area}.
              </span>
            </div>
            <span
              onClick={() => {
                navigate("/profile");
                closeSidebar();
              }}
              className="text-[#FF0018] text-[16px] leading-[24px] font-[500] cursor-pointer"
            >
              View account
            </span>
          </div>
        </div>
        <div className="flex flex-col mt-[96px] gap-[20px]">
          <Link
            to="/help"
            className="flex border-l-[4px] border-[transparent] gap-[18px] transparent_hoverbg items-center w-[193px] cursor-pointer px-[16px] py-[10px]"
          >
            <img src={donut} alt="" />
            <span className="text-[16px] text-[#ffffff] leading-[24px] font-[500]">
              Help
            </span>
          </Link>
          <Link
            to={"/favourites"}
            className="flex border-l-[4px] border-[transparent] gap-[18px] transparent_hoverbg items-center w-[193px] cursor-pointer px-[16px] py-[10px]"
          >
            <img src={love} alt="" />
            <span className="text-[16px] text-[#ffffff] leading-[24px] font-[500]">
              Favorites
            </span>
          </Link>
          <div className="relative">
            <div className="flex border-l-[4px] border-[transparent] gap-[18px] transparent_hoverbg items-center w-[193px] cursor-pointer px-[16px] py-[10px]">
              <img src={code} alt="" />
              <span
                onClick={toggleDiscount}
                className="text-[16px] whitespace-nowrap text-[#ffffff] leading-[24px] font-[500]"
              >
                Discount Code
              </span>
            </div>
            {discount && (
              <div
                className={`absolute z-[999999999] transition-all duration-100 ${
                  discountDelay ? "translate-y-0" : "translate-y-[-10px]"
                } left-0 gap-3 p-3 rounded-[10px] top-[50px] bg-gray-300 p-2 flex flex-col`}
              >
                <span className="border-2 h-[40px] flex  relative p-2 rounded-[10px] border-[#000]">
                  <span
                    className={`text-[10px] transition-all ${
                      discountValue !== "" ? "opacity-1" : "opacity-0"
                    } duration-100 trans text-[#000] absolute top-[-8px] bg-gray-300`}
                  >
                    Discount Code
                  </span>

                  <input
                    type="text"
                    placeholder="Discount code"
                    value={discountValue}
                    onChange={handleDiscount}
                    className="bg-transparent text-[14px] outline-none"
                  />
                </span>
                <span
                  onClick={saveDiscount}
                  className="w-full bg-[#FF0018] h-[40px] text-[#fff] rounded-[10px] cursor-pointer flex justify-center items-center"
                >
                  {loading ? <Loader color={"#fff"} size={[16, 16]} /> : "Save"}
                </span>
              </div>
            )}
          </div>
          <Link
            to="/orders"
            className="flex border-l-[4px] border-[transparent] gap-[18px] transparent_hoverbg items-center w-[193px] cursor-pointer px-[16px] py-[10px]"
          >
            <img src={order} alt="" />
            <span className="text-[16px] whitespace-nowrap text-[#ffffff] leading-[24px] font-[500]">
              Order History
            </span>
          </Link>
          {/* <span className="flex border-l-[4px] border-[transparent] gap-[18px] transparent_hoverbg items-center w-[193px] cursor-pointer px-[16px] py-[10px]">
            <img src={chat} alt="" />
            <span className="text-[16px] text-[#ffffff] leading-[24px] font-[500]">
              Live Support
            </span>
          </span> */}
          {/* <span className="flex border-l-[4px] border-[transparent] gap-[18px] transparent_hoverbg items-center w-[193px] cursor-pointer px-[16px] py-[10px]">
            <img src={friends} alt="" />
            <span className="text-[16px] text-[#ffffff] leading-[24px] font-[500]">
              Invite Friends
            </span>
          </span> */}
          <span
            onClick={() => {
              localStorage.clear();
              navigate("/user-location");
            }}
            className=" z-[1] items-center cursor-pointer px-[16px] py-[10px]"
          >
            <span className="text-[16px] transition-all ease-in-out duration-300 text-[#ffffff] hover:text-[#FF0018] opacity-[0.65] leading-[24px] font-[500]">
              Sign out
            </span>
          </span>
        </div>
      </div>
      <div className="flex flex-col px-[24px] gap-[28px] w-full py-[48px]">
        <div className="flex gap-[16px] items-center">
          <img
            src={logo}
            className="w-[72.25px] h-[72.25px] rounded-[16px]"
            alt=""
          />
          <span className="text-[#ffffff] w-[163px] text-[16px] leading-[24px] font-[500]">
            There’s more to love in the app.
          </span>
        </div>
        <div className="flex gap-x-[16px]">
          <img
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=com.theeveafterdark.customer")
            }
            src={play}
            className=" w-[128px] h-[45px] rounded-[100px]  "
            alt=""
          />
          <img
            onClick={() =>
              (window.location.href =
                "https://apps.apple.com/gb/app/eve-after-dark/id6450600671")
            }
            src={apple}
            className=" w-[128px] h-[45px] rounded-[100px]  "
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
