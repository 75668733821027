import React, { useState } from "react";
// import { BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { appService } from "../../../middle-ware/Services/appService";
// import { useDataContext } from "../../hooks/StateContext/DataContext";
import { useFlowContext } from "../../../middle-ware/StateContext/FlowContext";
import Loader from "./Loader";
import { useClipper } from "../../hooks/useClipper";
import Images from "./Images";
import Prices from "./Prices";

const MiniProductCard = ({ product }) => {
  const navigate = useNavigate();
  // const appContext = useDataContext();
  const flowContext = useFlowContext();

  const service = new appService();

  const [loading, setLoading] = useState(false);

  const cart = flowContext?.cart?.items?.filter(
    (i) => i.product.id === product?.id
  );
  const { setCartModalView, setOpenModal, setCartModalItem } = flowContext;
  const addToCart = async () => {
    setLoading(true);
    if (localStorage.token) {
      const res = await service.addToCart(product.id, 1);
      if (res.message === "success") {
        service.getCart();
        if (
          product?.has_related_products ||
          product?.has_spice ||
          product?.has_allergiess
        ) {
          setCartModalView(true);
          setOpenModal(true);
          setCartModalItem(product);
        }
      }
    } else {
      service.addToLocalCart(product, 1);
      service.getLocalCart();
      if (
        product?.has_related_products ||
        product?.has_spice ||
        product?.has_allergiess
      ) {
        setCartModalView(true);
        setOpenModal(true);
        setCartModalItem(product);
      }
    }
    setLoading(false);
  };

  return (
    <div className="h-[350px] transparent_bg backdrop-blur-[15.7px] rounded-[18px] p-[16px] gap-[16px] bg-blue-100 flex flex-col items-center">
      <span
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          navigate(
            `/product/${product?.category?.name}/${product?.title}/${product?.id}`
          );
        }}
      >
        <Images
          classes={"w-[160.98px] h-[160.98px] rounded-full filter"}
          url={product?.image?.url}
          width={"160.98px"}
          height={"160.98px"}
          radius={"100%"}
        />
      </span>
      <div className=" flex flex-col gap-[7.83px] px-[16px]">
        <span
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            navigate(
              `/product/${product?.category?.name}/${product?.title}/${product?.id}`
            );
          }}
          className="text-center cursor-pointer whitespace-nowrap truncate max-tablet:max-w-[200px] max-w-[180px] mx-auto text-[17.5px] leading-[26px] font-[500] text-[#ffffff] opacity-[0.85] "
        >
          {product?.title}
        </span>
        {/* <div className="flex justify-center gap-[14.6px]">
          <BsStarFill className="text-[10.7px] text-[#ffa503]" />
          <BsStarFill className="text-[10.7px] text-[#ffa503]" />
          <BsStarFill className="text-[10.7px] text-[#ffa503]" />
          <BsStarFill className="text-[10.7px] text-[#ffa503]" />
          <BsStarFill className="text-[10.7px] text-[#ffa503]" />
        </div> */}
        <div className="h-[40px] text-[13px] leading-[20px] overflow-hidden text-ellipsis text-center text-[#FFFFFF] opacity-[0.65]">
          <span className=" text-ellipsis">
            {useClipper(product.description, 60)}
          </span>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <span className="">
          <Prices
            amount={product?.product_price?.price}
            currency={product?.product_price?.currency}
            height={14}
            classes={
              "text-[14px] text-[#ffffff] opacity-[0.85] leading-[22px] font-[600]"
            }
          />
        </span>
        <span
          onClick={() => addToCart()}
          className={`${
            cart && cart?.length > 0
              ? "text-[#ffffff] bg-[#ad0010] "
              : "text-[#ff0018]"
          } transition-all ease-in-out duration-500 min-w-[90px] text-center hover:bg-[#ff0018] hover:text-[#ffffff] cursor-pointer text-[10.7px] border border-[#ff0018] p-[7px] rounded-[100px]`}
        >
          {loading ? (
            <Loader color={"#ffffff"} size={[20, 20]} />
          ) : cart && cart?.length > 0 ? (
            "In Cart"
          ) : (
            "Add To Cart"
          )}
        </span>
      </div>
    </div>
  );
};

export default MiniProductCard;
