import React from "react";
import { AuthContextProvider } from "./AuthContext";
import { DataContextProvider } from "./DataContext";
import { FlowContextProvider } from "./FlowContext";

const AppProviders = ({ children }) => {
  return (
    <AuthContextProvider>
      <DataContextProvider>
        <FlowContextProvider>{children}</FlowContextProvider>
      </DataContextProvider>
    </AuthContextProvider>
  );
};

export default AppProviders;
