import React from "react";
import h1 from "../../../assets/images/topLHS-food-image.png";
import h2 from "../../../assets/images/topRHS-food-image.png";
import { useNavbar } from "../../hooks/useNavbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import Content1 from "./slideComponents/Content1";

const Header = () => {
  return (
    <div className="w-full mini-tablet:h-[1024px] mobile:h-[703px] min-h-[479px] h-fit max-mobile:px-[35px] px-[16px]  relative">
      {useNavbar()}
      <div className="h-full min-h-fit relative">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={24}
          slidesPerView={1}
          style={{ height: "100%", minHeight: "400px", position:"relative !important" }}
          pagination={{
            clickable: true,
            el: ".swiper-custom-pagination",
          }}
          loop={true}
          speed={2000}
          autoplay={{ delay: 500 }}
        >
          {[0, 1, 2].map((_l, n) => (
            <SwiperSlide key={n}>
              <Content1 />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-custom-pagination"/>
      </div>
      <img
        src={h1}
        className="absolute left-0 mini-tablet:bottom-[91px] bottom-[37px] mini-tablet:max-w-[236px] max-w-[170px] w-full max-mobile:block hidden "
        alt=""
      />
      <img
        src={h2}
        className="absolute right-0 bottom-0  mini-tablet:max-w-[236px] max-w-[170px] w-full max-mobile:block hidden  "
        alt=""
      />
    </div>
  );
};

export default Header;
