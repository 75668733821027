import React, { useEffect, useState } from "react";
// import rider from "../../assets/images/rider.svg";
import { appService } from "../../../middle-ware/Services/appService";
import Rating from "./Rating";

const DeliveryRating = ({ processed, info }) => {
  const [politeness, setPoliteness] = useState(0);
  const [handling, setHandling] = useState(0);
  const [appearance, setAppearance] = useState(0);
  const service = new appService();
  const [slide, setSlide] = useState(false);
  const [view, setView] = useState(false);
  console.log(info);
  const rider = {
    id: info?.rider_id,
    first_name: info?.rider?.first_name,
    last_name: info?.rider?.first_name,
    email: info?.rider?.email,
    phone: info?.rider?.phone,
    image: info?.rider?.image,
    available_region_id: "",
  };
  // const getRider = async () => {
  //   const res = await service.getRider(info?.rider_id);
  //   if (res.message === "success") setRider(res.data);
  // };
  useEffect(() => {
    // getRider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`sm-tablet:max-w-[358px] transition-all duration-500 w-full items-center bg-[#151515] p-[20px] rounded-[18px] flex flex-col gap-[25px]`}
    >
      <span
        onClick={() => {
          setSlide(!slide);
          view ? setTimeout(() => setView(!false), 100) : setView(true);
        }}
        className="m-auto sm-tablet:hidden block w-[50px] h-[5px] bg-[rgba(255,255,255,0.2)] rounded-[100px]"
      ></span>
      <div className="flex flex-col text-center border-b-[0.65px] border-[#ffffff17] max-mobile:pb-[24px] pb-[16px]">
        <span className="text-[#00BA80] text-[14px] leading-[21px] font-[500]">
          Package Delivered
        </span>
        <span className="text-[#ffffff] opacity-[0.5] text-[12px] leading-[18px] font-[500]">
          Your order has been delivered successfully
        </span>
      </div>
      <div className="flex flex-col text-center ">
        <span className="text-[#ffffff] text-[14px] leading-[21px] font-[500]">
          Rate Dispatch Rider
        </span>
        <span className="text-[#ffffff] opacity-[0.5] text-[12px] leading-[18px] font-[500]">
          Your feedback will help us improve your experience
        </span>
      </div>
      <img
        src={rider?.image?.url}
        className="w-[60px] h-[60px] rounded-full"
        alt=""
      />
      <span className="text-center text-[14px] leading-[21px] text-[#ffffff] ">
        {rider?.first_name} {rider?.last_name}
      </span>
      <div
        className={`w-full sm-tablet:flex hidden flex-col  gap-[27px] transition-all duration-500 ${
          !slide
            ? `sm-tablet:h-fit h-fit opacity-1 `
            : `sm-tablet:h-fit h-0 opacity-0 `
        }`}
      >
        <Rating
          title={"Politeness"}
          param={politeness}
          handler={setPoliteness}
        />
        <Rating
          title={"Food Handling"}
          param={handling}
          handler={setHandling}
        />
        <Rating
          title={"Appearance"}
          param={appearance}
          handler={setAppearance}
        />
      </div>
      {!processed && (
        <span
          className={` transition-all duration-500 sm-tablet:flex hidden ${
            !slide
              ? `sm-tablet:h-[66px] h-[66px] opacity-1 `
              : `sm-tablet:h-[66px] h-0 opacity-0`
          } cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] text-[20px] red_btn_shadow leading-[30px]`}
        >
          Submit
        </span>
      )}
      {view && (
        <div
          className={`w-full flex-col sm-tablet:hidden flex  gap-[27px] transition-all duration-500 ${
            slide
              ? `sm-tablet:h-fit h-fit opacity-1 `
              : `sm-tablet:h-fit h-0 hidden opacity-0 `
          }`}
        >
          <Rating
            title={"Politeness"}
            param={politeness}
            handler={setPoliteness}
          />
          <Rating
            title={"Food Handling"}
            param={handling}
            handler={setHandling}
          />
          <Rating
            title={"Appearance"}
            param={appearance}
            handler={setAppearance}
          />
        </div>
      )}

      {!processed && view && (
        <span
          className={` transition-all duration-500 sm-tablet:hidden flex ${
            slide
              ? `sm-tablet:h-[66px] h-[66px] opacity-1 `
              : `sm-tablet:h-[66px] h-0 opacity-0`
          } cursor-pointer w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] text-[20px] red_btn_shadow leading-[30px]`}
        >
          Submit
        </span>
      )}
    </div>
  );
};

export default DeliveryRating;
