import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useFlowContext } from "../../../../middle-ware/StateContext/FlowContext";
import CartAccordion from "./utils/CartAccordion";
import hot from "../../../../assets/vectors/very_hot.svg";
import light from "../../../../assets/vectors/light_hot.svg";
import medium from "../../../../assets/vectors/medium_hot.svg";
// import bag from "../../../../assets/icons/bag.svg";
// import chicken from "../../../../assets/images/meals/proteins.png";
import { Counter, MainCounter } from "./utils/Counter";
// import Btn from "../../product/utils/Btn";
// import Button from "./utils/Button";
import icon from "../../../../assets/icons/successCheck.svg";
import icon2 from "../../../../assets/icons/tick-circle.svg";
// import { useCartModalProduct } from "./hook/useProductsInModal";
import { appService } from "../../../../middle-ware/Services/appService";
// import { useProductItem } from "../../product/hooks/useProductItem";
import Loader from "../../ui/Loader";
import { useDataContext } from "../../../../middle-ware/StateContext/DataContext";
import { toast } from "react-hot-toast";

const Protein = ({ protein, service }) => {
  const context = useFlowContext();
  const { products } = useDataContext();

  const cart = context?.cart?.items?.filter(
    (i) => i?.product?.id?.toString() === protein?.id?.toString()
  );
  const quantity = cart && cart?.length !== 0 ? cart[0]?.quantity : 0;
  const cartId = cart ? cart[0]?.id : null;

  const product = products?.filter((p) => p.id === protein.id);

  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(false);

  const addToCart = async () => {
    setLoading(true);
    if (localStorage.token) {
      const res = await service.addToCart(protein.id, 1);
      if (res.message === "success") {
        await service.getCart();
        setLoading(false);
      }
    } else {
      // console.log("test");
      service.addToLocalCart(product[0], 1);
      const res = service.getLocalCart();
      setLoading(false);
    }
    // setQuantity(quantity + 1);
  };

  const resetCart = async () => {
    if (localStorage.token) {
      const res = await service.addToCart(protein.id, 1);
      if (res.message === "success") {
        service.getCart();
      }
    } else {
      service.addToLocalCart(protein, 1);
      service.getLocalCart();
    }
  };

  const updateCart = (action) => {
    if (localStorage.token) {
      if (action === "plus" && cart?.length === 0) {
        resetCart();
      } else if (action === "plus" && cart?.length > 0) {
        service.updateCart(cartId, quantity + 1);
      } else {
        quantity > 1 && service.updateCart(cartId, quantity - 1);
      }
    } else {
      if (action === "plus") {
        service.addToLocalCart(product[0], quantity + 1);
        service.getLocalCart();
      } else {
        service.addToLocalCart(product[0], quantity - 1);
        service.getLocalCart();
      }
    }
    setLoadingCount(false);
  };
  const toPrice = (currency, amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency : "NGN",
    });
    return formatter.format(amount);
  };
  // console.log(product);

  return (
    <div className="grid grid-cols-12 justify-between items-center transparent_bg5 p-[16px] rounded-[24px]">
      <div className="sm:col-span-10 col-span-8 flex items-center gap-[16px]">
        <img
          className="sm:w-[60px] sm:h-[60px] w-[45px] h-[45px] bg-[#fff] rounded-full"
          src={protein.image.url}
          alt=""
        />
        <div className="flex flex-col">
          <span className="text-[#fff] sm:text-[20px] text-[18px] opacity-[0.85]">
            {protein.title}
          </span>
          <span className="text-[#fff] text-[14px] opacity-[0.65]">
            {toPrice(
              product[0]?.product_price?.currency,
              product[0]?.product_price?.price
            )}
          </span>
        </div>
      </div>
      <div className="sm:col-span-2 col-span-4 flex justify-end">
        {cart.length > 0 ? (
          <Counter
            quantity={quantity}
            setQuantity={updateCart}
            loadingCount={loadingCount}
          />
        ) : (
          <span
            onClick={addToCart}
            className="text-[10.63px] flex justify-center whitespace-nowrap items-center capitalize text-[#FF0018] font-[500] sm:h-[51px] h-[42px] cursor-pointer border border-[#FF0018] sm:px-[24px] px-[18px] rounded-[53.466px]"
          >
            {loading ? (
              <Loader color={"#FF0018"} size={[20, 20]} />
            ) : (
              "Add to cart"
            )}
          </span>
        )}
      </div>
    </div>
  );
};

const CartModal = () => {
  const { setCartModalView, setOpenModal, cartModalItem } = useFlowContext();
  const service = new appService();
  const [spice, setSpice] = useState("medium_hot");
  const levels = ["slightly_hot", "medium_hot", "very_hot"];

  const [loading, setLoading] = useState(false);

  const emojies = {
    very_hot: hot,
    slightly_hot: light,
    medium_hot: medium,
  };

  // const

  const updateSpice = async (level) => {
    setLoading(true);
    const getLevel = () => {
      if (level === "slightly_hot") return "low";
      else if (level === "medium_hot") return "medium";
      else return "high";
    };
    if (localStorage.token) {
      const res = await service.updateCart(cartModalItem?.id, 1, getLevel());
      if (res.message === "success") {
        toast.success("Spice level updated successfully");
        setSpice(level);
      } else toast.error("Please try again");
      setLoading(false);
    } else {
      service.addToLocalCart(cartModalItem, 1, getLevel());
      toast.success("Spice level updated successfully");
      setSpice(level);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen h-full py-[100px] pt-[130px] px-[30px] newscroll w-full  overflow-auto ">
      <div className="max-mobile:max-w-[600px]  overflow-y-auto max-mobile:h-fit h-screen max-mobile:max-h-[898px] m-auto max-mobile:relative fixed top-0 left-0 right-0 z-[99999999999] backdrop:blur-[5rem] flex flex-col gap-[24px] w-full rounded-[24px]  py-[32px] max-mobile:px-[32px] px-[16px] bg-[#151515]">
        <IoMdClose
          onClick={() => {
            setCartModalView(false);
            setOpenModal(false);
          }}
          className="  text-[#FFf] h-[32px] text-[32px] cursor-pointer"
        />
        <div className="flex items-center gap-[8px] p-[10px] rounded-[30px] bg-[rgba(0,186,128,0.10)]">
          <img className="sm:w-[40px] sm:h-[40px] w-[35px] h-[35px]" src={icon} alt="" />
          <div className="flex flex-col">
            <span className="sm:text-[18px] text-[16px] font-[600] text-[#fff]">
              Your item has been added to cart
            </span>
            <span className="sm:text-[14px] text-[12px] font-[400] text-[rgba(255,255,255,0.65)]">
              You can change the spice level or add related item to cart here
            </span>
          </div>
        </div>
        {cartModalItem?.has_spice && (
          <div className="flex flex-col gap-[16px]">
            <span className="sm:text-[20px] text-[16px] font-[600] text-[#fff] flex items-center gap-[8px]">
              🌶️ How hot do you want your meal?{" "}
              <span className="opacity-[0.65] text-[14px]">(optional)</span>
            </span>
            <div className="grid grid-cols-12 w-full sm:gap-[24px] gap-[16px]">
              {levels.map((level) => (
                <div
                  key={level}
                  onClick={() => updateSpice(level)}
                  className={`col-span-4 transparent_bg5 relative cursor-pointer rounded-[16px] flex flex-col items-center justify-center p-[8px] transition-all ease-in-out duration-200 ${
                    spice === level
                      ? "border-2 border-[#fff] opacity-1"
                      : "border-none opacity-[0.65]"
                  }`}
                >
                  <span className=" absolute top-[8px] right-[8px]">
                    {spice === level && !loading && (
                      <img className=" w-[20px] h-[20px]" src={icon2} alt="" />
                    )}
                    {loading && spice === level && (
                      <span className=" w-[20px] h-[20px] bg-white rounded-full flex items-center justify-center">
                        <Loader color={"#FF0018"} size={[18, 18]} />
                      </span>
                    )}
                  </span>
                  <img
                    src={emojies[level]}
                    className="w-[40px] h-[40px]"
                    alt=""
                  />
                  <span className="text-[#fff] capitalize sm:text-[18px] text-[14px] ">
                    {level
                      .replaceAll("_", " ")
                      .replace("medium", "medi")
                      .replace("very", "naija")
                      .replace("sligthly", "mild")}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="bg-[#ffffff6c] flex  h-[.5px] w-full"></div>
        <CartAccordion
          title={"Add-ons"}
          label={null}
          helper={"You can add your favourite items to spice up your meal!"}
          value={<></>}
        >
          <div className="flex flex-col gap-[16px] max-h-[310px] overflow-y-auto">
            {cartModalItem?.related_products &&
              cartModalItem?.related_products?.map((protein, n) => (
                <Protein key={n} protein={protein} service={service} />
              ))}
          </div>
        </CartAccordion>
      </div>
    </div>
  );
};

export default CartModal;
