import React, { useState } from "react";
import search_ from "../../../assets/icons/l-search.png";
import PriceRangeSlider from "../ui/PriceRangeSlider/PriceRangeSlider";
import { IoClose } from "react-icons/io5";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VscSearchStop } from "react-icons/vsc";
import Images from "../ui/Images";
import Loader from "../ui/Loader";

const CatalogueList = ({
  handleProducts,
  min,
  max,
  setMax,
  setMin,
  rangeProducts,
  currency,
}) => {
  const context = useDataContext();
  const navigate = useNavigate();
  const { categories, allProducts, fetchingAllProducts } = context;
  const route = useParams();
  const [target, setTarget] = useState(route["*"].replace("%20", " "));
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [vissible, setVissible] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const result = allProducts?.filter((p) => {
      const title = p.title.toLowerCase();
      const value = e.target.value.toLowerCase();
      return title.includes(value);
    });
    setSearchResults(result);
    setTimeout(() => {
      setVissible(true);
    }, 50);
  };

  // const localCart =

  const closeSearch = () => {
    setVissible(false);
    setTimeout(() => {
      setSearch("");
    }, 50);
  };

  useEffect(() => {
    rangeProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  return (
    <div className="w-full p-[24px] rounded-[24px]  border-[0.8px] border-[#ffffff80]">
      <span className="text-[18px] leading-[27px] font-[600] text-[#ffffff]">
        Refine your search
      </span>

      <div className="flex relative  bg-[#ffffff17] mt-[32px] items-center max-w-[362px] h-[50px] rounded-[16px] ">
        <input
          placeholder="what do you want to eat?"
          className="w-full text-[12px] font-[400] placeholder:text-[#666666] text-[#9e9e9e] px-[20px] outline-none bg-transparent autofill:bg-inherit "
          type="text"
          value={search}
          onChange={handleSearch}
        />
        <span
          onClick={closeSearch}
          className="w-[42px] cursor-pointer rounded-[16px] h-[42px] mr-[4px] bg-[#525252] opacity-[0.5] flex justify-center items-center"
        >
          {search === "" ? (
            <img src={search_} className="w-[16px] h-[16px]" alt="" />
          ) : (
            <IoClose color="#fff" className="text-[#fff] text-[16px]" />
          )}
        </span>
        {search !== "" && (
          <div
            className={`absolute transition-all w-full max-w-[362px] ease-in-out duration-100 ${
              !vissible ? " translate-y-[30px]" : "translate-y-0"
            } top-[57px] rounded-[16px] max-h-[400px] h-fit overflow-y-auto bg-[#353434] z-[99999] p-[20px] flex flex-col gap-[19px]`}
          >
            <span className="text-[#FF0018] text-[14px] leading-[21px] font-[400]">
              Search Results ({searchResults.length})
            </span>
            {searchResults.length > 0 ? (
              searchResults?.map((result, n) => (
                <div
                  key={n}
                  onClick={() =>
                    navigate(
                      `/product/${result?.category?.name}/${result?.title}/${result?.id}`
                    )
                  }
                  className="flex cursor-pointer gap-[8px] items-center"
                >
                  <Images
                    classes={
                      "w-[24px] min-w-[24px] max-w-[24px] h-[24px] rounded-full"
                    }
                    url={result?.image?.url}
                    width={"24px"}
                    height={"24px"}
                    radius={"100%"}
                  />
                  <span
                    className={`text-[16px] whitespace-nowrap truncate transition-all ease-in-out duration-500 leading-[27px] ${"text-[#ffffff]"} font-[400]`}
                  >
                    {result.title}
                  </span>
                </div>
              ))
            ) : (
              <div className="min-h-[100px] flex w-full justify-center items-center">
                <span className="bg-[#525252] w-[60px] h-[60px] flex justify-center items-center rounded-full">
                  {fetchingAllProducts ? (
                    <Loader size={[30, 30]} color={"red"} />
                  ) : (
                    <VscSearchStop className="text-[#fff] text-[30px] " />
                  )}
                </span>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="my-[24px]">
        <span className=" font-[400] text-[#ffffff] text-[18px] leading-[27px]">
          Price
        </span>
        <PriceRangeSlider
          min={min}
          max={max}
          setMin={setMin}
          setMax={setMax}
          rangeProducts={rangeProducts}
          currency={currency}
        />
      </div>

      <div className="flex justify-between items-center">
        <span className="text-[#FFFFFF] font-[600] text-[18px] leading-[27px]">
          Catalogue
        </span>
        <span className="text-[#FF0018] text-[14px] leading-[21px] font-[400]">
          Show more
        </span>
      </div>
      <div className="flex flex-col gap-[19px] mt-[9.5px]">
        {categories.map((category, n) => {
          return (
            <div
              key={n}
              onClick={() => {
                setTarget(category.title);
                handleProducts(category.title, category.id);
              }}
              className="flex cursor-pointer gap-[8px] items-center"
            >
              <Images
                classes={"w-[24px] h-[24px] rounded-full"}
                url={category?.image?.url}
                width={"24px"}
                height={"24px"}
                radius={"100%"}
              />
              <span
                className={`text-[18px] transition-all ease-in-out duration-500 leading-[27px] ${
                  target === category.title || (target === "" && n === 0)
                    ? "text-[#FF0018]"
                    : "text-[#ffffff]"
                } font-[400]`}
              >
                {category.title}
              </span>
              <span
                className={`text-[18px] transition-all ease-in-out duration-500 leading-[27px] ${
                  target === category.title || (target === "" && n === 0)
                    ? "text-[#FF0018]"
                    : "text-[#ffffff]"
                } font-[400]`}
              >
                {category.product_count ? `` : ""}
                {/* (${category.product_count}) */}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CatalogueList;
