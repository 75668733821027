import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { appService } from "../../../../../middle-ware/Services/appService";
import { useDataContext } from "../../../../../middle-ware/StateContext/DataContext";
import { toast } from "react-hot-toast";
import usePlacesAutocomplete from "use-places-autocomplete";
import Geocode from "react-geocode";
import { useFlowContext } from "../../../../../middle-ware/StateContext/FlowContext";

Geocode.setApiKey("AIzaSyCN7pinc11ItQCUJH9VXgs3ubpr1GG0vOo");
Geocode.setLanguage("en");
// eslint-disable-next-line
const libraries = ["places"];

export const useAddressHook = () => {
  // const navigate = useNavigate();
  const service = new appService();
  const context = useDataContext();
  const { setAddressModalView, setOpenModal } = useFlowContext();

  const [loading, setLoading] = useState(false);
  const [oldAddresses, setOldAddresses] = useState([{}]);
  const [newAddress, setNewAddress] = useState(true);
  const [loadingOldAddresses, setLoadingOldAddresses] = useState(false);

  const [loadingAreas, setLoadingAreas] = useState(false);

  const [areas, setAreas] = useState([]);
  const location = localStorage.currentAddress
    ? JSON.parse(localStorage.currentAddress)
    : {};

  const [boundary, setBoundary] = useState({ lat: "", lng: "" });
  // console.log(boundary);
  const [oldAddress, setOldAddress] = useState({
    id: "",
    name: "",
  });

  const [area, setArea] = useState({
    id: "",
    name: "",
  });
  const [address, setAddress] = useState({
    house_number: "",
    street: "",
    post_code: "",
  });
  const [oldAddressError, setOldAddressError] = useState("");

  const [errors, setErrors] = useState({
    house_number: "",
    street: "",
    country: "",
    state: "",
    area: "",
    post_code: "",
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => boundary.lat, lng: () => boundary.lng },
      radius: 30000,
    },
  });

  const getOldAddresses = async () => {
    setLoadingOldAddresses(true);
    const addreses = await service.getAddresses();
    if (addreses.message === "success") {
      // console.log(addreses.data);
      const arr = [];
      const id = location.available_region_id
        ? location.available_region_id
        : location.id;
      addreses?.data
        ?.filter((d) => d.available_region_id === id)
        ?.forEach((a) => {
          arr.push({
            id: a.id,
            name: `${a.house_number} ${a.street}, ${a.area} ${a.state} ${a.country}`,
          });
        });
      if (arr.length === 0) setNewAddress(true);
      else setNewAddress(false);
      setOldAddresses(arr);
    }
    setLoadingOldAddresses(false);
  };

  const getAreas = async () => {
    setLoadingAreas(true);
    const res = await service.getAreas(location.location_id, location.id);
    if (res.message === "success") {
      setAreas(res.data);
    }
    setLoadingAreas(false);
  };

  const getAreaLocation = async (area_) => {
    try {
      const location_ = await Geocode.fromAddress(
        `${area_}, ${location.state}`
      );

      const { lat, lng } = location_?.results[0]?.geometry.location;
      setBoundary({ lat: lat, lng: lng });
    } catch (err) {
      return;
    }
  };

  const handleChange = async (name, data) => {
    if (name === "area") {
      setArea({
        id: data?.id,
        name: data.name,
      });
      setErrors({ ...errors, area: "", house_number: "", street: "" });
      getAreaLocation(data.name);
    } else if (name === "oldAddress") {
      setOldAddress({
        id: data.id,
        name: data.name,
      });
    }
  };

  const handleAddress = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "street") {
      setValue(value);
    } else setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const error = {};
    if (address.house_number === "") error.house_number = "Required*";
    if (address.street === "") error.street = "Please select an address*";
    if (
      JSON.parse(localStorage.currentAddress).state === "London" &&
      address.post_code === ""
    )
      error.post_code = "Required*";
    else if (address.street.length < 3)
      error.street = "should be a mininmum of 3 characters";
    if (area.id === "") error.area = "Required*";
    return error;
  };
  // console.log(areas);
  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      const location_ = await Geocode.fromAddress(
        `${address.house_number}, ${address.street} ${area.name} ${
          location.state
        } ${location.location_id === "1" ? "Nigeria" : "London"}`
      );
      const { lat, lng } = location_?.results[0]?.geometry.location;
      const payload = {
        house_number: address.house_number,
        street: address.street,
        delivery_zone_id: area.id,
        available_region_id: location.id,
        location_id: location.location_id,
        longitude: lng,
        latitude: lat,
      };
      const res = await service.postAddress({
        address:
          JSON.parse(localStorage.currentAddress)?.state === "London"
            ? { ...payload, post_code: address.post_code }
            : payload,
      });

      if (res.message === "success") {
        localStorage.setItem("recipientAddress", JSON.stringify(res.data[0]));
        if (localStorage.localCart) {
          const localCart = JSON.parse(localStorage.localCart);
          localCart.items.forEach(async (item) => {
            await service.addToCart(item.product.id, item.quantity);
          });
          localStorage.removeItem("localCart");
        }
        // context.setAddressModal(false);
        setAddressModalView(false);
        setOpenModal(false);
      } else toast.error("Something went wrong, Please try again.");
      setLoading(false);
    }
  };
  const setCurrentLoaction = async () => {
    if (oldAddress.id === "") {
      setOldAddressError("Required*");
    } else {
      setLoading(true);
      const res = await service.setCurrentAddress(oldAddress.id);
      if (res.message === "success") {
        localStorage.setItem("recipientAddress", JSON.stringify(res.data[0]));
        // context.setAddressModal(false);
        setAddressModalView(false);
        setOpenModal(false);
      } else toast.error("Something went wrong, Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAreas();
    if (localStorage.token) getOldAddresses();
    else setNewAddress(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    area,
    areas,
    loading,
    loadingAreas,
    loadingOldAddresses,
    newAddress,
    location,
    ready,
    value,
    status,
    data,
    setValue,
    clearSuggestions,
    address,
    oldAddress,
    oldAddresses,
    oldAddressError,
    errors,
    setErrors,
    setNewAddress,
    setCurrentLoaction,
    handleAddress,
    handleChange,
    handleSubmit,
    setAddress,
  };
};
