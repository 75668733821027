import React, { Fragment, useState, useEffect, useRef } from "react";
// import { useDataContext } from "../../middle-ware/StateContext/DataContext";
import { appService } from "../../middle-ware/Services/appService";
import { useNavigate } from "react-router-dom";
import { useFlowContext } from "../../middle-ware/StateContext/FlowContext";
import LayoutTwo from "../components/ui/layouts/LayoutTwo";
import check from "../../assets/icons/red-check.svg";
import locator from "../../assets/icons/locator.svg";
import profile from "../../assets/icons/profile.svg";
import wallet from "../../assets/icons/wallet.svg";
import Loader from "../components/ui/Loader";
import CurrentOrder from "../components/cart/CurrentOrder";
import Reciepent from "../components/checkout/Recipient";
import Address from "../components/checkout/Address";
// import Input from "../components/ui/input/Input";

const Checkout = () => {
  const navigate = useNavigate();
  const service = new appService();
  const flowContext = useFlowContext();

  const recipientAddress = localStorage.recipientAddress
    ? JSON.parse(localStorage.recipientAddress)
    : {};

  const [showRecipient, setShowRecipient] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [discountError, setDiscountError] = useState(false);
  const [validDiscount, setValidError] = useState(false);
  const [discount, setDiscount] = useState("");

  const [loading2, setLoading2] = useState(false);

  const discountRef = useRef();

  const addDiscount = async (e) => {
    const res = await service.addDiscountToCart(discountRef.current.value);
    if (res.message === "success") {
      setDiscountError(false);
      setValidError(true);
      setDiscount(discountRef.current.value);
    } else if (
      discountRef.current.value === "" ||
      discountRef.current.value == null
    ) {
      setDiscountError(false);
      setValidError(false);
    } else {
      setDiscountError(true);
      // console.log("Invalid", res.message);
      setValidError(false);
    }
  };

  const initiatePayment = async () => {
    setLoading2(true);
    const res = await service.initiatePayment();
    if (res.message) setLoading2(false);
  };

  useEffect(() => {
    if (!localStorage.currentAddress) navigate("/user-location");
    if (flowContext?.cart?.items?.length === 0) navigate("/menu");
    if (flowContext?.cart?.voucher?.code) {
      setDiscount(flowContext?.cart?.voucher?.code);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowContext?.cart]);

  useEffect(() => {
    if (localStorage.hasRecipient) {
      setShowRecipient(false);
      setShowAddress(true);
      setShowPayment(false);
    }
    if (localStorage.hasRecipient && localStorage.hasRecipientAddress) {
      setShowRecipient(false);
      setShowAddress(false);
      setShowPayment(true);
    }
  }, []);

  return (
    <LayoutTwo
      title={"Menu"}
      Path={
        <Fragment>
          <span>/</span>
          <span>{"Checkout"}</span>
        </Fragment>
      }
      LeftComponent={
        <Fragment>
          <div className="w-full transition-all ease-in-out duration-500 flex gap-[11px] pl-[18px] items-center relative">
            <span
              className={`border-r border-dashed transition-all ease-in-out duration-500
              ${
                showRecipient &&
                !showAddress &&
                !showPayment &&
                `${
                  localStorage.forAFriend
                    ? "sm:h-[410px] h-[510px] sm:mt-[200px]"
                    : "sm:h-[370px] h-[540px] sm:mt-[150px] mt-[0]"
                }  `
              }
               ${
                 !showRecipient &&
                 showAddress &&
                 !showPayment &&
                 `${!recipientAddress?.street ? "h-[315px]" : "h-[410px]"}`
               }
                 ${
                   !showRecipient &&
                   !showAddress &&
                   showPayment &&
                   "h-[282px] mb-[140px]"
                 }
                 w-1`}
            ></span>
            <div className="flex flex-col w-full gap-[24px]">
              <div className="p-[16px] rounded-[16px] text-[#ffffff] transparent_bg3 max-w-[808px] flex flex-col gap-[8px] justify-center items-center w-full h-fit">
                <span className="max-w-[40px] bg-[#383737] w-full min-w-[40px] flex justify-center border border-[#FF0018] items-center absolute z-10 left-0 h-[40px] sm:top-auto top-[16px] rounded-full ">
                  <img
                    src={localStorage.hasRecipient ? check : profile}
                    alt=""
                  />
                </span>
                {showRecipient ? (
                  <Reciepent
                    vissible={setShowRecipient}
                    showNext={() => setShowAddress(true)}
                  />
                ) : (
                  <span
                    onClick={() => {
                      if (localStorage.hasRecipient) {
                        setShowAddress(false);
                        setShowRecipient(true);
                        setShowPayment(false);
                      }
                    }}
                    className="mobile:text-[24px] text-[18px] mobile:leading-[36px] leading-[27px] w-full  font-[500]"
                  >
                    Recipient Information
                  </span>
                )}
              </div>
              <div className="p-[16px] rounded-[16px] text-[#ffffff] transparent_bg3 max-w-[808px] flex flex-col gap-[8px] justify-center items-center w-full h-fit">
                <span className="max-w-[40px] bg-[#383737] w-full min-w-[40px] flex justify-center border border-[#FF0018] items-center absolute z-10 left-0 h-[40px] rounded-full ">
                  <img
                    src={localStorage.hasRecipientAddress ? check : locator}
                    alt=""
                  />
                </span>
                {showAddress ? (
                  <Address
                    vissible={setShowAddress}
                    showNext={() => setShowPayment(true)}
                  />
                ) : (
                  <span
                    onClick={() => {
                      if (localStorage.hasRecipientAddress) {
                        setShowAddress(true);
                        setShowRecipient(false);
                        setShowPayment(false);
                      }
                    }}
                    className="mobile:text-[24px] text-[18px] mobile:leading-[36px] leading-[27px] w-full  font-[500]"
                  >
                    Delivery Address
                  </span>
                )}
              </div>
              <div className="p-[16px] rounded-[16px] text-[#ffffff] transparent_bg3 max-w-[808px] flex flex-col gap-[8px] justify-center items-center w-full h-fit">
                <span className="max-w-[40px] bg-[#383737] w-full min-w-[40px] flex justify-center border border-[#FF0018] items-center absolute z-10 left-0 h-[40px] rounded-full ">
                  <img src={wallet} className="" alt="" />
                </span>
                {showPayment ? (
                  <Fragment>
                    <span className="mobile:text-[24px] text-[18px] mobile:leading-[36px] leading-[27px] flex flex-col items-center  font-[500]">
                      Payment & Discounts{" "}
                    </span>
                    <span className="mt-[24px]">Apply A Discount Code</span>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        gap: "16px",
                        width: "80%",
                      }}
                    >
                      <input
                        type="text"
                        ref={discountRef}
                        name="discount"
                        error={discountError}
                        label={"Enter a Discount Code"}
                        placeholder={"Enter a Discount Code"}
                        className={`${
                          discountError
                            ? "border-[#FF0018]"
                            : "border-[#ffffff]"
                        } ${
                          validDiscount ? "border-[#aaffaa]" : ""
                        }  border-[1px] text-[16px] w-[50%] leading-[27px] font-[300] rounded-[16px] p-[12px] text-[#ffffff] bg-transparent`}
                        light
                      />
                      <button
                        onClick={addDiscount}
                        className="border-[1px] text-[16px] leading-[27px] font-[500] rounded-[16px] p-[12px] pt-[8px] text-[#000] bg-[#ffffff]"
                      >
                        Apply Code
                      </button>
                    </div>

                    {discount && (
                      <span className="text-[#00FF00]">
                        &apos;{discount}&apos; has been applied to this order
                      </span>
                    )}
                    <form
                      className="flex-col min-h-[100px] justify-center items-center flex max-w-[430px] m-auto gap-[27px] w-full"
                      action=""
                    >
                      <span
                        onClick={() => initiatePayment()}
                        className="h-[55px] cursor-pointer font-[500] w-full flex justify-center items-center bg-[#FF0018] text-[#ffffff] rounded-[100px] mobile:text-[20px] text-[18px] leading-[30px]"
                      >
                        {loading2 ? (
                          <Loader color={"#fff"} size={[30, 30]} />
                        ) : (
                          "Make Payment"
                        )}
                      </span>
                    </form>
                  </Fragment>
                ) : (
                  <span
                    onClick={() => {
                      if (localStorage.hasReciepientAddress) {
                        setShowAddress(false);
                        setShowRecipient(false);
                        setShowPayment(true);
                      }
                    }}
                    className="mobile:text-[24px] text-[18px] mobile:leading-[36px] leading-[27px] w-full font-[500]"
                  >
                    Payment
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="tablet:hidden mt-[32px] block">
            <CurrentOrder
              processed={true}
              title={"Order Total"}
              completed={false}
            />
          </div>
        </Fragment>
      }
      RightComponent={
        <CurrentOrder
          processed={false}
          title={"Order Total"}
          completed={false}
        />
      }
    />
  );
};

export default Checkout;
