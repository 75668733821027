import React from "react";
import { useState } from "react";
import arrow from "../../../assets/icons/chevron.svg";

const RecipientDropDown = ({ info }) => {
  const [toggle, setToggle] = useState(false);
  const [view, setView] = useState(false);
  return (
    <div className="w-[236px]  transition-all ease-in-out duration-300 bg-[#151515] p-[10px] rounded-[18px] flex flex-col gap-[11px]">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => {
          setToggle(!toggle);
          view ? setTimeout(() => setView(false), 100) : setView(true);
        }}
      >
        <span className="text-[10px] w-[61px] text-[#ffffff] leading-[15px] bg-[#00BA80] px-[8px] py-[4px] rounded-[32px]">
          {info?.status}
        </span>
        {!toggle && (
          <span className="text-[12px] whitespace-nowrap truncate font-[600] leading-[18px] flex gap-[11px] items-center text-[#ffffff]">
            {info?.recipient?.full_name}
          </span>
        )}
        <img
          src={arrow}
          className={`w-[12px] transition-transform ease-in-out duration-300 ${
            !toggle ? "rotate-180" : "rotate-0"
          } `}
          alt=""
        />
      </div>
      {view && (
        <div
          className={`flex transition-transform duration-300 ease-in-out flex-col gap-[11px] ${
            toggle ? "translate-y-0" : "translate-y-[-100px]"
          }`}
        >
          <span className="text-[12px] font-[600] leading-[18px] flex gap-[11px] items-center text-[#ffffff] ">
            <span className=" whitespace-nowrap truncate capitalize">{info?.recipient?.full_name}</span> •{" "}
            <span>{info?.recipient?.phone}</span>
          </span>
          <span className="text-[#ffffff] text-[11px] font-[400] leading-[16px] opacity-[0.65]">
            {info?.address?.house_number} {info?.address?.street},{" "}
            {info?.address?.area}, {info?.address?.state}.
          </span>
        </div>
      )}
    </div>
  );
};

export default RecipientDropDown;
