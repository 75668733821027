import React from "react";
import logo from "../../../assets/images/logo.png";
import Loader from "./Loader";

const PageLoader = ({ transparent, contain, children }) => {
  return (
    <div
      className={`w-full ${transparent ? "bg-[#1515157c]" : "bg-[#151515]"} ${
        contain ? "h-fit" : "h-screen"
      } flex flex-col justify-center items-center gap-[24px]`}
    >
      <img
        className="mini-tablet:w-[180px] max-mobile:w-[140px] w-[100px] mini-tablet:h-[180px] max-mobile:h-[140px] h-[100px]"
        src={logo}
        alt=""
      />
      {children}
      <div className="mini-tablet:block hidden">
        <Loader size={[60, 60]} color="#FF0018" />
      </div>
      <div className="mini-tablet:hidden max-mobile:block hidden">
        <Loader size={[50, 50]} color="#FF0018" />
      </div>
      <div className="mini-tablet:hidden max-mobile:hidden block ">
        <Loader size={[40, 40]} color="#FF0018" />
      </div>
    </div>
  );
};

export default PageLoader;
