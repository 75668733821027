import React, { Fragment, useEffect } from "react";
import OrderTracking from "../components/order/OrderTracking";
// import CurrentOrder from "../components/cart/CurrentOrder";
import LayoutTwo from "../components/ui/layouts/LayoutTwo";
import { useNavigate } from "react-router-dom";
import OrderCart from "../components/cart/OrderCart";

const Tracking = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.token && !localStorage.currentAddress)
      navigate("/user-location");
    // else if(!localStorage.currentAddress) navigate("/address");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutTwo
      title={"Order"}
      Path={
        <Fragment>
          <span>/</span>
          <span>Tracking</span>
        </Fragment>
      }
      LeftComponent={
        <Fragment>
          <OrderTracking />
          <div className=" tablet:hidden block tablet:pb-0 pb-[32px] border-b border-[#ffffff29] ">
            <OrderCart title={"Order Details"} processed />
          </div>
        </Fragment>
      }
      RightComponent={<OrderCart title={"Order Details"} />}
    />
  );
};

export default Tracking;
