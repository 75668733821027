import React, { useRef, useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import MiniProductCard from "./MiniProductCard";
import { useDataContext } from "../../../middle-ware/StateContext/DataContext";
import { useParams } from "react-router-dom";

const MiniProductSlide = ({ title }) => {
  const appContext = useDataContext();
  const forwardRef = useRef(null);
  const backwardRef = useRef(null);
  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(false);
  const params = useParams();
  // console.log(params);
  const products = appContext.products.filter(
    (p) => p?.category?.name === params?.category
  );
  // console.log(products);
  return (
    <div className=" w-full m-auto pt-[34px] pb-[32px] border-y border-[0.75px] border-r-0 border-l-0 border-[#ffffff17] ">
      <div className="flex items-center w-full justify-between">
        <span className="text-[#ffffff] opacity-[0.65] text-[24px] leading-[36px] font-[600]">
          {title}
        </span>
        <div className="flex gap-[16px]">
          <span
            onClick={() => backwardRef.current.click()}
            className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer w-[56px] h-[56px] ${
              !start && "bg-[#ff0018]"
            } flex justify-center items-center rounded-full`}
          >
            <BsArrowLeftShort className="text-[21px] text-[#ffffff]" />
          </span>
          <span
            onClick={() => forwardRef.current.click()}
            className={`border-[#ff0018] transition-all ease-in-out duration-500 z-30 border cursor-pointer w-[56px] h-[56px] ${
              !end && "bg-[#ff0018]"
            } flex justify-center items-center rounded-full`}
          >
            <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
          </span>
        </div>
      </div>

      <div className=" w-full  mt-[28px] mb-[24px]]">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={24}
          slidesPerView={3}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            964: {
              width: 529,
              slidesPerView: 2,
              spaceBetween: 15,
            },
            1248: {
              slidesPerView: 3,
            },
          }}
          onSwiper={(swiper) => {
            setStart(swiper.isBeginning);
            setEnd(swiper.isEnd);
          }}
          onSlideChange={(swiper) => {
            setStart(swiper.isBeginning);
            setEnd(swiper.isEnd);
          }}
        >
          {products.map((p, n) => (
            <SwiperSlide key={n}>
              <MiniProductCard product={p} />
            </SwiperSlide>
          ))}
          <SwiperButtonNext>
            <span className="hidden absolute" ref={forwardRef}>
              <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
            </span>
          </SwiperButtonNext>
          <SwiperButtonPrev>
            <span className="hidden absolute" ref={backwardRef}>
              <BsArrowRightShort className="text-[21px] cursor-pointer text-[#ffffff]" />
            </span>
          </SwiperButtonPrev>
        </Swiper>
      </div>
    </div>
  );
};

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slideNext()}>{children}</span>;
};
const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return <span onClick={() => swiper.slidePrev()}>{children}</span>;
};

export default MiniProductSlide;
