import React, { Fragment } from "react";
import { useNavbar } from "../hooks/useNav";
import Cart from "../navbarCart/Cart";
import polygon from "../../../../assets/vectors/triangle.png";
import bag from "../../../../assets/icons/bag.svg";

const CartContainer = () => {
  const { cartRef, setOpenCart, openCart, numOfItems } = useNavbar();

  return (
    <div
      ref={cartRef}
      className="w-fit px-3 flex  relative h-[40px] gap-3 text-[12px] text-[#fff] cursor-pointer justify-center items-center rounded-[24px] transparent_bg"
    >
      <div className="flex gap-2" onClick={() => setOpenCart(!openCart)}>
        <img src={bag} className="min-w-[16.69px] w-[17px] h-[16.69px]" alt="" />
        {numOfItems > 0 && numOfItems}
      </div>

      <div
        className={`${
          localStorage.token && !localStorage.guestUser
            ? "mobile:right-[-10px] right-[10px] "
            : "mini-tablet:right-[-30px]  mobile:right-[-100px] right-[-150px]"
        } absolute transition-all duration-200 ease-in-out rounded-md z-[99999990099999999] border-t-2 border-[#FF0018] top-[67px]`}
      >
        {openCart && (
          <Fragment>
            <img
              className={`absolute top-[-20px] ${
                localStorage.token && !localStorage.guestUser
                  ? "right-[15px]"
                  : "mini-tablet:right-[32px] mobile:right-[100px] right-[150px]"
              } z-[-99] w-[40px] h-[40px]`}
              src={polygon}
              alt=""
            />
            <Cart />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CartContainer;
